import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { COMPLAINTS_LIST,DELETE_COMPLAINT,ADD_CUSTOMER_COMPLAINTS,EDIT_CUSTOMER_COMPLAINTS } from './actionTypes';
import { ComplaintsListSuccessful, ComplaintsListApiError,addComplaintsApiError } from './actions';

// AUTH related methods
import { getList,deleteData,addData,editData } from '../../helpers/fackBackend_Helper';

function* getComplaintsList( { payload : { LoginId } } ) {
    try {
        const response = yield call(getList, `/customer/complaintlist/${LoginId}`);
        if (response) {
            yield put(ComplaintsListSuccessful(response.data.data));
        }
    } catch (error) {
        yield put(ComplaintsListApiError(error));
    }
}

export function* watchGetComplaints() {
    yield takeEvery(COMPLAINTS_LIST, getComplaintsList)
}


function* addCustomerComplaints({ payload: { CustmerComplaints } }) {
    try {
        const responseCreate = yield call(addData, '/customer/complaint', CustmerComplaints);
        // console.log('response from saga = ', responseCreate);
        if (responseCreate) {
            const response = yield call(getList, `/customer/complaintlist/${CustmerComplaints.CreatedBy}`);
            if (response) {
                    //    console.log('response.data.data = ', response.data.data);
                    //    console.log('responseCreate = ', responseCreate);
                yield put(ComplaintsListSuccessful(response.data.data,responseCreate));                
            }
        }
    } catch (error) {
        yield put(addComplaintsApiError(error));
    }
}

export function* watchaddCustomerComplaints() {
    yield takeEvery(ADD_CUSTOMER_COMPLAINTS, addCustomerComplaints);
}



function* editCustomerComplaints({ payload: { CustmerComplaints } }) {
    try {
        const responseedit = yield call(editData, '/customer/complaint', CustmerComplaints);
        // console.log('response from editCustomerComplaints saga = ', responseedit);
        // const responseDetails = yield call(getList, `/customer/complaintlist/${CustmerComplaints.UpdatedBy}`);
        if (responseedit) {
            const responseCount = yield call(getList, `/customer/complaintcount/${CustmerComplaints.UpdatedBy}`);
            if (responseCount) {
                // const responseRemark = yield call(getList, `/complaint/remarks/${CustmerComplaints.CompanyCode}/${CustmerComplaints.ComplaintId}`);
                // if (responseRemark) {
                    const response = yield call(getList, `/customer/complaintlist/${CustmerComplaints.UpdatedBy}`);
                    if (response) {
                        // console.log('complaintcount response.data.data',responseCount.data.data)
                        // console.log('response.data.data',response.data.data)
                        // console.log('responseedit.data.data',responseedit)
                        // console.log('responseRemark',responseRemark)
                        // console.log('response.data.data,responseCount.data.data,responseedit',response.data.data,responseCount.data.data,responseedit)
                        yield put(ComplaintsListSuccessful(response.data.data,responseedit));                
                    }  
                // }             
            }
            
        }
    } catch (error) {
        yield put(addComplaintsApiError(error));
    }
}

export function* watcheditCustomerComplaints() {
    yield takeEvery(EDIT_CUSTOMER_COMPLAINTS, editCustomerComplaints);
}

function* deleteComplaint({ payload: { ComplaintId,status,UpdatedBy} }) {
    try {
        // console.log('/customer/complaintlist',ComplaintId,status,UpdatedBy)
        const response = yield call(deleteData, '/customer/complaint', { ComplaintId,status,UpdatedBy });
        // console.log('update status /customer/complaintlist =',response.data.data);
        if (response) {
            const responseD = yield call(getList, `/customer/complaintlist/${UpdatedBy}`);
            if (responseD) {
                yield put(ComplaintsListSuccessful(responseD.data.data,response.data.data,));
                
            }
        }
    } catch (error) {
        yield put(addComplaintsApiError(error));
    }
}

export function* watchdeleteComplaint() {
    yield takeEvery(DELETE_COMPLAINT, deleteComplaint);
}

function* ComplaintsSaga() {
    yield all([fork(watchGetComplaints)]);
    yield all([fork(watchdeleteComplaint)]);
    yield all([fork(watchaddCustomerComplaints)]);
    yield all([fork(watcheditCustomerComplaints)]);
}

export default ComplaintsSaga;