import { 
    TICKETSTYPE_LIST,TICKETSTYPE_LIST_SUCCESSFUL,
     TICKETSTYPE_LIST_API_FAILED ,ADD_TICKETTYPE,EDIT_TICKETTYPE,
     ADD_TICKETTYPE_API_FAILED,DELETE_TICKETTYPE,LOG_TICKETTYPE,
     ADD_ACTIVITY
} from './actionTypes';

const initialState = {
    
    apiError: null, loading: false, TicketsType: [], CustomerExecutive: [], isAlertOpen: false, modal_static: false, message: null, modalError: null
}

const TicketsType = (state = initialState, action) => {
    switch (action.type) {
        case TICKETSTYPE_LIST:
            state = {
                ...state,
                loading: true,
                apiError: null
            }
            break;
        case TICKETSTYPE_LIST_SUCCESSFUL:
            //console.log(action.payload.TicketsType);
            state = {
                ...state,
                TicketsType: action.payload.TicketsType,
                CustomerExecutive: action.payload.CustomerExecutive,
                loading: false,
                message: action.payload.message.message
            }
            break;
        case TICKETSTYPE_LIST_API_FAILED:
            // console.log(action.payload);
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
                TicketsType: []
            }
            break;
            case ADD_TICKETTYPE:
            state = {
                ...state,
                loading: true,
                apiError: null,
                message: null
            }
            break;
            case ADD_ACTIVITY:
                state = {
                    ...state
                    // loading: true,
                    // modalError: null
                }
                break;    
         
        
            case ADD_TICKETTYPE_API_FAILED:
            state = {
                ...state,
                loading: false,
                modalError: action.payload,
            }
            break;
            case EDIT_TICKETTYPE:
            state = {
                ...state,
                loading: true,
                apiError: null,
                message: null
            }
            break;
            case DELETE_TICKETTYPE:
            state = {
                ...state,
                loading: true,
                apiError: null,
                message: null
            }
            break;
            case LOG_TICKETTYPE:
            state = {
                ...state,
                loading: true,
                apiError: null,
                message: null
            }
            break;
        default:
            state = { ...state };
            break;
    }

    return state;
}

export default TicketsType;