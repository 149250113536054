import { REMARKS_LIST,REMARKS_LIST_SUCCESSFUL,
     REMARKS_LIST_API_FAILED,
     ADD_REMARKS_API_FAILED,
     EDIT_REMARKS } from './actionTypes';

const initialState = {
    apiError: null, loading: false, Remarks: [],  isAlertOpen: false, modal_static: false, message: null, modalError: null
}

const Remarks = (state = initialState, action) => {
    switch (action.type) {
        case REMARKS_LIST:
            state = {
                ...state,
                loading: true,
                apiError: null
            }
            break;
        case REMARKS_LIST_SUCCESSFUL:
            state = {
                ...state,
                Remarks: action.payload.Remarks,
                loading: false,
                message: action.payload.message.message
            }
            break;
        case REMARKS_LIST_API_FAILED:
            state = {
                ...state,
                loading: false,
                Remarks: []
            }
            break;
            
            case ADD_REMARKS_API_FAILED:
            state = {
                ...state,
                loading: false,
                message: action.payload
            }
            break;
            case EDIT_REMARKS:
            state = {
                ...state,
                loading: false,
                apiError: null,
                message: null
            }
            break;
            
        default:
            state = { ...state };
            break;
    }

    return state;
}

export default Remarks;