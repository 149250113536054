import { OPEN_COMPLAINTS_LIST,OPEN_COMPLAINTS_LIST_SUCCESSFUL,
     OPEN_COMPLAINTS_LIST_API_FAILED,DELETE_COMPLAINT,
     ADD_CUSTOMER_OPEN_COMPLAINTS,ADD_OPEN_COMPLAINTS_API_FAILED,
     EDIT_CUSTOMER_OPEN_COMPLAINTS } from './actionTypes';

const initialState = {
    apiError: null, loading: false, OpenComplaints: [],  isAlertOpen: false, modal_static: false, message: null, modalError: null
}

const OpenComplaints = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_COMPLAINTS_LIST:
            state = {
                ...state,
                loading: true,
                apiError: null
            }
            break;
        case OPEN_COMPLAINTS_LIST_SUCCESSFUL:
            state = {
                ...state,
                OpenComplaints: action.payload.OpenComplaints,
                loading: false,
                message: action.payload.message.message
            }
            break;
        case OPEN_COMPLAINTS_LIST_API_FAILED:
            state = {
                ...state,
                loading: false,
                OpenComplaints: []
            }
            break;
            case ADD_CUSTOMER_OPEN_COMPLAINTS:
            state = {
                ...state,
                loading: true,
                modalError: null
            }
            break;
            case ADD_OPEN_COMPLAINTS_API_FAILED:
            state = {
                ...state,
                loading: false,
                message: action.payload
            }
            break;
            case EDIT_CUSTOMER_OPEN_COMPLAINTS:
            state = {
                ...state,
                loading: false,
                apiError: null,
                message: null
            }
            break;
            case DELETE_COMPLAINT:
            state = {
                ...state,
                loading: true,
                apiError: null,
                message: null
            }
            break;
        default:
            state = { ...state };
            break;
    }

    return state;
}

export default OpenComplaints;