import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CHECK_LOGIN, FETCH_COMPANY, LOGOUT_USER } from './actionTypes';
import { apiError, fetchCompanySucceassful, loginUserSuccessful, logoutUserSuccess } from './actions';

// AUTH related methods
import { postLogin, fetchLoginCompany } from '../../../helpers/fackBackend_Helper';
//import { getFirebaseBackend } from '../../../helpers/firebase_helper';

//Initilize firebase
//const fireBaseBackend = getFirebaseBackend();

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { user, history, companyId } }) {

    try {

        // alert(process.env.REACT_APP_BASEURL);
        if (process.env.REACT_APP_DEFAULTAUTH === "apiservice") {
            // console.log(process.env.REACT_APP_DEFAULTAUTH);
            // console.log("response Login Saga", user);
            //console.log("response Login Saga", url); 

            const response = yield call(postLogin, '/users/login', { Email: user.username, Password: user.password, companyId: companyId });
            // console.log('companyId', companyId)
            // console.log("response Login Saga", response)
            if (response.success) {
                localStorage.setItem('success', JSON.stringify(response.success));
                localStorage.setItem('token', JSON.stringify(response.token));
                localStorage.setItem('authUser', JSON.stringify(response.data));
                yield put(loginUserSuccessful(response));
                history.push('/dashboard');

                // if(response.data.RoleId==1){
                //     //console.log("superadmin8888",response.data.RoleId)
                //     history.push('/maindashboard');
                // }else{
                // }
            }
            else {

                // console.log("Login msg", response.message)
                yield put(apiError(response.message));
                history.push('/login/');
            }
        }
        // history.push('/dashboard');
    } catch (error) {

        // console.log("Login error", error)
        yield put(apiError(error));
        history.push('/login/');
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        localStorage.removeItem("authUser");

        // if (process.env.REACT_APP_DEFAULTAUTH === '') {
        //     const response = yield call(fireBaseBackend.logout);
        //     yield put(logoutUserSuccess(response));
        // }

        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}

function* getCompanyDetails({ payload: { domainName } }) {
    try {
        // console.log(domainName);
        const response = yield call(fetchLoginCompany, '/users/companyDetails', { domainName: domainName });

        if (response.success) {
            yield put(fetchCompanySucceassful(response.data));
        }
    } catch (error) {

    }
}

export function* watchUserLogin() {
    yield takeEvery(CHECK_LOGIN, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

export function* watchGetCompanyDetail() {
    yield takeEvery(FETCH_COMPANY, getCompanyDetails)
}

function* loginSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
        fork(watchGetCompanyDetail)
    ]);
}

export default loginSaga;