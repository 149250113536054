import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { OPEN_COMPLAINTS_LIST,DELETE_COMPLAINT,ADD_CUSTOMER_OPEN_COMPLAINTS,EDIT_CUSTOMER_OPEN_COMPLAINTS } from './actionTypes';
import { OpenComplaintsListSuccessful, OpenComplaintsListApiError,addOpenComplaintsApiError } from './actions';

// AUTH related methods
import { getList,deleteData,addData,editData } from '../../../helpers/fackBackend_Helper';

function* getOpenComplaintsList( { payload : { LoginId } } ) {
    try {
        const response = yield call(getList, `/customer/complaintopen/${LoginId}`);
        if (response) {
            yield put(OpenComplaintsListSuccessful(response.data.data));
        }
    } catch (error) {
        yield put(OpenComplaintsListApiError(error));
    }
}

export function* watchGetOpenComplaints() {
    yield takeEvery(OPEN_COMPLAINTS_LIST, getOpenComplaintsList)
}


function* addCustomerOpenComplaints({ payload: { CustmerOpenComplaints } }) {
    try {
        const responseCreate = yield call(addData, '/customer/complaint', CustmerOpenComplaints);
        // console.log('response from saga = ', responseCreate);
        if (responseCreate) {
            const response = yield call(getList, `/customer/complaintopen/${CustmerOpenComplaints.CreatedBy}`);
            if (response) {
                       console.log('response.data.data = ', response.data.data);
                       console.log('responseCreate = ', responseCreate);
                yield put(OpenComplaintsListSuccessful(response.data.data,responseCreate));                
            }
        }
    } catch (error) {
        yield put(addOpenComplaintsApiError(error));
    }
}

export function* watchaddCustomerOpenComplaints() {
    yield takeEvery(ADD_CUSTOMER_OPEN_COMPLAINTS, addCustomerOpenComplaints);
}



function* editCustomerOpenComplaints({ payload: { CustmerOpenComplaints } }) {
    try {
        const responseedit = yield call(editData, '/customer/complaint', CustmerOpenComplaints);
        console.log('response from editCustomerOpenComplaints saga = ', responseedit);
        // const responseDetails = yield call(getList, `/customer/complaintlist/${CustmerOpenComplaints.UpdatedBy}`);
        if (responseedit) {
            const responseCount = yield call(getList, `/customer/complaintcount/${CustmerOpenComplaints.UpdatedBy}`);
            if (responseCount) {
                // const responseRemark = yield call(getList, `/complaint/remarks/${CustmerOpenComplaints.CompanyCode}/${CustmerOpenComplaints.ComplaintId}`);
                // if (responseRemark) {
                    const response = yield call(getList, `/customer/complaintopen/${CustmerOpenComplaints.UpdatedBy}`);
                    if (response) {
                        console.log('complaintcount response.data.data',responseCount.data.data)
                        console.log('response.data.data',response.data.data)
                        console.log('responseedit.data.data',responseedit)
                        // console.log('responseRemark',responseRemark)
                        console.log('response.data.data,responseCount.data.data,responseedit',response.data.data,responseCount.data.data,responseedit)
                        yield put(OpenComplaintsListSuccessful(response.data.data,responseedit));                
                    }  
                // }             
            }
            
        }
    } catch (error) {
        yield put(addOpenComplaintsApiError(error));
    }
}

export function* watcheditCustomerOpenComplaints() {
    yield takeEvery(EDIT_CUSTOMER_OPEN_COMPLAINTS, editCustomerOpenComplaints);
}

function* deleteComplaint({ payload: { ComplaintId,status,UpdatedBy} }) {
    try {
        console.log('/customer/complaintlist',ComplaintId,status,UpdatedBy)
        const response = yield call(deleteData, '/customer/complaint', { ComplaintId,status,UpdatedBy });
        console.log('update status /customer/complaintlist =',response.data.data);
        if (response) {
            const responseD = yield call(getList, `/customer/complaintopen/${UpdatedBy}`);
            if (responseD) {
                yield put(OpenComplaintsListSuccessful(responseD.data.data,response.data.data,));
                
            }
        }
    } catch (error) {
        yield put(addOpenComplaintsApiError(error));
    }
}

export function* watchdeleteComplaint() {
    yield takeEvery(DELETE_COMPLAINT, deleteComplaint);
}

function* OpenComplaintsSaga() {
    yield all([fork(watchGetOpenComplaints)]);
    yield all([fork(watchdeleteComplaint)]);
    yield all([fork(watchaddCustomerOpenComplaints)]);
    yield all([fork(watcheditCustomerOpenComplaints)]);
}

export default OpenComplaintsSaga;