import { REMARKS_LIST,REMARKS_LIST_SUCCESSFUL,
     REMARKS_LIST_API_FAILED,EDIT_REMARKS,
     ADD_REMARKS_API_FAILED } from './actionTypes';

export const RemarksList = (CompanyId,ComplaintId) => {
    
    return {
        type: REMARKS_LIST,
        payload: {CompanyId,ComplaintId}
    }
}

export const RemarksListSuccessful = (Remarks, message='') => {

    return {
        type: REMARKS_LIST_SUCCESSFUL,
        payload: {Remarks, message},
    }
}





export const editRemarks = (Remarks) => {
    return {
        
        type: EDIT_REMARKS,
        payload: {Remarks},
        isAlertOpen: false,
        modal_static: false
    }
}

export const RemarksListApiError = (error) => {
    return {
        type: REMARKS_LIST_API_FAILED,
        payload: error
    }
}
export const addRemarksApiError = (error) => {
    return {
        type: ADD_REMARKS_API_FAILED,
        payload: error
    }
}


