import { 
    COMPANYLIST, 
    COMPANYLISTBYID,
    COMPANYLISTSUCCESSFUL, 
    COMPANYLISTAPIERROR,
    ADDCOMPANY,
    ADDCOMPANYAPIERROR,
    EDITCOMPANY,
    DELETECOMPANYLIST,
    COMPANYLOGOAPI,
} from './actionTypes';

export const CompanyList = () => {
    return {
        type: COMPANYLIST,
        payload: {  }
    }
}
export const CompanyListByID = (Id) => {
    return {
        type: COMPANYLISTBYID,
        payload: {Id},
    }
}

export const CompanyListsuccessfull = (Company, message='') => {
    return {
        type: COMPANYLISTSUCCESSFUL,
        payload: {Company, message},
    }
}

export const CompanyListApiError = (error) => {
    
    return {
        type: COMPANYLISTAPIERROR,
        payload: error
    }
}

export const addCompanyApiError = (error) => {
    
    return {
        type: ADDCOMPANYAPIERROR,
        payload: error
    }
}


 
export const addCompany = (Company) => {
    
    return {
        type: ADDCOMPANY,
        payload: {Company},
        isAlertOpen: false,
        modal_static: false
    }
}

export const CompanyLogoApi = (Company) => {
    return {
        type: COMPANYLOGOAPI,
        payload: {Company},
        isAlertOpen: false,
        modal_static: false
    }
}

export const editCompany = (Company) => {
    
    return {
        type: EDITCOMPANY,
        payload: {Company},
        isAlertOpen: false,
        modal_center: false
    }
}

export const deleteCompanyList = (Id, userId,CompanyCode, status) => {
    return {
        type: DELETECOMPANYLIST,
        payload: {Id, userId,CompanyCode, status},
        isAlertOpen: false,
        modal_static: false
    }
}
