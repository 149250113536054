import { AWAITING_COMPLAINTS_LIST,AWAITING_COMPLAINTS_LIST_SUCCESSFUL,
     AWAITING_COMPLAINTS_LIST_API_FAILED,DELETE_COMPLAINT,
     ADD_CUSTOMER_AWAITING_COMPLAINTS,ADD_AWAITING_COMPLAINTS_API_FAILED,
     EDIT_CUSTOMER_AWAITING_COMPLAINTS } from './actionTypes';

export const AwaitingComplaintsList = (LoginId) => {
    
    return {
        type: AWAITING_COMPLAINTS_LIST,
        payload: {LoginId}
    }
}

export const AwaitingComplaintsListSuccessful = (AwaitingComplaints, message='') => {

    return {
        type: AWAITING_COMPLAINTS_LIST_SUCCESSFUL,
        payload: {AwaitingComplaints, message},
    }
}

export const addCustomerAwaitingComplaints = (CustmerAwaitingComplaints) => {
    return {
        
        type: ADD_CUSTOMER_AWAITING_COMPLAINTS,
        payload: {CustmerAwaitingComplaints},
        isAlertOpen: false,
        modal_static: false
    }
}



export const editCustomerAwaitingComplaints = (CustmerAwaitingComplaints) => {
    return {
        
        type: EDIT_CUSTOMER_AWAITING_COMPLAINTS,
        payload: {CustmerAwaitingComplaints},
        isAlertOpen: false,
        modal_static: false
    }
}

export const AwaitingComplaintsListApiError = (error) => {
    return {
        type: AWAITING_COMPLAINTS_LIST_API_FAILED,
        payload: error
    }
}
export const addAwaitingComplaintsApiError = (error) => {
    return {
        type: ADD_AWAITING_COMPLAINTS_API_FAILED,
        payload: error
    }
}
export const deleteComplaint = (ComplaintId,status,UpdatedBy) => {
    return {
        type: DELETE_COMPLAINT,
        payload: {ComplaintId,status,UpdatedBy},
        isAlertOpen: false,
        modal_static: false
    }
}

