import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { PROFILES_LIST,EDIT_PROFILES } from './actionTypes';
import { ProfilesListSuccessful, ProfilesListApiError,addProfilesApiError } from './actions';

// AUTH related methods
import { getList,editData } from '../../helpers/fackBackend_Helper';

function* ProfilesList( { payload : { LoginId } } ) {
    
    try {
        const response = yield call(getList, `/users/user/${LoginId}`);
        if (response) {
            yield put(ProfilesListSuccessful(response.data.data));
        }
    } catch (error) {
        yield put(ProfilesListApiError(error));
    }
}

export function* watchGetProfiles() {
    yield takeEvery(PROFILES_LIST, ProfilesList)
}

function* editProfiles({ payload: { Profiles } }) {
    
    try {
        const responseedit = yield call(editData, '/users/userprofile', Profiles);
        // console.log('response from editCustomerProfiles saga = ', responseedit);
        // console.log('Profiles from editCustomerProfiles saga = ', Profiles);
       
        if (responseedit) {
            const response = yield call(getList, `/users/user/${responseedit.Id}`);
            if (response) {
                // console.log('response.data.data,responseedit.data.data',response.data.data)
                // console.log('responseedit.data.data',responseedit)
                yield put(ProfilesListSuccessful(response.data.data,responseedit));                
            }
        }
    } catch (error) {
        yield put(addProfilesApiError(error));
    }
}

export function* watcheditProfiles() {
    yield takeEvery(EDIT_PROFILES, editProfiles);
}



function* ProfilesSaga() {
    yield all([fork(watchGetProfiles)]);
    yield all([fork(watcheditProfiles)]);
}

export default ProfilesSaga;