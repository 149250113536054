import { combineReducers  } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication Module
import Account from './auth/register/reducer';
import Login from './auth/login/reducer';
import Forget from './auth/forgetpwd/reducer';
import CustomerCareExecutive from './CustomerExecutive/reducer';
import Count_List from './Count/reducer';
import Notification_List from './Notification/reducer';
import Profiles from './Profile/reducer';
import TicketsType from './Tickets/TicketsType/reducer';
import TicketsReason from './Tickets/TicketsReason/reducer';
import Complaints from './Complaints/reducer';
import Remarks from './Remarks/reducer';
import OpenComplaints from './Complaints/Open/reducer';
import ClosedComplaints from './Complaints/Closed/reducer';
import AwaitingComplaints from './Complaints/Awaiting/reducer';
import MainDashboard from './MainDashboard/reducer';
import ProfileMenu from './auth/ProfileMenu/reducer'

const rootReducer = combineReducers({

    // public
    Layout,
    CustomerCareExecutive,
    MainDashboard,
    Count_List,
    Notification_List,
    Profiles,
    Account,
    Login,
    Forget,
    Complaints,
    TicketsType,
    TicketsReason,
    Remarks,
    OpenComplaints,
    ClosedComplaints,
    AwaitingComplaints,
    ProfileMenu,
    
});

export default rootReducer;