import { PROFILES_LIST,PROFILES_LIST_SUCCESSFUL,
     PROFILES_LIST_API_FAILED,EDIT_PROFILES,
     ADD_PROFILES_API_FAILED } from './actionTypes';

export const ProfilesList = (LoginId) => {

    return {
        type: PROFILES_LIST,
        payload: {LoginId}
    }
}

export const ProfilesListSuccessful = (Profiles, message='') => {

    return {
        type: PROFILES_LIST_SUCCESSFUL,
        payload: {Profiles, message},
    }
}





export const editProfiles = (Profiles) => {
    return {

        type: EDIT_PROFILES,
        payload: {Profiles},
        isAlertOpen: false,
        modal_static: false
    }
}

export const ProfilesListApiError = (error) => {
    return {
        type: PROFILES_LIST_API_FAILED,
        payload: error
    }
}
export const addProfilesApiError = (error) => {
    return {
        type: ADD_PROFILES_API_FAILED,
        payload: error
    }
}


