import { TICKETSREASON_LIST,TICKETSREASON_LIST_SUCCESSFUL,
     TICKETSREASON_LIST_API_FAILED,ADDTICKETSREASON,
     EDITTICKETSREASON,ADDTICKETSREASON_API_FAILED,
     DELETE_TICKETREASON,
     ADD_ACTIVITY,Activity_list } from './actionTypes';

export const TicketsReasonList = (loginId) => {
    return {
        type: TICKETSREASON_LIST,
        payload: {loginId}
    }
}

export const activitylist_successfull = (LoginId) => {
    return {
        type: Activity_list,
        payload: { LoginId }
    }
}

export const TicketsReasonListSuccessful = (TicketsReason, message='') => {

    return {
        type: TICKETSREASON_LIST_SUCCESSFUL,
        payload: {TicketsReason, message},
    }
}

export const TicketsReasonListApiError = (error) => {
    return {
        type: TICKETSREASON_LIST_API_FAILED,
        payload: error
    }
}


export const addTicketReason = (TicketsReason) => {
    return {
        
        type: ADDTICKETSREASON,
        payload: {TicketsReason},
        isAlertOpen1: false,
        modal_static1: false
    }
}

export const add_activity = (activity) => {
    // console.log("activityeeeeessss",activity);
    return {
        type: ADD_ACTIVITY,
        payload: {activity}
        // isAlertOpen: false,
        // modal_static: false
    }
}


export const addTicketReasonApiError = (error) => {
    return {
        type: ADDTICKETSREASON_API_FAILED,
        payload: error
    }
}


export const editTicketReason = (TicketsReason) => {
    return {
        type: EDITTICKETSREASON,
        payload: {TicketsReason},
        isAlertOpen1: false,
        modal_static1: false
    }
}

export const deleteTicketReason = (Id,status,CompanyCode,UpdatedBy) => {
    return {
        type: DELETE_TICKETREASON,
        payload: {Id,status,CompanyCode,UpdatedBy},
        isAlertOpen1: false,
        modal_static1: false
    }
}
