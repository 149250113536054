import axios from 'axios';

// const token = JSON.parse(localStorage.getItem('token'));

const baseURL = process.env.REACT_APP_API_BASEURL;
const token = JSON.parse(localStorage.getItem('token'));
// const companyId=JSON.parse(localStorage.getItem('token'));
// Gets the logged in user data from local session 
const getLoggedInUser = () => {
    const user = localStorage.getItem('authUser');
    if (user)
        return JSON.parse(user);
    return null;
}

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
}

// Register Method
const postRegister = (url, data) => {
    return axios.post(baseURL + url, data).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        var message;
        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 404: message = "Sorry! the page you are looking for could not be found"; break;
                case 500: message = "Sorry! something went wrong, please contact our support team"; break;
                case 401: message = "Invalid credentials"; break;
                default: message = err[1]; break;
            }
        }
        throw message;
    });

}

// Login Method
const postLogin = (url, data) => {
    
    // console.log("urll", baseURL + url, data.Email)
    // console.log("urll", baseURL + url, data.Password)
    // console.log('urll', baseURL + url, data.companyId)

    return fetch(baseURL + url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accepts': 'application/json',
        },
        body: JSON.stringify({
            username: data.Email,
            password: data.Password,
            companyId: data.companyId,
            // Loginurl:data.Loginurl
        })
    }).then(response => response.json())
        .then(response => {
            // console.log("response", response)
            if (response.status === 400 || response.status === 500)
                throw response.data;

            return response;
        }).catch(err => {
            // console.log("API Error = ", err)
            // console.log("API err[1] = ",err[1])
            throw err;
        });

}

// Login Method
// const postLogin = (url, data) => {
//     console.log("urll",baseURL+url, data.Email)  
//     console.log("urll",baseURL+url, data.Password)  

//     return axios.post(baseURL+url, data).then(response => {  
//         console.log("response",response)
//         if (response.status === 400 || response.status === 500)
//             throw response.data; 
//          return response;
//         }).catch(err => {
//             console.log("API Error = ",err)
//             throw err;
//          });

// }
// postForgetPwd 
const postForgetPwd = (url, data) => {
    return axios.post(baseURL + url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        // console.log("API Error", err)
        throw err[1];
    });
}
// DataList
// QueryTypeList


//  List
const getList = (url) => {
    const tokenData = JSON.parse(localStorage.getItem('token'));
    // console.log("call API",baseURL+url)
    // console.log("call APIAuthorization" , `Bearer ${tokenData}`)
    return axios.get(baseURL + url, { headers: { "Authorization": `Bearer ${tokenData}` } }).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response;
        //console.log("fake response",response)
        return response;
    }).catch(err => {
        throw getErrorMessage(err);
    });
}

//  List
const getSearchList = (url, data) => {
    const searcData = JSON.parse(localStorage.getItem('token'));
    // console.log("call API",baseURL+url)
    // console.log("call APIAuthorization" , `Bearer ${tokenData}`)
    return axios.post(baseURL + url, data, { headers: { "Authorization": `Bearer ${searcData}` } }).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response;
        //console.log("fake response",response)
        return response;
    }).catch(err => {
        throw getErrorMessage(err);
    });
}

const getDataById = (url) => {


    //const baseURLL = process.env.REACT_APP_API_BASEURL;
    const tokenByIdGet = JSON.parse(localStorage.getItem('token'));
    //  console.log(`${baseURLL}/customer/complaintcount/${this.state.RoleId}`)

    return fetch(baseURL + url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accepts': 'application/json',
            'Authorization': `Bearer ${tokenByIdGet}`
        }
    }).then(response => response.json())
        .then(response => {
            // console.log('response By ID Cout Fake', response);
            if (response.status === 400 || response.status === 500)
                throw response;
            // console.log("fake response", response)
            return response;
        }).catch(err => {
            // console.log('getErrorMessage  Fake Backend By Id== ', err);
            throw getErrorMessage(err);
        });

    // const tokenByIdGet = JSON.parse(localStorage.getItem('token')); 
    //  return  axios.get(baseURL+url, { headers: {"Authorization" : `Bearer ${tokenByIdGet}`} })
    //  .then(response => {
    //     console.log('response By ID',response);
    //     if (response.status === 400 || response.status === 500)
    //         throw response;
    //         //console.log("fake response",response)
    //     return response;
    // }).catch(err => {  
    //     console.log('getErrorMessage  Fake Backend By Id== ',err);
    //     throw getErrorMessage(err);
    // });
}

const addData = (url, data) => {
    const tokenAdd = JSON.parse(localStorage.getItem('token'));
    return axios.post(baseURL + url, data, { headers: { "Authorization": `Bearer ${tokenAdd}` } }).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        throw getErrorMessage(err);
    });
}


const getErrorMessage = (error) => {
    var message;
    if (error.response && error.response.status) {
        switch (error.response.status) {
            case 404: message = "Sorry! the page you are looking for could not be found"; break;
            case 500: message = "Sorry! something went wrong, please contact our support team"; break;
            case 401: message = "Invalid credentials"; break;
            case 422: message = "Email already exists"; break;
            default: message = "Sorry! something went wrong, please contact our support team"; break;
        }
    }
    return message;
}

// update Status
const deleteData = (url, data) => {
    // var body =  {"UpdatedBy":data.UserId, "status":data.status, "Id":data.Id};
    // console.log("body",body)
    // console.log('data', data)
    const tokenDelete = JSON.parse(localStorage.getItem('token'));
    return axios.post(baseURL + url, data, { headers: { "Authorization": `Bearer ${tokenDelete}` } }).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        throw getErrorMessage(err);
    });
}

// Edit 
const editData = (url, data) => {
    
    const tokenEdd = JSON.parse(localStorage.getItem('token'));
    return axios.post(baseURL + url, data, { headers: { "Authorization": `Bearer ${tokenEdd}` } }).then(response => {
        
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        throw getErrorMessage(err);
        
    });
}

const fetchLoginCompany = (url, data) => {
    
    return fetch(baseURL + url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accepts': 'application/json',
        },
        body: JSON.stringify({
            domainName: data.domainName
        })
    }).then(response => response.json())
        .then(response => {
            
            // console.log("response", response)
            if (response.status === 400 || response.status === 500)
                throw response.data.message;

            return response;
        }).catch(err => {
            
            // console.log("API Error = ", err)
            // console.log("API err[1] = ",err[1])
            throw err;
        });
}


export { getLoggedInUser, isUserAuthenticated, postRegister, postLogin, postForgetPwd, getList, getDataById, addData, deleteData, editData, getSearchList, fetchLoginCompany }
