import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { COUNT_LIST,COUNT_LIST_BY_ID, COUNT_LIST_API_FAILED } from './actionTypes';
import { Count_List_successfull, Count_List_API_FAILED } from './actions';

// AUTH related methods
import {getDataById} from '../../helpers/fackBackend_Helper';
  
function* getCount_List_By_Id({ payload:{Id} }) {
    try {
        // console.log('count Saga ID call', Id)
        const response = yield call(getDataById, `/customer/complaintcount/${Id}`);
        // console.log("response Count Saga ",response)
        if (response) {
            yield put(Count_List_successfull(response.data));
        }
    } catch (error) {
        yield put(Count_List_API_FAILED(error));
    }
}

export function* watchGetCount_List_By_Id() {
    yield takeEvery(COUNT_LIST_BY_ID, getCount_List_By_Id)
}
 
function* Count_ListSaga() { 
    yield all([fork(watchGetCount_List_By_Id)]);   
}

export default Count_ListSaga;