
import { EDIT_PASSWORD} from './actionTypes';
import { takeEvery,call,put,all,fork} from 'redux-saga/effects';
import { editpasswordsuccessfull,editpasswordfailed } from './actions';

import { editData } from '../../../helpers/fackBackend_Helper';

function* editPasswordById({payload:{ editUserPassword }}){
    
    try{
     const responseedit=yield call(editData,'/users/resetPassword',editUserPassword);
     
    //  console.log('responseeditpassword',responseedit);
    //  console.log('response',editUserPassword)
     
     if(responseedit){
        
        // console.log('response data',responseedit.message);
        yield put(editpasswordsuccessfull(responseedit.message))
     }
    }
    catch(error){
        
        yield put(editpasswordfailed(error));
    }
}

export function* watchEditPassword(){
    yield takeEvery(EDIT_PASSWORD,editPasswordById);
}

function* ProfileMenuSaga(){
    yield all([fork(watchEditPassword)]);
}

export default ProfileMenuSaga;