import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row, Col, Card, CardBody, UncontrolledTooltip, Alert,
  Modal, ModalHeader, ModalBody, ModalFooter, Button, Container, FormGroup, Label, Input
} from "reactstrap";
import { AvForm, AvInput, AvField } from "availity-reactstrap-validation";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "./datatables.scss";

// import BootstrapTable from 'react-bootstrap-table-next';
// import ToolkitProvider from 'react-bootstrap-table2-toolkit';
// import TicketingModel from '../../pages/Ticketing/TicketingModel';

import {
  CustomerCareExecutiveList,
  CustomerCareExecutiveByID,
  CustomerCareExecutiveListApiError,
  add_customer_care_executive,
  add_customer_care_executive_ApiError,
  edit_customer_care_executive,
  delete_customer_care_executive,
  add_activity,
  update_user_password,
} from '../../store/actions';


class SupportExecutive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modal_standard: false,
      modal_large: false,
      modal_xlarge: false,
      modal_small: false,
      modal_center: false,
      modal_scroll: false,
      modal_static: false,
      ErrorMsg: '',
      pwderror: '',
      strongPasswordError: false,
      cPasswordmessage: '',
      Passwordmessage: '',
      userAlert: false,
      Usermessage: '',
      userSuccess: false,
      DataAlertMessage: false,
      DataErrorMessage: false,
      AlertColor: '',
      message: '',
      changepassword: false,
      PasswordAlert: false,
      PasswordErrorAlert: false,
      DataAlert: false,
      DataError: false,
      DataMessage: "",
      ErrorMessage: '',
      phoneMatch: false,
      Phonemessage: '',
      passwordNewError: false,
      PasswordNewSuccess: false,
      UpdateAlert: false,
      ErrorAlert: false,
      addAlert: false,
      breadcrumbItems: [
        { title: "", link: "customer-executive" },
      ],
      loggedInUserId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).Id : '',
      RoleId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).RoleId : null,
      CompanyId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyId : null,
      CompanyCode: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyCode : null,
      // CompanyCode: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyCode : null,

      isAlertOpen: false,
      FirstName: '',
      LastName: '',
      PhoneNumber: '',
      Email: '',
      UserName: '',
      Password: '',
      NewPassword: '',
      ConfirmPassword: '',
      ConformPassword: '',
      CustomerCareExe: [],
      CustomerCareExeList: [],
      editCustomerCareExe: [],
      updateFirstName: '',
      updateLastName: '',
      updatePhoneNumber: '',
      updateEmail: '',
      updateUserName: '',
      updatePassword: '',
      updateConformPassword: '',
      updateisActive: '',
      Activity: '',
      UserIp: '',
      PasswordError: false,
      Alertmessage: "",
      AlertPropsMessage: '',
      ErrorPropsMessage: '',
      Alert: false,
      Success: false,

    };

    this.tog_standard = this.tog_standard.bind(this);
    this.tog_xlarge = this.tog_xlarge.bind(this);
    this.tog_large = this.tog_large.bind(this);
    this.tog_small = this.tog_small.bind(this);
    this.tog_center = this.tog_center.bind(this);
    this.tog_scroll = this.tog_scroll.bind(this);
    this.tog_static = this.tog_static.bind(this);

    this.addAcustomerCareExecutive = this.addAcustomerCareExecutive.bind(this);
    this.deletecustomerCareExecutiveById = this.deletecustomerCareExecutiveById.bind(this);
    this.UpdateCustomerExecutive = this.UpdateCustomerExecutive.bind(this);
    this.FormHandler = this.FormHandler.bind(this);
    this.FormUpdator = this.FormUpdator.bind(this);
    this.handlePwdKeyUp = this.handlePwdKeyUp.bind(this);
    this.handlePwd = this.handlePwd.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.removeError = this.removeError.bind(this);
    this.removeModal = this.removeModal.bind(this);
    this.removeUserError = this.removeUserError.bind(this);
    this.removePasswordError = this.removePasswordError.bind(this);
    this.removeConfirmError = this.removeConfirmError.bind(this);

  }

  editCustomerCareExecutiveById = (event) => {

    const CCId = event.target.dataset.param;
    console.log('CompId = ', CCId);
    const editData = this.state.CustomerCareExeList.filter(({ Id }) => Id == CCId);

    console.log('editData = ', editData);
    if (typeof editData !== "undefined") {
      this.setState({ editData: editData[0], modal_scroll: true, isAlertOpen: false, ErrorMsg: '' });
    } else {
      this.state.editData = [];
      this.setState({ editData: editData[0], modal_scroll: true, isAlertOpen: false, ErrorMsg: '' });
      console.log('PopupData ', editData);
      this.setState({ UpdateAlert: false, ErrorAlert: false, addAlert: false, Usermessage: '', Passwordmessage: '', cPasswordmessage: '', Phonemessage: '' })
    }


  }
  editPasswordById = (event) => {
    const CCId = event.target.dataset.param;
    const updateData = this.state.CustomerCareExeList.filter(({ Id }) => Id == CCId);
    console.log('Id', updateData);
    console.log('username', updateData[0].UserName);
    const UserNamePassword = updateData[0].UserName;
    this.setState({ UserNamePassword: updateData[0].UserName })
    if (typeof updateData !== "undefined") {
      this.setState({ updateData: updateData[0] })
    } else {
      this.setState.updateData = [];
      this.setState({ updateData: updateData[0] });
      console.log('Id', this.state.updateData)

    }
    this.setState({ changepassword: true, PasswordNewSuccess: false, NewPassword: '', ConfirmPassword: '', passwordNewError: false });
  }

  tog_static() {
    this.setState(prevState => ({
      modal_static: !prevState.modal_static
    }));
    this.removeBodyCss();
  }

  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  tog_large() {
    this.setState(prevState => ({
      modal_large: !prevState.modal_large
    }));
    this.removeBodyCss();
  }
  tog_xlarge() {
    this.setState(prevState => ({
      modal_xlarge: !prevState.modal_xlarge
    }));
    this.removeBodyCss();
  }
  tog_small() {
    this.setState(prevState => ({
      modal_small: !prevState.modal_small
    }));
    this.removeBodyCss();
  }
  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center
    }));
    this.removeBodyCss();
  }
  tog_scroll() {
    this.setState(prevState => ({

      modal_scroll: !prevState.modal_scroll
    }));
    this.removeBodyCss();
  }
  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  TicketingModel() {

    console.log("TicketingModel Call");

  }
  handleSelect(e) {
    console.log(e);
  }
  FormHandler(e) {

    let value = e.target.value;
    let name = e.target.name;

    this.setState({ [name]: value })
    this.setState({ updateisActive: e.target.value });
  }


  FormUpdator(e) {

    let value = e.target.value;
    let name = e.target.name;

    this.setState({ [name]: value })
    console.log(name);
    console.log(value);
    console.log({ [name]: value })
  }

  changePassword = (event, value) => {

    const usernameData = this.state.updateData.UserName
    const pass = value.NewPassword;
    const cfmpass = value.ConfirmPassword;
    // const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    // var test = strongRegex.test(pass);
    // if (test) {

    if (pass === cfmpass) {

      let updatePass = {
        Id: this.state.updateData.Id,
        Password: value.NewPassword,
        ConfirmPassword: value.ConfirmPassword,
      }
      // console.log(editPass);
      this.props.update_user_password(updatePass);
      setTimeout(() => {
        if (this.props.modalError === null) {

          this.setState({ PasswordNewSuccess: true });
          setTimeout(() => {
            this.setState({ changepassword: false, PasswordNewSuccess: false, passwordNewError: false });
          }, 4000);
        } else {
          this.setState({ passwordNewError: true, PasswordNewSuccess: false, changepassword: true });
          setTimeout(() => {
            this.setState({ passwordNewError: false, changepassword: true });
          }, 4000);
        }
      }, 2000);

    } else {
      this.setState({ PasswordError: true, Alertmessage: 'Password Confirmation does not match !' })
    }
    // } else {
    //   
    //   this.setState({ PasswordError: true, Alertmessage: 'Please type strong password !' })
    // }
  }

  handlePwdKeyUp(e) {

    let UserNameN = e.target.value;
    const UserNameData = this.state.CustomerCareExeList.filter(({ UserName }) => UserName == UserNameN);

    let ErrorMsg = '';
    let SuccessMsg = '';
    if (UserNameN.indexOf(' ') >= 0) {
      console.log("contains spaces", UserNameN);
      if (UserNameN != '') {
        // SuccessMsg = "";
        this.setState({ userAlert: true, Usermessage: 'This username is Not valid Contain spaces!', AlertColor: "danger" })
        setTimeout(() => {
          this.setState({ userAlert: false, Usermessage: '' })
        }, 5000);
      }
    } else if (UserNameData != '') {
      if (UserNameN != '') {
        // SuccessMsg = "";
        this.setState({ userAlert: true, Usermessage: 'This username is already exist', AlertColor: "danger" })
        setTimeout(() => {
          this.setState({ userAlert: false, Usermessage: '' })
        }, 3000);
        return false;
      }
    } else {
      if (UserNameN != '') {
        // this.setState({ userAlert: true, message: 'This username is available', AlertColor: 'success' });
        setTimeout(() => {
          this.setState({ userAlert: false });
        }, 5000);
      } else {
        SuccessMsg = "";
        this.setState({ SuccessMsg });
        ErrorMsg = "";
        this.setState({ ErrorMsg });
        this.setState({ visible: true }, () => {
          window.setTimeout(() => {
            this.setState({ visible: false })
          }, 5000)
        });
      }
    }

  }


  handlePwd(event) {

    var pass = event.target.value;
    //  var reg = '/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/';
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    var test = strongRegex.test(pass);
    if (test) {
      // alert('pass');
      console.log('pass', pass);
      this.setState({ value: pass });
    } else {
      this.setState({})
    }

  }


  handlePhone(event) {
    let Phoneerror = '';
    let SuccessPhone = '';
    this.setState({ Phoneerror });
    this.setState({ SuccessPhone });
    if (typeof event.target.value !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);

      if (!pattern.test(event.target.value)) {


        // this.setState({phoneMatch:true,Phonemessage:'Please enter number only !'})
        // Phoneerror = "Please enter number only !";
        // this.setState({ Phoneerror });
        // this.setState({ visible: true }, () => {
        //   window.setTimeout(() => {
        //     this.setState({ visible: false })
        //   }, 2000)
        // });
      } else if (event.target.value.length < 10) {
        console.log(event.target.value.length);

        //  console.log("Please enter valid phone number !");
        //  this.setState({ SuccessPhone });
        // Phoneerror = "Please enter valid phone number !";
        // this.setState({ Phoneerror });
        // this.setState({ visible: true }, () => {
        //   window.setTimeout(() => {
        //     this.setState({ visible: false })
        //   }, 2000)
        // });

      } else {

        var phone = event.target.value;
        //  var reg = '/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/';
        const strongRegex = new RegExp(/^[0-9\b]+$/);
        var test = strongRegex.test(phone);
        if (test) {
          // alert('pass');
          console.log('phone', phone);
          this.setState({ value: phone });
          //  console.log("Please enter valid phone number !");
          //  this.setState({ SuccessPhone });
          // SuccessPhone = "valid phone number ";
          // this.setState({ SuccessPhone });
          this.setState({ visible: true }, () => {
            window.setTimeout(() => {
              this.setState({ visible: false })
            }, 2000)
          });
          // }else{
          //   console.log('fail',phone);
        }
      }

    }

  }

  removeError() {
    this.setState({
      PasswordError: false, phoneMatch: false, Phonemessage: '',
      Alertmessage: '', message: ''
    });
  }

  removeUserError() {
    this.setState({ userAlert: false, Usermessage: '' })
  }
  removePasswordError() {
    this.setState({
      strongPasswordError: false,
      Passwordmessage: ''
    })
  }
  removeConfirmError() {
    this.setState({ strongPasswordError: false, cPasswordmessage: '' })
  }

  handleNumber(e) {
    const keyCode = e.keyCode;
    if (((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) && e.keyCode != 8) {
      e.preventDefault();
    }
  }

  removeModal() {
    this.setState({
      changepassword: false, NewPassword: '', ConfirmPassword: '', PasswordError: false, Alertmessage: '', PasswordError: false, phoneMatch: false, Phonemessage: '',
      Alertmessage: '', userAlert: false, Usermessage: '', strongPasswordError: false,
      Passwordmessage: '', cPasswordmessage: '', message: '', modal_scroll: false
    })
  }


  componentDidMount() {
    this.props.CustomerCareExecutiveListApiError("");
    // this.props.addCompanyApiError("");
    this.props.CustomerCareExecutiveList(this.state.CompanyCode);
  }
  componentWillReceiveProps(nextProps) {

    // console.log("Props CustomerCareExe",nextProps.CustomerCareExe )
    this.setState({ CustomerCareExeList: nextProps.CustomerCareExe });
  }

  deletecustomerCareExecutiveById = (event) => {

    var d = new Date();
    var curr_date = d.getDate();
    var curr_month = d.getMonth();
    var curr_year = d.getFullYear();
    var curr_Hour = d.getHours();
    var curr_Minutes = d.getMinutes();
    var curr_Seconds = d.getSeconds();
    const datetime = curr_year + "-" + curr_month + "-" + curr_date + " " + curr_Hour + ":" + curr_Minutes + ":" + curr_Seconds

    //console.log("event",event.target.dataset)
    const { param } = event.target.dataset;
    const status = 'Delete';
    let activity = {
      Activity: "Delete Executive",
      ActivityEndTime: datetime,
      ActivityType: 7,
      UserId: this.state.loggedInUserId,
      ComponyId: this.state.CompanyId,
      RoleId: this.state.RoleId,
      UserIp: "61.247.235.72",
      status: "add"
    };
    this.props.delete_customer_care_executive(param, this.state.loggedInUserId, this.state.CompanyCode, status);

    if (this.props.modalError === null) {
      this.setState({ isAlertOpen: true });

      this.setState({ modal_scroll: false });
      this.setState({ visible: true }, () => {
        window.setTimeout(() => {
          this.setState({ visible: false })
        }, 5000)
      });
    }

    console.log("activityyyyyyyyyyy", activity);
    this.props.add_activity(activity);



  }

  addAcustomerCareExecutive = (event, values) => {

    var d = new Date();
    var curr_date = d.getDate();
    var curr_month = d.getMonth();
    var curr_year = d.getFullYear();
    var curr_Hour = d.getHours();
    var curr_Minutes = d.getMinutes();
    var curr_Seconds = d.getSeconds();
    const datetime = curr_year + "-" + curr_month + "-" + curr_date + " " + curr_Hour + ":" + curr_Minutes + ":" + curr_Seconds

    const pwd = values.Password;
    var phone = values.PhoneNumber;
    //  var reg = '/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/';
    const strongReg = new RegExp(/^[0-9\b]+$/);
    var testphone = strongReg.test(phone);

    if (testphone) {
      const cwpwd = values.ConformPassword;
      const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      var test = strongRegex.test(pwd);
      if (test) {
        // alert('pass');
        // console.log('pass',pass);
        // this.setState({value: pass});

        let customerCareExeData = {
          FirstName: values.FirstName,
          LastName: values.LastName,
          PhoneNumber: values.PhoneNumber,
          Email: values.Email,
          UserName: values.UserName,
          Password: values.Password,
          ConformPassword: values.ConformPassword,
          CompanyId: this.state.CompanyId,
          CompanyCode: this.state.CompanyCode,
          status: "add",
          isActive: 1,
          RoleId: 3,
          CreatedBy: this.state.loggedInUserId
        };

        let activity = {
          Activity: "Add Executive",
          ActivityEndTime: datetime,
          ActivityType: 5,
          UserId: this.state.loggedInUserId,
          ComponyId: this.state.CompanyId,
          RoleId: this.state.RoleId,
          UserIp: "61.247.235.72",
          status: "add"
        };
        if (pwd === cwpwd) {

          this.props.add_customer_care_executive(customerCareExeData);

          setTimeout(() => {
            if (this.props.apiError === null) {

              this.setState({ addAlert: true, ErrorAlert: false })
              setTimeout(() => {
                this.setState({ addAlert: false })
                this.setState({ modal_scroll: false });
              }, 4000);
            } else {

              this.setState({ ErrorAlert: true, modal_scroll: true, addAlert: false });
              setTimeout(() => {
                this.setState({ ErrorAlert: false, addAlert: false });
              }, 4000);
            }
          }, 3000);


        } else {
          // let pwderror='';
          // 
          // pwderror = "Confirm Password Not Match";
          // this.setState({ pwderror });
          this.setState({ strongPasswordError: true, cPasswordmessage: 'Password Confirmation does not match !' })
          this.setState({ visible: true }, () => {
            window.setTimeout(() => {
              // this.setState({visible:false})
              this.setState({ strongPasswordError: false })
            }, 5000)
          });


        }

        console.log("activityyyyyyyyyyy", activity);
        this.props.add_activity(activity);

      }
      else {

        // let pwderror='';
        // pwderror = "Please type strong password !";
        // this.setState({ pwderror });
        this.setState({ strongPasswordError: true, Passwordmessage: 'Please enter the strong type password !' })
        this.setState({ visible: true }, () => {
          window.setTimeout(() => {
            // this.setState({visible:false})
            this.setState({ strongPasswordError: false })
          }, 5000)
        });
      }

    } else {

      // let Phoneerror='';
      // Phoneerror = "Please enter number only !";
      // this.setState({ Phoneerror });
      this.setState({ strongPasswordError: true, message: 'Please enter number only !' })
      this.setState({ visible: true }, () => {
        window.setTimeout(() => {
          // this.setState({visible:false})
          this.setState({ strongPasswordError: false })
        }, 5000)

      });

    }
  }


  // Submit Updated data

  UpdateCustomerExecutive = (event, values) => {

    var d = new Date();
    var curr_date = d.getDate();
    var curr_month = d.getMonth();
    var curr_year = d.getFullYear();
    var curr_Hour = d.getHours();
    var curr_Minutes = d.getMinutes();
    var curr_Seconds = d.getSeconds();
    const datetime = curr_year + "-" + curr_month + "-" + curr_date + " " + curr_Hour + ":" + curr_Minutes + ":" + curr_Seconds

    var phone = values.PhoneNumber;
    const strongReg = new RegExp(/^[0-9\b]+$/);
    var testphone = strongReg.test(phone);
    if (testphone) {
      // const pwd = values.Password;
      // const cwpwd = values.ConformPassword;
      // const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      // var test = strongRegex.test(pwd);

      if (testphone) {

        let customerCareExeData = {
          FirstName: values.FirstName,
          LastName: values.LastName,
          PhoneNumber: values.PhoneNumber,
          Email: values.Email,
          UserName: values.UserName,
          // Password: values.Password,
          // ConformPassword: values.ConformPassword,
          IsActive: values.IsActive,
          CompanyId: this.state.CompanyId,
          CompanyCode: this.state.CompanyCode,
          status: "edit",
          RoleId: 3,
          UpdatedBy: this.state.loggedInUserId,
          Id: this.state.editData.Id
        };


        let activity = {
          Activity: "Update Executive",
          ActivityEndTime: datetime,
          ActivityType: 7,
          UserId: this.state.loggedInUserId,
          ComponyId: this.state.CompanyId,
          RoleId: this.state.RoleId,
          UserIp: "61.247.235.72",
          status: "add"
        };

        console.log('Description = ', customerCareExeData);
        console.log('props = ', this.props);
        this.props.edit_customer_care_executive(customerCareExeData);
        setTimeout(() => {
          if (this.props.modalError === null) {
            this.setState({ UpdateAlert: true, ErrorAlert: false })
            setTimeout(() => {
              this.setState({ UpdateAlert: false })
              this.setState({ modal_scroll: false });
            }, 6000);

          } else {
            this.setState({ ErrorAlert: true, modal_scroll: true });
            setTimeout(() => {
              this.setState({ ErrorAlert: false, UpdateAlert: false });
            }, 6000);
          }
        }, 2000);

        // if (pwd === cwpwd) {

        // } else {
        //   let pwderror = '';
        //   pwderror = "Confirm Password Not Match";
        //   this.setState({ pwderror });
        //   this.setState({ visible: true }, () => {
        //     window.setTimeout(() => {
        //       this.setState({ visible: false })
        //     }, 5000)
        //   });


        // }

        console.log("activitupdate", activity);
        this.props.add_activity(activity);
      }
      // else {
      //   let pwderror = '';
      //   pwderror = "Please type strong password !";
      //   this.setState({ pwderror });
      //   this.setState({ visible: true }, () => {
      //     window.setTimeout(() => {
      //       this.setState({ visible: false })
      //     }, 5000)
      //   });
      // }
    } else {

      let Phoneerror = '';
      Phoneerror = "Please enter number only !";
      this.setState({ Phoneerror });
      this.setState({ visible: true }, () => {
        window.setTimeout(() => {
          this.setState({ visible: false })
        }, 5000)
      });

    }     // setTimeout(() => {
    //   console.log('this.props',this.props,'modal error',this.props.modalError);
    //   if (this.props.modalError === null) {
    //     this.setState({ isAlertOpen: true });
    //     setTimeout(() => {
    //       this.setState({ modal_scroll: false });
    //     }, 1000);
    //   }
    // }, 1000);
  }



  render() {

    const { CustomerCareExeList } = this.state;
    const CustomerEditData = this.state.editData;
    const updateData = this.state.updateData;
    const UserNamePassword = this.state.UserNamePassword;
    const CustomerListNew = [];
    // let disableButton; 
    CustomerCareExeList && CustomerCareExeList.map((CustomerList, i) => {
      // console.log(changePasswordData.UserName);

      CustomerListNew.push(
        {
          SN: i + 1,
          name: `${CustomerList.FirstName} ${CustomerList.LastName}`,
          UserName: CustomerList.UserName,
          Email: CustomerList.Email,
          PhoneNumber: CustomerList.PhoneNumber,
          Status: CustomerList.IsActive == '1' ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>,
          Action: <div className="badge badge-soft-success font-size-12">

            <Link to="#" data-toggle="modal"
              className="mr-3 text-primary" id={"edit" + CustomerList.Id}><i onClick={this.editCustomerCareExecutiveById} data-param={CustomerList.Id} className="mdi mdi-pencil font-size-18"></i></Link>

            {CustomerList.IsActive == '1' ?
              <Link to="#" className={`text-danger`}
                id={"delete" + CustomerList.Id}><i className="mdi mdi-trash-can font-size-18 mr-3"
                  onClick={this.deletecustomerCareExecutiveById} data-param={CustomerList.Id}></i>
              </Link> : ''}

            <Link to="#" data-toggle="modal"
              className="mr-3 text-primary"><i onClick={this.editPasswordById} data-param={CustomerList.Id} className="mdi mdi-lock font-size-18"></i></Link>
          </div>
        })

    })

    const data = {
      columns: [
        {
          label: "Sl",
          field: "SN",
          sort: "asc",
          width: 150
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 150
        },
        {
          label: "User Name",
          field: "UserName",
          sort: "asc",
          width: 150
        },
        {
          label: "Email",
          field: "Email",
          sort: "asc",
          width: 150
        },
        {
          label: "Contact Number",
          field: "PhoneNumber",
          sort: "asc",
          width: 150
        },

        {
          label: "Status",
          field: "Status",
          sort: "asc",
          width: 270
        },
        {
          label: "Action",
          field: "Action",
          sort: "asc",
          width: 270
        },
      ],
      rows: CustomerListNew
    };



    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Customer Care Executive" breadcrumbItems={this.state.breadcrumbItems} />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {/* <Alert color="success" isOpen={this.state.DataAlert}>{this.state.DataMessage}</Alert>
                    <Alert color="danger" isOpen={this.state.DataError}>{this.state.ErrorMessage}</Alert> */}
                    {/* <Alert color={this.state.AlertColor} isOpen={this.state.DataAlertMessage}>{this.state.message} </Alert> */}
                    <div className="text-right">
                      <Button
                        type="button"
                        color="primary" className="waves-effect waves-light align-center"
                        onClick={this.editCustomerCareExecutiveById}
                        data-toggle="modal"
                      >
                        Add
                      </Button>
                    </div>
                    {/* <h4 className="card-title">Customer Care Executive</h4> */}

                    <MDBDataTable responsive bordered hover data={data} />

                    {/* Create Ticket tog_center */}
                    <Modal
                      isOpen={this.state.modal_scroll}
                      // onClick={this.removeModal}
                      scrollable={true}
                      className="overflow-hidden"
                    >
                      <ModalHeader toggle={this.removeModal}>
                        {(CustomerEditData) ? 'Edit Customer Care Executive' : 'Add Customer Care Executive '}
                      </ModalHeader>

                      <ModalBody className="my-2">
                        {/* {this.props.apiError && this.props.apiError ? <Alert color="danger" Alert={this.props.Alert}>Data Not Submitted</Alert> : null}
                    {this.props.message && this.props.message !== '' ? <Alert color="success" Success={this.props.Success}>{this.props.message} </Alert> : null} */}
                        {this.state.addAlert ? <Alert color="success" >Customer care executive detail added successfully !</Alert> : null}
                        {this.state.ErrorAlert ? <Alert color="danger">Form Contains errors.Please see fields marked in red !</Alert> : null}
                        {this.state.UpdateAlert ? <Alert color="success">Customer care executive detail updated successfully !</Alert> : null}
                        <AvForm onValidSubmit={(CustomerEditData) ? this.UpdateCustomerExecutive : this.addAcustomerCareExecutive} className="form-horizontal" >
                          <FormGroup row>
                            <div className="col-md-6 frm-hit">
                              <Label htmlFor="FirstName">First Name <span className="mandatory">*</span></Label>

                              <AvField className="form-control" type="text" required name="FirstName"  value={CustomerEditData && CustomerEditData.FirstName} onChange={this.FormHandler} placeholder="FirstName.." id="example-text-input" />
                            </div>
                            <div className="col-md-6 frm-hit">
                              <Label htmlFor="LastName">Last Name <span className="mandatory">*</span></Label>
                              <AvField className="form-control" type="text" required name="LastName"  value={CustomerEditData && CustomerEditData.LastName} onChange={this.FormHandler} placeholder="LastName.." id="example-text-input" />
                            </div>
                          </FormGroup>
                          <FormGroup row>
                            <div className="col-md-6 frm-hit">
                              <Label htmlFor="PhoneNumber">Phone Number <span className="mandatory">*</span></Label>
                              <AvField className="form-control " maxLength={10} type="text" required name="PhoneNumber" onKeyDown={this.handleNumber} onInput={this.removeError} value={CustomerEditData && CustomerEditData.PhoneNumber} onKeyUp={this.handlePhone} onChange={this.FormHandler} placeholder="Phone Number.." id="example-text-input" />
                              <p style={{ color: '#ff3d60' }} phoneMatch={this.state.phoneMatch}>{this.state.Phonemessage}</p>
                              {/* {this.state.Phoneerror && this.state.Phoneerror ? <Alert color="danger" isOpen={this.state.visible}>{this.state.Phoneerror}</Alert> : null}
                              {this.state.SuccessPhone && this.state.SuccessPhone ? <Alert color="success" isOpen={this.state.visible}>{this.state.SuccessPhone}</Alert> : null} */}
                            </div>
                            <div className="col-md-6 frm-hit">
                              <Label htmlFor="Email">Email <span className="mandatory">*</span></Label>
                              <AvField className="form-control" type="email" required name="Email" value={CustomerEditData && CustomerEditData.Email} onChange={this.FormHandler} placeholder="Your Mail.." id="example-text-input" />
                            </div>
                          </FormGroup>
                          <FormGroup row>
                            <div className="col-md-12 frm-hit">
                              <Label htmlFor="UserName">User Name <span className="mandatory">*</span></Label>
                              <AvField className="form-control" type="text" required name="UserName" onInput={this.removeUserError} value={CustomerEditData && CustomerEditData.UserName} onKeyUp={this.handlePwdKeyUp} onChange={this.FormHandler} placeholder="User Name.." id="UserName" autoComplete="off" />
                              {/* <span style={{ color: "red" }}>{this.state.ErrorMsg && this.state.ErrorMsg ? this.state.ErrorMsg:''}</span><span style={{ color: "green" }}>{this.state.SuccessMsg}</span> */}
                              {/* {this.state.ErrorMsg && this.state.ErrorMsg ? <Alert color="danger" isOpen={this.state.visible}>{this.state.ErrorMsg}</Alert> : null }
                                                {this.state.SuccessMsg && this.state.SuccessMsg ? <Alert color="success" isOpen={this.state.SuccessMsg}>{this.state.SuccessMsg}</Alert> : null } */}
                              <p style={{ color: '#ff3d60' }} className="alert" userAlert={this.state.userAlert}>{this.state.Usermessage}</p>
                              {/* <Alert color="success" isOpen={this.state.userSuccess}>{this.state.message}</Alert> */}
                            </div>
                          </FormGroup>
                          {(CustomerEditData) ? '' :
                            <FormGroup row>
                              <div className="col-md-12 frm-hit">
                                <Label htmlFor="Password">Password <span className="mandatory">*</span></Label>
                                <AvField className="form-control" type="password" required name="Password" onInput={this.removePasswordError} value={CustomerEditData && CustomerEditData.Password} onKeyUp={this.handlePwd} onChange={this.FormHandler} placeholder="Password.." id="example-text-input" autoComplete="off" />
                                <p style={{ color: '#ff3d60' }} className="alert" strongPasswordError={this.state.strongPasswordError}>{this.state.Passwordmessage}</p>
                              </div>
                            </FormGroup>}

                          {(CustomerEditData) ? '' :
                            <FormGroup row>
                              <div className="col-md-12 frm-hit">

                                <Label htmlFor="ConformPassword">Confirm Password <span className="mandatory">*</span></Label>
                                <AvField className="form-control" type="password" required name="ConformPassword" onInput={this.removeConfirmError} value={CustomerEditData && CustomerEditData.Password} onKeyUp={this.handlePwd} onChange={this.FormHandler} placeholder=" Confirm Password.." id="example-text-input" />
                                {/* <span style={{ color: "red" }}>{this.state.pwderror && this.state.pwderror ? this.state.pwderror:''}</span> */}
                                {/* {this.state.pwderror && this.state.pwderror ? <Alert color="danger" isOpen={this.state.visible}>{this.state.pwderror}</Alert> : null } */}
                                <p style={{ color: '#ff3d60' }} className="alert" strongPasswordError={this.state.strongPasswordError}>{this.state.cPasswordmessage}</p>
                              </div>
                            </FormGroup>
                          }
                          {(CustomerEditData) ?
                            <FormGroup row>
                              <Col md={12}>
                                <Label htmlFor="Status">Status <span className="mandatory">*</span></Label>
                                <AvField className="form-control" name="IsActive" type="select" value={CustomerEditData && JSON.stringify(CustomerEditData.IsActive)} id="IsActive" onChange={this.FormHandler} required>
                                  <option value="">Select</option>
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                                </AvField>
                              </Col>
                            </FormGroup> : ''}
                          <ModalFooter>
                            <Button type="button" color="light" onClick={this.removeModal}>Close</Button>
                            <Button type="submit" color="primary" >{(CustomerEditData) ? 'Update' : 'Save'}</Button>
                          </ModalFooter>
                        </AvForm>

                      </ModalBody>
                    </Modal>


                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal isOpen={this.state.changepassword}>
              <ModalHeader toggle={this.removeModal}>
                Change Password
              </ModalHeader>
              <ModalBody className="my-2">
                {/* {this.props.modalError && this.props.modalError ? <Alert color="danger" isOpen={this.state.passwordNewError}>Data Not Submitted!</Alert>:null} */}
                {/* {this.props.message && this.props.message !=='' ?<Alert color="success" isOpen={this.state.PasswordNewSuccess}>Password Updated Successfully</Alert>:null} */}
                {this.state.PasswordNewSuccess ? <Alert color="success">Password updated successfully !</Alert> : null}
                {this.state.passwordNewError ? <Alert color="danger">Unable to change password due to an unknown error. Please try again</Alert> : null}
                <h6 className="mb-3">User Name :<span className="text-primary"> {this.state.UserNamePassword}</span></h6>
                {/* <p>{`${updateData}`}</p> */}
                <AvForm onValidSubmit={this.changePassword}>
                  <FormGroup row>
                    <div className="col-md-12 frm-hit">
                      <Label htmlFor="NewPassword">New Password <span className="mandatory">*</span></Label>
                      <AvField className="form-control" type="password" value={this.state.NewPassword} name="NewPassword" onChange={this.FormUpdator} required placeholder="New Password" id="example-text-input" />
                    </div>
                  </FormGroup>
                  <FormGroup row>
                    <div className="col-md-12 frm-hit">
                      <Label htmlFor="ConfirmPassword">Confirm Password<span className="mandatory">*</span></Label>
                      <AvField className="form-control" type="password" name="ConfirmPassword" state={this.state.ConfirmPassword} onInput={this.removeError} onChange={this.FormUpdator} required placeholder="Confirm Password" id="example-text-input" />
                      <p style={{ color: '#ff3d60' }} className="alert" >{this.state.Alertmessage}</p>
                    </div>
                  </FormGroup>
                  <ModalFooter>
                    <Button type="button" color="secondary" className="mx-2" onClick={this.removeModal} >Cancel</Button>
                    <Button type="submit" color="primary" >Change Password</Button>
                  </ModalFooter>
                </AvForm>
              </ModalBody>
            </Modal>

          </Container>
        </div>
      </React.Fragment>
    );
  }
}
//export default SupportExecutive;


const mapStatetoProps = state => {
  const { CustomerCareExe, editUserPassword, apiError, loading, Alert, Success, isAlertOpen, modal_static, message, modalError, passwordError, PasswordSuccess } = state.CustomerCareExecutive;      // .SupportExecutive
  return { CustomerCareExe, apiError, editUserPassword, loading, Alert, Success, isAlertOpen, modal_static, message, modalError, passwordError, PasswordSuccess };
}

export default withRouter(connect(mapStatetoProps, {
  CustomerCareExecutiveList,
  CustomerCareExecutiveByID,
  CustomerCareExecutiveListApiError,
  add_customer_care_executive,
  add_customer_care_executive_ApiError,
  edit_customer_care_executive,
  delete_customer_care_executive,
  add_activity,
  update_user_password,
})(SupportExecutive));

