import { TICKETSREASON_LIST,TICKETSREASON_LIST_SUCCESSFUL,
    TICKETSREASON_LIST_API_FAILED,ADDTICKETSREASON,
    EDITTICKETSREASON,ADDTICKETSREASON_API_FAILED,
    DELETE_TICKETREASON,ADD_ACTIVITY, } from './actionTypes';
const initialState = {
    apiError1: null, loading1: false, TicketsReason: [], isAlertOpen1: false, modal_static1: false,message:null, message1: null, modalError1: null
}

const TicketsReason = (state = initialState, action) => {
    switch (action.type) {
        case TICKETSREASON_LIST:
            state = {
                ...state,
                loading1: true,
                apiError1: null
            }
            break;
        case TICKETSREASON_LIST_SUCCESSFUL:
            //console.log(action.payload.TicketsReason);
            state = {
                ...state,
                TicketsReason: action.payload.TicketsReason,
                loading1: false,
                message1: action.payload.message.message
            }
            break;
        case TICKETSREASON_LIST_API_FAILED:
            // console.log(action.payload);
            state = {
                ...state,
                loading1: false,
                apiError1: action.payload,
                TicketsReason: []
            }
            break;
            case ADDTICKETSREASON:
            state = {
                ...state,
                loading1: true,
                modalError1: null
            }
            break;
            case ADD_ACTIVITY:
            state = {
                    ...state
                    // loading: true,
                    // modalError: null
            }
            break;    
         
            case ADDTICKETSREASON_API_FAILED:
            state = {
                ...state,
                loading1: false,
                modalError1: action.payload.message,
            }
            break;
            case EDITTICKETSREASON:
            state = {
                ...state,
                loading1: true,
                modalError1: null,
                message1: null,
            }
            break;
            case DELETE_TICKETREASON:
            state = {
                ...state,
                loading1: true,
                apiError1: null,
                message1: null,
            }
            break;
        default:
            state = { ...state };
            break;
    }

    return state;
}

export default TicketsReason;