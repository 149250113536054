export const TICKETSREASON_LIST = 'TicketsReason_list';
export const TICKETSREASON_LIST_SUCCESSFUL = 'TicketsReason_list_successfull';
export const TICKETSREASON_LIST_API_FAILED = 'TicketsReason_list_api_failed';
export const ADDTICKETSREASON = 'AddTicketsReason';
export const EDITTICKETSREASON = 'EditTicketsReason';
export const ADDTICKETSREASON_API_FAILED = 'AddTicketsReason_api_failed';
export const DELETE_TICKETREASON = 'Delete_TicketReason';
export const ADD_ACTIVITY = 'add_activity';
export const Activity_list = 'activity_list';
export const ADD_Activity_API_FAILED = 'add_activity_api_error';
