import React, { Component } from "react";
//import React,{useState,useEffect} from 'react'


import Moment from 'moment';
// import Axios from 'axios';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// import {useHistory} from 'react-router-dom'
import { AvForm,  AvField } from "availity-reactstrap-validation";

// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { MDBDataTable } from "mdbreact";
// import Multiselect from 'multiselect-react-dropdown';
// import { MultiSelect } from "react-multi-select-component";
// import Select from 'react-select';
// import AvForm from 'availity-reactstrap-validation';

import {
  Row, Col, Card, CardBody,
   Container, FormGroup, Label, Alert
} from "reactstrap";

import Breadcrumbs from '../../components/Common/Breadcrumb';
import "./datatables.scss";

import {editCustomerComplaints,ComplaintsList,CustomerCareExecutiveList,RemarksList,editRemarks,RemarksListApiError} from '../../store/actions';

class Remarks extends Component {
  constructor(props) {
    super(props);
    
    this.multiselectRef = React.createRef();

    this.state = {
      visible: false,
      isAlertOpen: false,
      modal_standard: false,
      modal_large: false,
      modal_xlarge: false,
      modal_small: false,
      modal_center: false,
      modal_scroll: false,
      modal_reason: false,
      modal_static: false,
      breadcrumbItems: [
        // { title: "Back", link: "/tickets" }
      ],
      TicketsType: [],
      TicketsReason: [],
      TicketTypeName: "",
      TicketType: [],
      TicketReasonName: '',
      CustomerExecutive: [],
      editData: null,
      editReasonData: null,
      IsActive:'',
      Complaints:[],
      // CreatedBy:'1', 
      loginUser: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).Id : '',
      CompanyId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyId : '',
      RoleId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).RoleId : '',
      CompanyCode: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyCode : '',
      ComplaintId:props.match.params.id
    };
    this.FormHandler = this.FormHandler.bind(this);
    this.tog_standard = this.tog_standard.bind(this);
    this.tog_xlarge = this.tog_xlarge.bind(this);
    this.tog_large = this.tog_large.bind(this);
    this.tog_small = this.tog_small.bind(this);
    this.tog_center = this.tog_center.bind(this);
    this.tog_scroll = this.tog_scroll.bind(this);
    this.tog_reason = this.tog_reason.bind(this);
    this.tog_static = this.tog_static.bind(this);
    this.editRemark = this.editRemark.bind(this);

  }

  componentDidMount() {
    this.props.RemarksList(this.state.CompanyCode,this.state.ComplaintId);
    this.props.CustomerCareExecutiveList(this.state.CompanyCode);
    this.props.ComplaintsList(this.state.loginUser);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ Remarks: nextProps.Remarks });
    this.setState({CustomerCareExeList: nextProps.CustomerCareExe});
    this.setState({Complaints: nextProps.Complaints}); 
  }

  editRemark = (event, values) => {
    let ComplaintsData = {
      TicketType: values.TicketType,
      TicketReason: values.TicketReason,
      AssignTo: values.AssignTo,
      Description: values.Description,
      Remarks: values.Remarks,
      CompanyId: this.state.CompanyId,
      CompanyCode: this.state.CompanyCode,
      UpdatedBy: this.state.loginUser,
      IsActive:1,
      Status: values.Status,
      ComplaintId: this.state.ComplaintId,
      status: 'edit'
    };
    console.log('editRemarks parameters = ',ComplaintsData);
    console.log('this.props = ',this.props);
    this.props.editRemarks(ComplaintsData);
    if (this.props.modalError === null) {

      this.setState({visible:true},()=>{
        window.setTimeout(()=>{
          this.setState({visible:false})
        },5000)
      });
      
    }
  }
  tog_static() {
    this.setState(prevState => ({
      modal_static: !prevState.modal_static
    }));
    this.removeBodyCss();
  }

  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  tog_large() {
    this.setState(prevState => ({
      modal_large: !prevState.modal_large
    }));
    this.removeBodyCss();
  }
  tog_xlarge() {
    this.setState(prevState => ({
      modal_xlarge: !prevState.modal_xlarge
    }));
    this.removeBodyCss();
  }
  tog_small() {
    this.setState(prevState => ({
      modal_small: !prevState.modal_small
    }));
    this.removeBodyCss();
  }
  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center
    }));
    this.removeBodyCss();
  }
  tog_scroll() {
    this.setState(prevState => ({
      modal_scroll: !prevState.modal_scroll
      // componentDidMount()
    }));
    this.removeBodyCss();
  }
  tog_reason() {
    this.setState(prevState => ({
      modal_reason: !prevState.modal_reason
    }));
    this.removeBodyCss();
  }
  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  TicketingModel() {
    console.log("TicketingModel Call");
  }
  handleSelect(e) {
    console.log(e);
  }

  FormHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value }, function () {
    });
  }



  render() {
    const { CustomerCareExeList } = this.state;
    console.log('CustomerCareExe',CustomerCareExeList);
    const OptionExecutive = CustomerCareExeList && CustomerCareExeList.map(person =>
      <option key={person.Id} value={person.Id}>{person.UserName}</option>
    )
    console.log(this.props);
    
    const { Remarks } = this.state
    // const { Remarks } = this.state;
  console.log('Remarks',Remarks);
  const rowData = [];
  const editData = this.state.Complaints.filter(({ ComplaintId }) => ComplaintId == this.state.ComplaintId);
  console.log('editData',editData);
  console.log('editData[0]',editData[0]);
  // this.setState({ RemarksEditData: editData[0] && editData[0] });
  console.log('this.state.editData',this.state.editData);
  const RemarksEditData = this.state.editData;
  console.log('RemarksEditData',RemarksEditData);
  Remarks && Remarks.map((item,index) => {
     
    rowData.push({
        SN: index+1,
        TicketId : item.TicketId,
        Description: item.Description,
        DealerName: item.DealerName,
        AssignTo: item.UserName,
        Status: item.Status,
        Remarks: item.Remarks,
        ReferedBy: item.ReferedBy,
        CreatedDate: Moment(item.CreatedDate).format('MM-DD-YYYY HH:mm:ss'),       
     });
 });
 const data = {
  columns: [
    {
      label: "Sl",
      field: "SN",
      sort: "asc",
      width: 150
    },
    {
      label: "Ticket Id",
      field: "TicketId",
      sort: "asc",
      width: 150
    }, 
    {
      label: "Description",
      field: "Description",
      sort: "asc",
      width: 150 
    },
    {
      label: "Dealer Name",
      field: "DealerName",
      sort: "asc",
      width: 150 
    },
    {
      label: "Assign To",
      field: "AssignTo",
      sort: "asc",
      width: 150 
    }, 
    
    {
      label: "Status",
      field: "Status",
      sort: "asc",
      width: 150 
    },
    {
      label: "Remarks",
      field: "Remarks",
      sort: "asc",
      width: 270
    },
    {
      label: "Refered By",
      field: "ReferedBy",
      sort: "asc",
      width: 270
    },
    {
      label: "Created Date",
      field: "CreatedDate",
      sort: "desc",
      width: 150 
    },
  ],
  
  rows: rowData 
};
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
<Row>
<Col md={6}><Breadcrumbs title="Ticket History"  breadcrumbItems={this.state.breadcrumbItems} /></Col>

<Col md={6} className="text-right"><a href="/TicketSystem/complaints" className="bak-btn"> Back</a></Col>
</Row>
           
           
            

            <Row>
              <Col xs={12}>
                    <Card>
                      <CardBody>
                        
                        {/* <Row>
                          <Col sm={6} md={6} xl={6} className="mt-4">

                          </Col>
                          <Col sm={6} md={6} xl={6} className="mt-4 pull-right text-right"  >

                          </Col>
                        </Row> */}
                          {this.props.apiError && this.props.apiError ? <Alert color="danger" isOpen={this.state.visible}>{this.props.apiError}</Alert> : null }
                          {this.props.message && this.props.message!=='' ? <Alert color="success" isOpen={this.state.visible}>{this.props.message} </Alert> : null }
                        
                          <AvForm  onValidSubmit={(editData[0]) ? this.editRemark : this.addCustomerComplaint} className="form-horizontal" >
                           
                          <AvField className="form-control" name="TicketType" type="hidden" value={editData[0] && editData[0].TicketType} id="TicketType"/>
                          <AvField className="form-control" name="TicketReason" type="hidden" value={editData[0] && editData[0].TicketReason} id="TicketReason"/>
                          {(this.state.RoleId === 3) && 
                          <AvField className="form-control" name="AssignTo" type="hidden" value={editData[0] && editData[0].AssignTo} id="AssignTo"/>
                          }
                          <AvField className="form-control" name="Description" type="hidden" value={editData[0] && editData[0].Description} id="Description"/>
                                  
                              <FormGroup row>
                              {(this.state.RoleId === 3) && 
                                <Col sm={4} md={4} xl={4}>
                                <Label htmlFor="Status">Status <span className="mandatory">*</span></Label>
                                  <AvField className="form-control" name="Status"  type="select" value={editData[0] && editData[0].Status} id="Status" required>
                                  <option value="">Select</option>
                                  <option value="Awaiting">Awaiting</option>
                                    <option value="Closed">Closed</option>
                                  </AvField>
                                </Col>
                                }
                            
                            {(this.state.RoleId === 3) &&
                                  <Col sm={4} md={4} xl={4}>
                                    <Label htmlFor="Description">Remarks <span className="mandatory">*</span></Label>
                                    <AvField className="form-control" name="Remarks"  type="textarea"  value={editData[0] && editData[0].Remarks} id="Remarks" placeholder="Remarks" required />
                                  </Col>
                            }
                            {(this.state.RoleId === 2) &&
                                <Col sm={4} md={4} xl={4}>
                                <Label htmlFor="AssignTo">Customer Care Executive <span className="mandatory">*</span></Label>
                                  <AvField className="form-control" name="AssignTo"  type="select" value={editData[0] && editData[0].AssignTo} id="AssignTo" required>
                                    <option value="">Select Customer Care Executive</option>
                                    {OptionExecutive}
                                  </AvField>
                                  <AvField className="form-control" name="Status" type="hidden" value="Assigned" id="Status"/>
                                  
                                </Col>
                            }
                            {/* {(this.state.RoleId === 1) &&
                                <Col>
                                <Label htmlFor="AssignTo">Customer Care Executive</Label>
                                
                                  <AvField className="form-control" name="AssignTo"  type="select" value={editData[0] && editData[0].AssignTo} id="AssignTo">
                                    <option value="">Select Customer Care Executive</option>
                                    {OptionExecutive}
                                  </AvField>
                                </Col>
                            } */}
                              <Col sm={2} md={2} xl={2}>
                                <Label htmlFor="button"> </Label>
                                <AvField className="form-control btn btn-primary mt-2" name="submit" color="primary" type="submit" value="Submit" />
                                {/* <Button type="submit" color="primary">Save changes</Button> */}
                                </Col>
                              </FormGroup>
                            </AvForm>
                            <MDBDataTable responsive bordered data={data} />
                      </CardBody>
                    </Card>
              </Col>

            </Row>


          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { Complaints} = state.Complaints;
  const { Remarks, apiError, loading, message,modalError  } = state.Remarks; // .Complaints
  const { CustomerCareExe } = state.CustomerCareExecutive;
  return { Complaints,CustomerCareExe,Remarks, apiError, loading,message,modalError };
}

// export default withRouter(connect(mapStatetoProps, { querytypeList,querytypeListApiError,TicketsTypeList,TicketsTypeListApiError,TicketsReasonList,TicketsReasonListApiError})(Ticketing));
export default withRouter(connect(mapStatetoProps,
  {
    editCustomerComplaints,ComplaintsList,CustomerCareExecutiveList,RemarksList,editRemarks, RemarksListApiError

  })(Remarks));



