import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Media } from "reactstrap";
import SimpleBar from "simplebar-react";

//i18b
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import Moment from 'moment';
import { connect } from "react-redux";
import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago'
import en from 'javascript-time-ago/locale/en.json'

import { Notification_List,Notification_List_By_Id, Notification_List_Api_Faild } from  '../../../store/actions';

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      RoleId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).RoleId : null,
      UserId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).Id : null,
      CompanyId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyId : null,
      Notifications:[],     
    };
    this.toggle = this.toggle.bind(this);

    // TimeAgo.addDefaultLocale(en);
    TimeAgo.addLocale(en);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu
    }));
  }

  componentDidMount(){
    this.props.Notification_List_Api_Faild("");
    // this.props.addCompanyApiError("");
    this.props.Notification_List(this.state.UserId);
// console.log('this.props.Notification_List_By_Id(this.state.UserId);',this.props.Notification_List_By_Id(this.state.UserId));
}
componentWillReceiveProps(nextProps) {

    
    
    this.setState({Notifications:nextProps.Notifications});
    // console.log("Notifications Dropdown ", nextProps.Notifications) ;
    if(this.state.RoleId==2){
        // const ReadData = this.state.Notification.filter(({ AdminRead }) => AdminRead == 1);
        const UnReadData = nextProps.Notifications.filter(({ AdminRead }) => AdminRead == 0);
        // console.log("UnReadData Notification Dropdown if", UnReadData) ;
        this.setState({UnReadData:UnReadData});
      }else{
        // const ReadData = this.state.Notification.filter(({ ExecutiveRead }) => ExecutiveRead == 1);
        const UnReadData = nextProps.Notifications.filter(({ ExecutiveRead }) => ExecutiveRead == 0);
        // this.setState({ReadData:ReadData});
        // console.log("UnReadData Notification Dropdown else", UnReadData) ;
        this.setState({UnReadData:UnReadData});
      }
}


  render() {
    const {UnReadData} = this.state;
    return (
      <React.Fragment>
                        <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block">
                            <DropdownToggle tag="button" className="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown">
                                <i className="ri-notification-3-line"></i>
                                { UnReadData!='' &&
                                    <span className="noti-dot"></span>
                                }
                            </DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-lg p-0"
                                aria-labelledby="page-header-notifications-dropdown">
                                <div className="p-3">
                                    <Row className="align-items-center">
                                        <Col>
                                            <h6 className="m-0"> Notifications  </h6>
                                        </Col>
                                        <div className="col-auto">
                                            <Link to="Notification" className="small"> View All</Link>
                                        </div>
                                    </Row>
                                </div>
                                <SimpleBar style={{maxHeight: "230px"}}>
                                 
                                { UnReadData && UnReadData.map( (item,i)=>{
                                     return(
                                            <Link to={`Notification`}  key={i} className="text-reset notification-item" onClick={this.ReadNotificationById} data-param={i.ComplaintId}>
                                                <Media>
                                                    {/* <img src={avatar3} className="mr-3 rounded-circle avatar-xs" alt="user-pic"/> */}
                                                    <div className="avatar-xs mr-3">
                                                        <span className="avatar-title bg-success rounded-circle font-size-16">
                                                            <i className="ri-checkbox-circle-line"></i>
                                                        </span>
                                                    </div>
                                                    <Media body>
                                                        <h6 className="mt-0 mb-1"> {item.UserName}</h6>
                                                        <div className="font-size-12 text-muted">
                                                            <p className="mb-1">{item.TicketTypeName}</p>
                                                            <p className="mb-1">{item.Status}</p>
                                                            <p className="mb-0"><i className="mdi mdi-clock-outline"></i>                                                            
                                                                <ReactTimeAgo date={Date.parse(item.CreatedDate)} locale="en-US"/> 
                                                            </p> 
                                                        </div>
                                                    </Media>
                                                </Media>
                                            </Link>
                                        )
                                    })  }
                                   
                                </SimpleBar>

                                <div className="p-2 border-top">
                                    <Link to="Notification" className="btn btn-sm btn-link font-size-14 btn-block text-center">
                                        <i className="mdi mdi-arrow-right-circle mr-1"></i>View More
                                    </Link>
                                </div>
                            </DropdownMenu>
                        </Dropdown>
      </React.Fragment>
    );
  }
}
//export default withNamespaces()(NotificationDropdown);


const mapStatetoProps = state => {
    const { Notifications, apiError, loading, message } = state.Notification_List; // .Notifications
    return { Notifications, apiError, loading,message };
  }
  
  export default withRouter(connect(mapStatetoProps,{ Notification_List,Notification_List_By_Id, Notification_List_Api_Faild })(NotificationDropdown));