import { 
    CUSTOMER_CARE_EXECUTIVE_LIST, 
    CUSTOMER_CARE_EXECUTIVE_BY_ID,
    CUSTOMER_CARE_EXECUTIVE_LIST_SUCCESSFUL, 
    CUSTOMER_CARE_EXECUTIVE_LIST_API_FAILED,
    ADD_CUSTOMER_CARE_EXECUTIVE,
    ADD_CUSTOMER_CARE_EXECUTIVE_API_FAILED , 
    DELETE_CUSTOMER_CARE_EXECUTIVE,
    EDIT_CUSTOMER_CARE_EXECUTIVE,
    ADD_ACTIVITY,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_SUCCESSFULL,
    UPDATE_PASSWORD_FAILED,
} from './actionTypes';

const initialState = {
    apiError: null, loading: false, CustomerCareExe: [],updatePassword:[], isAlertOpen: false,Alert:false,Success:false, modal_static: false,passwordError:false,PasswordSuccess:false, message: null, modalError: null
}

const CustomerCareExecutive = (state = initialState, action) => {
    switch (action.type) {
        case CUSTOMER_CARE_EXECUTIVE_LIST:
            state = {
                ...state,
                loading: true,
                apiError: null,
                Alert:false,
                Success:false,
            }
            break;
        case CUSTOMER_CARE_EXECUTIVE_BY_ID:
            state = {
                ...state,
                loading: true,
                apiError: null
            }
            break;
        case CUSTOMER_CARE_EXECUTIVE_LIST_SUCCESSFUL:
            // console.log(action.payload);
            state = {
                ...state,
                CustomerCareExe: action.payload.CustomerCareExe,
                loading: false,
                message: action.payload.message.message,
                Success:true,
            }
            break;
        case CUSTOMER_CARE_EXECUTIVE_LIST_API_FAILED:
            // console.log(action.payload);
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
                CustomerCareExe: []
            }
            break;
        case ADD_CUSTOMER_CARE_EXECUTIVE:
            
            state = {
                ...state,
                loading: true,
                modalError: null,
                apiError:null
            }
            
            break;
        
        case ADD_ACTIVITY:
                state = {
                    ...state
                    // loading: true,
                    // modalError: null
                }
                break;    
         
        case ADD_CUSTOMER_CARE_EXECUTIVE_API_FAILED:
            
            state = {
                ...state,
                loading: false,
                modalError: action.payload,
                apiError:action.payload.error,
                Alert:true,
            }
            
            break;
        case DELETE_CUSTOMER_CARE_EXECUTIVE:
            state = {
                ...state,
                loading: true,
                apiError: null,
                message: null
            }
            break;

        case EDIT_CUSTOMER_CARE_EXECUTIVE:
            
            state = {
                ...state,
                loading: true,
                modalError: null,
                apiError:null,
                message: null,
                Alert:true,
            }
            
            break;
        case UPDATE_PASSWORD:
            
            state={
                ...state,
                loading:true,
                modalError:null,
                message:null,
            }
            break;
        case UPDATE_PASSWORD_SUCCESSFULL:
            
            state={
              ...state,
              loading:false,
              message:action.payload.message,
            //   isAlertOpen:true,
            PasswordSuccess:true,
              modalError:null,
            }
            break;
        case UPDATE_PASSWORD_FAILED:
            
            state={
                ...state,
                // apiError:action.payload,
                passwordError:true,
                modalError:action.payload.error,
                loading:false,
                
            }
            break;
        default:
            state = { ...state };
            break;
    }

    return state;
}

export default CustomerCareExecutive;