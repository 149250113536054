import React, { Component } from "react";
// import { Link } from "react-router-dom"; 
// import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, CardImg, CardTitle, Container,   } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "./datatables.scss";
// import img1 from "../../assets/images/small/img-1.jpg";
// import img2 from "../../assets/images/small/img-2.jpg";
import closed from "../../assets/images/closed.jpg";
import open from "../../assets/images/open.jpg";

// import BootstrapTable from 'react-bootstrap-table-next';
// import ToolkitProvider from 'react-bootstrap-table2-toolkit';
// import TicketingModel from '../Ticketing/TicketingModel';

class TrackComplaint extends Component {
    constructor(props) {
      super(props);
      this.state = {
        visible: false,
        modal_standard: false,
        modal_large: false,
        modal_xlarge: false,
        modal_small: false,
        modal_center: false,
        modal_scroll: false,
        modal_static: false,
        breadcrumbItems : [
          { title : "Track Complaint", link : "track-complaint" } 
      ],

      };

        this.tog_standard = this.tog_standard.bind(this);
        this.tog_xlarge = this.tog_xlarge.bind(this);
        this.tog_large = this.tog_large.bind(this);
        this.tog_small = this.tog_small.bind(this);
        this.tog_center = this.tog_center.bind(this);
        this.tog_scroll = this.tog_scroll.bind(this);
        this.tog_static = this.tog_static.bind(this);
        
    }

    tog_static() {
        this.setState(prevState => ({
          modal_static: !prevState.modal_static
        }));
        this.removeBodyCss();
      }
    
      tog_standard() {
        this.setState(prevState => ({
          modal_standard: !prevState.modal_standard
        }));
        this.removeBodyCss();
      }
      removeBodyCss() {
        document.body.classList.add("no_padding");
      }
    
      tog_large() {
        this.setState(prevState => ({
          modal_large: !prevState.modal_large
        }));
        this.removeBodyCss();
      }
      tog_xlarge() {
        this.setState(prevState => ({
          modal_xlarge: !prevState.modal_xlarge
        }));
        this.removeBodyCss();
      }
      tog_small() {
        this.setState(prevState => ({
          modal_small: !prevState.modal_small
        }));
        this.removeBodyCss();
      }
      tog_center() {
        this.setState(prevState => ({
          modal_center: !prevState.modal_center
        }));
        this.removeBodyCss();
      }
      tog_scroll() {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll
        }));
        this.removeBodyCss();
      }
      show() {
        this.setState({ visible: true });
      }
      hide() {
        this.setState({ visible: false });
      }

    TicketingModel(){

        // console.log("TicketingModel Call");
             
    }
     handleSelect(e){
        // console.log(e);
      }
    handleSubmit() {
	   
	/*	fetch(`${baseURL}addVcard`, {  
		  method: 'post',  
		  headers: {  
			'Accept': 'application/json',  
			'Content-Type': 'application/json'  
		  },  
		  body: JSON.stringify({  
		//   user_id: this.state.user_id,
		//   Username: this.state.Username,
		//   Color: this.state.TemplateColor,	  
		//   photo: this.state.photo, 
		//   Title: this.state.title,  
		//   Sub_title: this.state.subtitle,  
		//   Desc: this.state.description,  		 		 
		   
		  })  
		}).then((Response) => Response.json())  
		  .then((Result) => {   console.log("vData Response",Result)
			if (Result.success == true)  
			{
			  //
			   alert('VCard Successfully Created !!!')
			   window.location.reload(true);
			}        
			else{
				alert('Data Not Submit !!!')
			  
			}  
				
		  })
        */
		 
	}
  
    render() {
  
      // const data = {
      //   columns: [
      //      {
      //       label: 'No.',
      //       field: 'id',
      //       sort: "asc",
      //       width: 150
      //     },
      //     {
      //       label: "Complaint ID",
      //       field: "complaintId",
      //       sort: "asc",
      //       width: 150            
      //     }, 
      //     {
      //       label: "Name",
      //       field: "name",
      //       sort: "asc",
      //       width: 150
      //     },
      //     {
      //       label: "Date",
      //       field: "date",
      //       sort: "asc",
      //       width: 150 
      //     },
      //     {
      //       label: "Description",
      //       field: "description",
      //       sort: "asc",
      //       width: 150 
      //     },
      //     {
      //       label: "Status",
      //       field: "status",
      //       sort: "asc",
      //       width: 150 
      //     },  
      //   ],
      //   rows: [
      //     {
      //       id:"1",
      //       complaintId:"#NZ1572",
      //       name: "Data & Network",
      //       date: "21/07/2021", 
      //       description: "System Architect", 
      //       status: <Link to="#" onClick={this.tog_static} className="text-danger font-weight-bold">UnAssign</Link>,
             
      //     },
      //     {
      //       id:"2",
      //       complaintId:"#NZ1556",
      //       name: "Data & Network",
      //       date: "21/07/2021", 
      //       description: "System Architect", 
      //       status: <p  className="text-primery font-weight-bold">Assigned</p>,
      //     },
      //     {
      //       id:"3",
      //       complaintId:"#NZ1570",
      //       name: "Data & Network",
      //       date: "21/07/2021", 
      //       description: "System Architect", 
      //       status: <Link to="#" onClick={this.tog_static} className="text-danger font-weight-bold">UnAssign</Link>, 
      //     }, 
      //     {
      //       id:"4",
      //       complaintId:"#NZ1579",
      //       name: "Data & Network",
      //       date: "21/07/2021", 
      //       description: "System Architect", 
      //       status: <p  className="text-success font-weight-bold">Complete</p>, 
      //     }, 
      //     {
      //       id:"5",
      //       complaintId:"#NZ1572",
      //       name: "Data & Network",
      //       date: "21/07/2021", 
      //       description: "System Architect", 
      //       status: <p  className="text-warning font-weight-bold">Processing</p>, 
      //     }, 
      //   ]
      // };
  
       
   
      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
  
            <Breadcrumbs title="Track Complaint" breadcrumbItems={this.state.breadcrumbItems} />
  
              <Row>
                   {/* Ticket Assign */}
                     

                     <Col mg={6} xl={3}>
                                <Card>
                                    
                                    <CardBody>
                                        <CardTitle className="mt-0 text-center">OPEN</CardTitle>
                                        <CardImg top className="img-fluid" src={open} width="50" height="50" alt="Skote" />
                                         
                                    </CardBody>
                                     
                                </Card>

                            </Col>
                            <Col mg={6} xl={3}>
                                <Card>
                                <CardBody>
                                        <CardTitle className="mt-0 text-center">CLOSED</CardTitle>
                                        <CardImg top className="img-fluid" src={closed} width="50" height="50" alt="Skote" />
                                         
                                    </CardBody>
                                  
                                </Card>

                            </Col>
                   
 

              </Row>
  
              
            </Container>
          </div>
        </React.Fragment>
      );
    }
  }
export default TrackComplaint;



