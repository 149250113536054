import { PROFILES_LIST,PROFILES_LIST_SUCCESSFUL,
     PROFILES_LIST_API_FAILED,
     ADD_PROFILES_API_FAILED,
     EDIT_PROFILES } from './actionTypes';

const initialState = {
    apiError: null, loading: false, Profiles: [],  isAlertOpen: false, modal_static: false, message: null, modalError: null
}

const Profiles = (state = initialState, action) => {
    switch (action.type) {
        case PROFILES_LIST:
            
            state = {
                ...state,
                loading: true,
                // apiError: null
            }
            break;
        case PROFILES_LIST_SUCCESSFUL:
            
            state = {
                ...state,
                Profiles: action.payload.Profiles,
                loading: false,
                message: action.payload.message.message
            }
            break;
        case PROFILES_LIST_API_FAILED:
            
            state = {
                ...state,
                loading: false,
                Profiles: []
            }
            break;
            
            case ADD_PROFILES_API_FAILED:
                
            state = {
                ...state,
                loading: false,
                message: action.payload
            }
            break;
            case EDIT_PROFILES:
                
            state = {
                ...state,
                loading: false,
                apiError: null,
                message: null
            }
            break;
            
        default:
            state = { ...state };
            break;
    }

    return state;
}

export default Profiles;