import { CLOSED_COMPLAINTS_LIST,CLOSED_COMPLAINTS_LIST_SUCCESSFUL,
     CLOSED_COMPLAINTS_LIST_API_FAILED,DELETE_COMPLAINT,
     ADD_CUSTOMER_CLOSED_COMPLAINTS,ADD_CLOSED_COMPLAINTS_API_FAILED,
     EDIT_CUSTOMER_CLOSED_COMPLAINTS } from './actionTypes';

export const ClosedComplaintsList = (LoginId) => {
    
    return {
        type: CLOSED_COMPLAINTS_LIST,
        payload: {LoginId}
    }
}

export const ClosedComplaintsListSuccessful = (ClosedComplaints, message='') => {

    return {
        type: CLOSED_COMPLAINTS_LIST_SUCCESSFUL,
        payload: {ClosedComplaints, message},
    }
}

export const addCustomerClosedComplaints = (CustmerClosedComplaints) => {
    return {
        
        type: ADD_CUSTOMER_CLOSED_COMPLAINTS,
        payload: {CustmerClosedComplaints},
        isAlertOpen: false,
        modal_static: false
    }
}



export const editCustomerClosedComplaints = (CustmerClosedComplaints) => {
    return {
        
        type: EDIT_CUSTOMER_CLOSED_COMPLAINTS,
        payload: {CustmerClosedComplaints},
        isAlertOpen: false,
        modal_static: false
    }
}

export const ClosedComplaintsListApiError = (error) => {
    return {
        type: CLOSED_COMPLAINTS_LIST_API_FAILED,
        payload: error
    }
}
export const addClosedComplaintsApiError = (error) => {
    return {
        type: ADD_CLOSED_COMPLAINTS_API_FAILED,
        payload: error
    }
}
export const deleteComplaint = (ComplaintId,status,UpdatedBy) => {
    return {
        type: DELETE_COMPLAINT,
        payload: {ComplaintId,status,UpdatedBy},
        isAlertOpen: false,
        modal_static: false
    }
}

