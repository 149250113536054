export const OPEN_COMPLAINTS_LIST = 'OpenComplaints_list';
export const OPEN_COMPLAINTS_LIST_SUCCESSFUL = 'OpenComplaints_list_successfull';
export const OPEN_COMPLAINTS_LIST_API_FAILED = 'OpenComplaints_list_api_failed';
export const EDIT_OPEN_COMPLAINTS = 'edit_OpenComplaints';
export const EDIT_OPEN_COMPLAINTS_API_FAILED = 'edit_OpenComplaints_api_failed';
export const DELETE_COMPLAINT = 'delete_complaint';
export const ADD_OPEN_COMPLAINTS_API_FAILED = 'add_OpenComplaints_api_failed';
export const ADD_CUSTOMER_OPEN_COMPLAINTS = 'add_customer_OpenComplaints';
export const EDIT_CUSTOMER_OPEN_COMPLAINTS = 'edit_customer_OpenComplaints';


