import { 
    CUSTOMER_CARE_EXECUTIVE_LIST, 
    CUSTOMER_CARE_EXECUTIVE_BY_ID,
    CUSTOMER_CARE_EXECUTIVE_LIST_SUCCESSFUL, 
    ADD_CUSTOMER_CARE_EXECUTIVE_LIST_API_Error,
    CUSTOMER_CARE_EXECUTIVE_LIST_API_FAILED,
    ADD_CUSTOMER_CARE_EXECUTIVE,
    // ADD_COMPANY_SUCCESS,
    ADD_CUSTOMER_CARE_EXECUTIVE_API_FAILED,
    ADD_Activity_API_FAILED,
    DELETE_CUSTOMER_CARE_EXECUTIVE,
    EDIT_CUSTOMER_CARE_EXECUTIVE,
    ADD_ACTIVITY,
    Activity_list,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_SUCCESSFULL,
    UPDATE_PASSWORD_FAILED,
    // EDIT_COMPANY_SUCCESS 
} from './actionTypes';

export const CustomerCareExecutiveList = (LoginId) => {
    return {
        type: CUSTOMER_CARE_EXECUTIVE_LIST,
        payload: { LoginId }
    }
}

export const activitylist_successfull = (LoginId) => {
    return {
        type: Activity_list,
        payload: { LoginId }
    }
}

export const CustomerCareExecutiveByID = (Id) => {
    return {
        type: CUSTOMER_CARE_EXECUTIVE_BY_ID,
        payload: {Id},
    }
}

export const CustomerCareExecutiveList_successfull = (CustomerCareExe, message='') => {
    return {
        type: CUSTOMER_CARE_EXECUTIVE_LIST_SUCCESSFUL,
        payload: {CustomerCareExe, message},
    }
}

export const CustomerCareExecutiveListApiError = (error) => {
    return {
        type: CUSTOMER_CARE_EXECUTIVE_LIST_API_FAILED,
        payload: error
    }
}

export const add_customer_care_executive_ApiError = (error) => {
    
    return {
        type: ADD_CUSTOMER_CARE_EXECUTIVE_LIST_API_Error,
        payload: error
    }
}

export const CustomerCareExecutiveList_API_FAILED = (error) => {
    return {
        type: CustomerCareExecutiveList_API_FAILED,
        payload: error
    }
}
 
export const add_customer_care_executive = (customer_care_executive) => {
    return {
        type: ADD_CUSTOMER_CARE_EXECUTIVE,
        payload: {customer_care_executive},
        isAlertOpen: false,
        modal_static: false
    }
}

export const add_activity = (activity) => {
    // console.log("activityeeeeessss",activity);
    return {
        type: ADD_ACTIVITY,
        payload: {activity}
        // isAlertOpen: false,
        // modal_static: false
    }
}

export const edit_customer_care_executive = (customer_care_executive) => {
    
    return {
        type: EDIT_CUSTOMER_CARE_EXECUTIVE,
        payload: {customer_care_executive},
        isAlertOpen: false,
        modal_center: false
    }
    
}

export const update_user_password=(update_password)=>{
    
    return{
        type:UPDATE_PASSWORD,
        payload:{update_password},
        isAlertOpen:false,
    }
}

export const update_password_api_successfull=(message='')=>{
    
    return{
        type:UPDATE_PASSWORD_SUCCESSFULL,
        payload:{message}
    }
}

export const update_password_failed=(error)=>{
    
return{
    type:UPDATE_PASSWORD_FAILED,
    payload:{error}
}
}

export const delete_customer_care_executive = (Id, UpdatedBy,CompanyCode, status) => {
    return {
        type: DELETE_CUSTOMER_CARE_EXECUTIVE,
        payload: {Id, UpdatedBy,CompanyCode, status},
        isAlertOpen: false,
        modal_static: false
    }
}


// export const addCompanySuccessful = (company) => {
//     return {
//         type: ADD_COMPANY_SUCCESS,
//         payload: company,
//         isAlertOpen: true,
//         modal_static: true
//     }
// }

export const add_customer_care_executive_api_faild = (error) => {
    return {
        type: ADD_CUSTOMER_CARE_EXECUTIVE_API_FAILED,
        payload: error,
        isAlertOpen: false,
        modal_static: true
    }
}



// export const editCompanySuccess = (message) => {
//     return {
//         type: EDIT_COMPANY_SUCCESS,
//         payload: {message},
//         isAlertOpen: true,
//         modal_static: false
//     }
// }