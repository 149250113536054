import {
    EDIT_PASSWORD,
    EDIT_PASSWORD_API_SUCCESSFULL,
    EDIT_PASSWORD_API_FAILED
} from './actionTypes';

const initialState={
     loading:false, editUserPassword:[], isAlertOpen:false, message:null, modalError:null,isAlertError:false
}

const ProfileMenu=(state=initialState,action)=>{
switch(action.type){
    case EDIT_PASSWORD:
        
        state={
            ...state,
            loading:true,
            modalError:null,
            message:null,
            isAlertOpen:false,
        }
        break;
    case EDIT_PASSWORD_API_SUCCESSFULL:
        
        state={
            ...state,
            loading:false,
            message:action.payload,
            modalError:null,
            isAlertOpen:true,
        }
        break;
    case EDIT_PASSWORD_API_FAILED:
        
        state={
            ...state,
            loading:false,
            modalError:action.payload.error,
            isAlertError:true,
        }
        
        break;
        default:
            state={...state};
            break;
}
return state;
}

export default ProfileMenu;