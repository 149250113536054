export const CUSTOMER_CARE_EXECUTIVE_LIST = 'CustomerCareExecutiveList';
export const CUSTOMER_CARE_EXECUTIVE_BY_ID = 'CustomerCareExecutiveByID';
export const CUSTOMER_CARE_EXECUTIVE_LIST_SUCCESSFUL = 'CustomerCareExecutiveList_successfull';

export const CUSTOMER_CARE_EXECUTIVE_LIST_API_FAILED = 'CustomerCareExecutiveList_API_FAILED';
export const ADD_CUSTOMER_CARE_EXECUTIVE_LIST_API_Error = 'add_customer_care_executive_Api_Error'; 
export const ADD_CUSTOMER_CARE_EXECUTIVE = 'add_customer_care_executive';
// export const ADD_COMPANY_SUCCESS = 'add_company_successfull';

export const ADD_CUSTOMER_CARE_EXECUTIVE_API_FAILED = 'add_customer_care_executive_api_faild';

export const DELETE_CUSTOMER_CARE_EXECUTIVE = 'delete_customer_care_executive';

export const EDIT_CUSTOMER_CARE_EXECUTIVE = 'edit_customer_care_executive';

export const ADD_ACTIVITY = 'add_activity';

export const Activity_list = 'activity_list';

export const ADD_Activity_API_FAILED = 'add_activity_api_error';

export const UPDATE_PASSWORD="update_password";
export const UPDATE_PASSWORD_SUCCESSFULL="update_password_successfull";
export const UPDATE_PASSWORD_FAILED="update_password_faileed";
// export const EDIT_COMPANY_SUCCESS = 'edit_company_successfull';
