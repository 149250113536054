import { 
    COUNT_LIST, 
    COUNT_LIST_BY_ID,
    COUNT_LIST_SUCCESSFUL, 
    COUNT_LIST_API_FAILED,  
    COUNT_LIST_API_Error,
} from './actionTypes';
 
export const Count_List_By_Id = (Id) => {
    // console.log('count Action call ', Id)
    return {
        type: COUNT_LIST_BY_ID,
        payload: {Id},
    }
}

export const Count_List_successfull = (Count, message='') => {
    return {
        type: COUNT_LIST_SUCCESSFUL,
        payload: {Count, message},
    }
}

export const Count_List_API_FAILED = (error) => {
    return {
        type: COUNT_LIST_API_FAILED,
        payload: error
    }
}

export const Count_List_Api_Error = (error) => {
    return {
        type: COUNT_LIST_API_Error,
        payload: error
    }
}
 