import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components
import MiniWidgets from "./MiniWidgets";
import LatestTransactions from "./LatestTransactions";

import { withRouter,Redirect } from "react-router-dom";
import { connect } from "react-redux";
//import { Count_List_By_Id, Count_List_API_FAILED } from  '../../store/actions';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            RoleId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).RoleId : null,
            UserId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).Id : null,
            CompanyId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyId : null,
      
            breadcrumbItems : [
                { title : "", link : "dashboard" },
            ],
           
        }
    }

    componentDidMount(){
       // this.props.Count_List_API_FAILED("");
        // this.props.addCompanyApiError("");
       // this.props.Count_List_By_Id(this.state.UserId);
 
    }
    componentWillReceiveProps(nextProps) {
   
    }

    render() {
        if(this.state.RoleId==1){
            return <Redirect to='/maindashboard'/>
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col xl={12}>
                                <Row>
                                    <MiniWidgets />
                                </Row>
                                
                                 
                            </Col>
 
                            <Col xl={12}>
                        {/* {(this.state.RoleId === 1 || this.state.RoleId === 2) &&
                                    <LatestTransactions/>
                         }
                         {(this.state.RoleId === 3) &&
                                    <LatestTransactions/>
                         }
                         {(this.state.RoleId === 4) &&
                                    <LatestTransactions/>
                         } */}
                         <LatestTransactions/>
                            </Col>
                        </Row> 
                          
                    </Container> 
                </div>
            </React.Fragment>
        );
    }
}

export default Dashboard;

// const mapStatetoProps = state => {
//     const { Count, apiError, loading, message } = state.Count_List; // .Complaints
//     return { Count, apiError, loading,message };
//   }
  
//   export default withRouter(connect(mapStatetoProps,
//          { Count_List_By_Id, Count_List_API_FAILED })(Dashboard));
   
   
   
  