import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { REMARKS_LIST,EDIT_REMARKS } from './actionTypes';
import { RemarksListSuccessful, RemarksListApiError,addRemarksApiError } from './actions';

// AUTH related methods
import { getList,editData } from '../../helpers/fackBackend_Helper';

function* RemarksList( { payload : { CompanyId,ComplaintId } } ) {
    try {
        const response = yield call(getList, `/complaint/remarks/${CompanyId}/${ComplaintId}`);
        if (response) {
            // console.log('Remarks from list saga = ', response);
            yield put(RemarksListSuccessful(response.data.data));
        }
    } catch (error) {
        yield put(RemarksListApiError(error));
    }
}

export function* watchGetRemarks() {
    yield takeEvery(REMARKS_LIST, RemarksList)
}

function* editRemarks({ payload: { Remarks } }) {
    try {
        const responseedit = yield call(editData, '/customer/complaint', Remarks);
        // console.log('response from customer/complaint = ', responseedit);
        // console.log('Remarks customer/complaint  = ', Remarks);
       
        if (responseedit) {
            const response = yield call(getList, `/complaint/remarks/${Remarks.CompanyCode}/${Remarks.ComplaintId}`);
            if (response) {
                // console.log('response remarks',response.data.data)
                // console.log('response remarks ',responseedit)
                yield put(RemarksListSuccessful(response.data.data,responseedit));                
            }
        }
    } catch (error) {
        yield put(addRemarksApiError(error));
    }
}

export function* watcheditRemarks() {
    yield takeEvery(EDIT_REMARKS, editRemarks);
}



function* RemarksSaga() {
    yield all([fork(watchGetRemarks)]);
    yield all([fork(watcheditRemarks)]);
}

export default RemarksSaga;