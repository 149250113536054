import { 
    TICKETSTYPE_LIST,TICKETSTYPE_LIST_SUCCESSFUL, TICKETSTYPE_LIST_API_FAILED,
    ADD_TICKETTYPE, ADD_TICKETTYPE_API_FAILED,EDIT_TICKETTYPE,DELETE_TICKETTYPE,LOG_TICKETTYPE, ADD_ACTIVITY,Activity_list, 
} from './actionTypes';

export const TicketsTypeList = (LoginId) => {
    return {
        type: TICKETSTYPE_LIST,
        payload: {LoginId}
    }
}

export const TicketsTypeListSuccessful = (TicketsType, CustomerExecutive, message='') => {

    return {
        type: TICKETSTYPE_LIST_SUCCESSFUL,
        payload: {TicketsType, CustomerExecutive, message},
    }
}

export const activitylist_successfull = (TicketsType,CustomerExecutive,message='') => {
    return {
        type: Activity_list,
        payload: { TicketsType, CustomerExecutive, message }
    }
}


export const TicketsTypeListApiError = (error) => {
    return {
        type: TICKETSTYPE_LIST_API_FAILED,
        payload: error
    }
}

export const AddTicketType = (TicketsType) => {
    return {
        
        type: ADD_TICKETTYPE,
        payload: {TicketsType},
        isAlertOpen: false,
        modal_static: false
    }
}


export const add_activity = (activity) => {
    // console.log("activityeeeeessss",activity);
    return {
        type: ADD_ACTIVITY,
        payload: {activity}
        // isAlertOpen: false,
        // modal_static: false
    }
}

export const LogTicketType = (TicketsType) => {
    return {
        
        type: LOG_TICKETTYPE,
        payload: {TicketsType},
        isAlertOpen: false,
        modal_static: false
    }
}

export const AddTicketTypeApiError = (error) => {
    return {
        type: ADD_TICKETTYPE_API_FAILED,
        payload: error
    }
}


export const editTicketType = (TicketsType) => {
    return {
        type: EDIT_TICKETTYPE,
        payload: {TicketsType},
        isAlertOpen: false,
        modal_static: false
    }
}

export const deleteTicketType = (Id,status,CompanyCode,UpdatedBy) => {
    return {
        type: DELETE_TICKETTYPE,
        payload: {Id,status,CompanyCode,UpdatedBy},
        isAlertOpen: false,
        modal_static: false
    }
}
