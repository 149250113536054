import { 
    READNOTIFICATION,
    NOTIFICATION_LIST, 
    NOTIFICATION_LIST_BY_ID,
    NOTIFICATION_LIST_SUCCESSFUL, 
    NOTIFICATION_LIST_API_FAILED,  
    NOTIFICATION_LIST_API_Error,
} from './actionTypes';

export const Notification_List = (LoginId) => {
    return {
        type: NOTIFICATION_LIST,
        payload: {LoginId}
    }
}
export const Notification_List_By_Id = (Id) => {
    return {
        type: NOTIFICATION_LIST_BY_ID,
        payload: {Id},
    }
}

export const Notification_List_successfull = (Notifications, message='') => {
    return {
        type: NOTIFICATION_LIST_SUCCESSFUL,
        payload: {Notifications, message},
    }
}

export const Notification_List_Api_Faild = (error) => {
    return {
        type: NOTIFICATION_LIST_API_FAILED,
        payload: error
    }
}

export const Notification_List_Api_Error = (error) => {
    return {
        type: NOTIFICATION_LIST_API_Error,
        payload: error
    }
}
export const ReadNotifications = (Notifications) => {
    // console.log('Notifications action',Notifications)
    return {
        type: READNOTIFICATION,
        payload: {Notifications}
    }
}
 