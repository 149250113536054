export const TICKETSTYPE_LIST = 'TicketsType_list';
export const TICKETSTYPE_LIST_SUCCESSFUL = 'TicketsType_list_successfull';
export const TICKETSTYPE_LIST_API_FAILED = 'TicketsType_list_api_failed';
export const ADD_TICKETTYPE = 'AddTicketType';
export const EDIT_TICKETTYPE = 'edit_TicketType_successfull';
export const ADD_TICKETTYPE_API_FAILED = 'Add_TicketType_api_failed';
export const DELETE_TICKETTYPE = 'Delete_TicketType';
export const LOG_TICKETTYPE = 'Log_TicketType';
export const ADD_ACTIVITY = 'add_activity';
export const Activity_list = 'activity_list';
export const ADD_Activity_API_FAILED = 'add_activity_api_error';
