import React, { Component } from "react";
//import React,{useState,useEffect} from 'react'

import Moment from 'moment';
// import Axios from 'axios';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// import {useHistory} from 'react-router-dom'
import { AvForm,  AvField } from "availity-reactstrap-validation";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { MDBDataTable } from "mdbreact";
// import Multiselect from 'multiselect-react-dropdown';
// import { MultiSelect } from "react-multi-select-component";
// import Select from 'react-select';
// import AvForm from 'availity-reactstrap-validation';

import {
  Row, Col, Card, CardBody, 
   Container
} from "reactstrap";

import Breadcrumbs from '../../components/Common/Breadcrumb';
import "./datatables.scss";

// import BootstrapTable from 'react-bootstrap-table-next';
// import ToolkitProvider from 'react-bootstrap-table2-toolkit';
// import TicketingModel from '../../pages/Ticketing/TicketingModel';
import { ReadNotifications,Notification_List,Notification_List_By_Id, Notification_List_Api_Faild } from  '../../store/actions';
// import { TicketMultiSelect } from  'TicketMultiSelect'; 

// const baseURL = process.env.REACT_APP_API_BASEURL;
// const token = JSON.parse(localStorage.getItem('token'));

class Noticfication extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();

    this.state = {
      visible: false,
      isAlertOpen: false,
      breadcrumbItems: [
        { title: "", link: "Notification" },
      ],
      editData: null,
      editReasonData: null,
      IsActive:'',
      Notifications:[],
      loginUser: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).Id : '',
      CompanyId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyId : '',
      RoleId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).RoleId : '',
      CompanyCode: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyCode : '',

    };
  }

  componentDidMount() {
    this.props.Notification_List_Api_Faild("");
    // this.props.addCompanyApiError("");
    this.props.Notification_List(this.state.loginUser);
  }

  

  componentWillReceiveProps(nextProps) {
    this.setState({Notifications:nextProps.Notifications});
    if(this.state.RoleId==2){
      const ReadData = nextProps.Notifications.filter(({ AdminRead }) => AdminRead == 1);
      const UnReadData = nextProps.Notifications.filter(({ AdminRead }) => AdminRead == 0);
      this.setState({ReadData:ReadData});
      this.setState({UnReadData:UnReadData});
    }else{
      const ReadData = nextProps.Notifications.filter(({ ExecutiveRead }) => ExecutiveRead == 1);
      const UnReadData = nextProps.Notifications.filter(({ ExecutiveRead }) => ExecutiveRead == 0);
      this.setState({ReadData:ReadData});
      this.setState({UnReadData:UnReadData});
    }
  }
  ReadNotificationById = (event) => {
    const TTId = event.target.dataset.param;
    const editData = this.state.UnReadData.filter(({ Id }) => Id == TTId);
    this.setState({ editData: editData[0]});
    // console.log('editData ',editData[0]);
    if(this.state.RoleId==2){
      let UpdateData =  {
        Id: TTId,
        AdminRead:1,
        ExecutiveRead:editData[0].ExecutiveRead,
        UserId:this.state.loginUser
      }
      // console.log('UpdateData',UpdateData);
      this.props.ReadNotifications(UpdateData);
    }else{
      let UpdateData =  {
        Id: TTId,
        ExecutiveRead:1,
        AdminRead:editData[0].AdminRead,
        UserId:this.state.loginUser
      }
      // console.log('UpdateData',UpdateData);
      this.props.ReadNotifications(UpdateData);
    }
    
    
    
  }
  
  render() {
    const {ReadData} = this.state;
    const {UnReadData} = this.state;
    
    const UnReadNotification = [];
    UnReadData && UnReadData.map( (UnReadList,i)=>{

      let StatusData="";
      if(UnReadList.Status=='Assigned'){
        StatusData = <span className="text-warning">Assigned</span>
      }else if(UnReadList.Status=='ReOpen'){
        StatusData = <span className="text-danger">ReOpen</span>
      }else if(UnReadList.Status=='Awaiting'){
        StatusData = <span className="text-primary">Awaiting</span>
      }else if(UnReadList.Status=='Closed'){
        StatusData = <span className="text-success">Closed</span>
      }else{
        StatusData = <span className="text-primary">UnAssigned</span>
      }
      const limit = 2500;
      UnReadNotification.push({
        SN:i+1,
        TicketId: UnReadList.TicketId,
        TicketTypeName: UnReadList.TicketTypeName,
        TicketReasonName:UnReadList.TicketReasonName,
        Description: <a title={UnReadList.Description}>{UnReadList.Description.substring(0,limit)}...</a>,
        UserName: UnReadList.UserName,
        Status: StatusData,
        CreatedDate: Moment(UnReadList.CreatedDate).format('MM-DD-YYYY HH:mm:ss'),
        Action: <div>
                  {/* {`${text.substring(0, MAX_LENGTH)}...`}
                  <div className="badge badge-soft-success font-size-12">
                      <Link to="#" className="text-primary" id={"edit"+OpenList.ComplaintId}><i className="fa fa-edit" onClick={this.editComplaintById} data-param={OpenList.ComplaintId}></i></Link>
                  </div> */}
                  <div className="badge badge-soft-success font-size-12">
                      <Link to="#" className="text-primary" ><i className="fab fa-readme" onClick={this.ReadNotificationById} data-param={UnReadList.Id}></i></Link>
                  </div>
                </div>
      })
    })
    const ReadNotification = [];
    ReadData && ReadData.map( (ReadList,i)=>{

      let StatusData="";
      if(ReadList.Status=='Assigned'){
        StatusData = <span className="text-warning">Assigned</span>
      }else if(ReadList.Status=='ReOpen'){
        StatusData = <span className="text-danger">ReOpen</span>
      }else if(ReadList.Status=='Awaiting'){
        StatusData = <span className="text-primary">Awaiting</span>
      }else if(ReadList.Status=='Closed'){
        StatusData = <span className="text-success">Closed</span>
      }else{
        StatusData = <span className="text-primary">UnAssigned</span>
      }
      const limit = 2500;
      ReadNotification.push({
        SN:i+1,
        TicketId: ReadList.TicketId,
        TicketTypeName: ReadList.TicketTypeName,
        TicketReasonName:ReadList.TicketReasonName,
        Description: <a title={ReadList.Description}>{ReadList.Description.substring(0,limit)}...</a>,
        UserName: ReadList.UserName,
        Status: StatusData,
        CreatedDate: Moment(ReadList.CreatedDate).format('MM-DD-YYYY HH:mm:ss'),
        ReadDate: Moment(ReadList.UpdatedDate).format('MM-DD-YYYY HH:mm:ss')
      })
    })
    const data1 = {
      columns: [
        {
          label: "Sl",
          field: "SN",
          sort: "asc",
          width: 150
        },
        {
          label: "Ticket Id",
          field: "TicketId",
          sort: "asc",
          width: 150
        }, 
        {
          label: "Ticket Type",
          field: "TicketTypeName",
          sort: "asc",
          width: 150
        },
        {
          label: "Ticket Reason",
          field: "TicketReasonName",
          sort: "asc",
          width: 150 
        },
        {
          label: "Description",
          field: "Description",
          sort: "asc",
          width: 150 
        },
        {
          label: "Status",
          field: "Status",
          sort: "asc",
          width: 150 
        },
        {
          label: "Created Date",
          field: "CreatedDate",
          sort: "asc",
          width: 150 
        },
        {
          label: "ReadDate",
          field: "ReadDate",
          sort: "asc",
          width: 150 
        },
      ],
      rows: ReadNotification 
    };
    const data = {
      columns: [
        {
          label: "Sl",
          field: "SN",
          sort: "asc",
          width: 150
        },
        {
          label: "Ticket Id",
          field: "TicketId",
          sort: "asc",
          width: 150
        }, 
        {
          label: "Ticket Type",
          field: "TicketTypeName",
          sort: "asc",
          width: 150
        },
        {
          label: "Ticket Reason",
          field: "TicketReasonName",
          sort: "asc",
          width: 150 
        },
        {
          label: "Description",
          field: "Description",
          sort: "asc",
          width: 150 
        },
        {
          label: "Status",
          field: "Status",
          sort: "asc",
          width: 150 
        },
        {
          label: "Created Date",
          field: "CreatedDate",
          sort: "asc",
          width: 150 
        },  
        {
          label: "Action",
          field: "Action",
          sort: "asc",
          width: 270
        },
      ],
      rows: UnReadNotification 
    };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>

            <Breadcrumbs title="Notification" breadcrumbItems={this.state.breadcrumbItems} />

            <Row>
              <Col xs={12}>
                <Tabs >
                  <TabList>
                    <Tab onClick={this.Unread}>Unread</Tab>
                    <Tab onClick={this.Read}>Read</Tab>
                  </TabList>
                  <TabPanel>
                    <Card>
                      <CardBody>
                        <MDBDataTable responsive bordered data={data} />
                      </CardBody>
                    </Card>
                  </TabPanel>
                  <TabPanel>
                    <Card>
                      <CardBody>
                        <MDBDataTable responsive bordered data={data1} />
                      </CardBody>
                    </Card>
                  </TabPanel>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapStatetoProps = state => {
  const { Notifications, apiError, loading, message } = state.Notification_List; // .Complaints
  return { Notifications, apiError, loading,message };
}

export default withRouter(connect(mapStatetoProps,{ ReadNotifications,Notification_List,Notification_List_By_Id, Notification_List_Api_Faild })(Noticfication));



