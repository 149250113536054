import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { 
    COMPANYLIST, 
    COMPANYLISTBYID,
    COMPANYLISTSUCCESSFUL, 
    COMPANYLISTAPIERROR,
    ADDCOMPANY,
    ADDCOMPANYAPIERROR,
    EDITCOMPANY,
    DELETECOMPANYLIST,
    COMPANYLOGOAPI,
} from './actionTypes';
import { 
    CompanyList, 
    CompanyListById,
    CompanyListsuccessfull, 
    CompanyListApiError,
    addCompanyApiError,
 } from './actions';

// AUTH related methods
import { getList, getDataById, addData, deleteData, editData } from '../../helpers/fackBackend_Helper';

function* getCompanyList() {
    try {
        const response = yield call(getList, `/companylist`);
        // console.log("companylist response",response.data.data)
        if (response) {
            yield put(CompanyListsuccessfull(response.data.data));
        }
    } catch (error) {
        yield put(CompanyListApiError(error));
    }
}

export function* watchGetCompanyList() {
    yield takeEvery(COMPANYLIST, getCompanyList)
}

function* CompanyLogoApi({ payload: { Company } }) {
    debugger
    try {
        const responseCreate = yield call(addData, '/companylogo', Company);
        // if (responseCreate) {
        //     console.log("add payload Executive",Company)
        //     const response = yield call(getList, `/companylist`);
        //     console.log("add response Executive",response.data.data)
        //     if (response) {
        //         console.log('response saga = ',response.data.data);
        //         console.log('responseCreate',responseCreate);
        //         yield put(CompanyListsuccessfull(response.data.data,responseCreate));
        //     }
        //     else{

        //         yield put(addCompanyApiError(response.message));
        //     }
        // }
    } catch (error) {
        yield put(addCompanyApiError(error));
    }
}


export function* watchCompanyLogoApi() {
    yield takeEvery(COMPANYLOGOAPI, CompanyLogoApi);
}

function* addCompany({ payload: { Company } }) {
    debugger
    try {
        const responseCreate = yield call(addData, '/company', Company);
        if (responseCreate) {
            // console.log("add payload Executive",Company)
            const response = yield call(getList, `/companylist`);
            // console.log("add response Executive",response.data.data)
            if (response) {
                debugger
                // console.log('response saga = ',response.data.data);
                // console.log('responseCreate',responseCreate);
                yield put(CompanyListsuccessfull(response.data.data,responseCreate));
            }
            else{

                yield put(addCompanyApiError(response.message));
            }
        }
    } catch (error) {
        yield put(addCompanyApiError(error));
    }
}

export function* watchaddCompany() {
    yield takeEvery(ADDCOMPANY, addCompany);
}

function* deleteCompanyList({ payload: { Id, userId,CompanyCode, status } }) {
    try {
        const response = yield call(deleteData, '/Company', { Id, userId, status});
        // console.log("deleteData",response);
        if (response) {
            const responseCom = yield call(getList, `/companylist`);
        //    console.log("deleted data",responseCom.data)
            if (responseCom) {
                yield put(CompanyListsuccessfull(responseCom.data.data, response));
            }
        }
    } catch (error) {
        yield put(addCompanyApiError(error));
    }
}

export function* watchdeleteCompanyList() {
    yield takeEvery(DELETECOMPANYLIST, deleteCompanyList);
}

function* editCompany({ payload: { Company } }) {
    debugger
    try {
        const responseedit = yield call(editData, '/Company', Company);
        // console.log('responseedit Company',responseedit);
        // console.log("payload Company",Company)
        if (responseedit) {
            debugger
            // console.log("payload Edit",Company)
            const response = yield call(getList, `/companylist`);
            // console.log("response Edit",response.data.data)
            if (response) {
                // console.log('response saga = ',response.data.data);
                // console.log('responseCreate',responseedit);
                yield put(CompanyListsuccessfull(response.data.data,responseedit));
            }
        }
    } catch (error) {
        yield put(addCompanyApiError(error));
    }
}

export function* watcheditCompany() {
    yield takeEvery(EDITCOMPANY, editCompany);
}


function* MainDashboardSaga() {
    yield all([fork(watchGetCompanyList)]);
    yield all([fork(watchCompanyLogoApi)]);    
    yield all([fork(watchaddCompany)]);
    yield all([fork(watchdeleteCompanyList)]);
    yield all([fork(watcheditCompany)]);
}

export default MainDashboardSaga;