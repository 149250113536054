import React, { Component } from "react";
import { Link } from "react-router-dom"; 
import { MDBDataTable } from "mdbreact";
import Moment from 'moment';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Row, Col, Card, CardBody, Container, FormGroup,
     Label, Alert } from "reactstrap";
import { AvForm,  AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "./datatables.scss";

// import BootstrapTable from 'react-bootstrap-table-next';
// import ToolkitProvider from 'react-bootstrap-table2-toolkit';
// import TicketingModel from '../Ticketing/TicketingModel';
import { AwaitingComplaintsList,ClosedComplaintsList,OpenComplaintsList,CustomerCareExecutiveList,ComplaintsList,editCustomerComplaints,ComplaintsListApiError,
  deleteComplaint } from  '../../store/actions';
// const baseURL = process.env.REACT_APP_API_BASEURL;
// const token = JSON.parse(localStorage.getItem('token'));
class Complaints extends Component {
    constructor(props) {
      super(props);
      this.state = {
        visible: false,
        isAlertOpen: false,
        modal_scroll:false,
        modal_static:false,
        modalError: false,
        visible : false,
        breadcrumbItems : [
          { title : "", link : "complaints" }
        ],
        Complaints: [],
        CustomerCareExe:[],
        CustomerCareExeList: [],
        CustomerEId:null,
        Status:null,
        RoleId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).RoleId : null,
        loginUser: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).Id : '',
        CompanyCode: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyCode : '',
        ErrorMessage:'',
        HideAlert:false,
        // SearchValue:[],

      };

      this.tog_scroll = this.tog_scroll.bind(this);
      this.tog_static = this.tog_static.bind(this);
      this.SearchData = this.SearchData.bind(this);
      this.Checkvalid=this.Checkvalid.bind(this);
    }
  
    componentDidMount(){
      this.props.ComplaintsListApiError("");
      this.props.ComplaintsList(this.state.loginUser);
      this.props.OpenComplaintsList(this.state.loginUser);
      this.props.ClosedComplaintsList(this.state.loginUser);
      this.props.AwaitingComplaintsList(this.state.loginUser);
      this.props.CustomerCareExecutiveList(this.state.CompanyCode);
    }
    componentWillReceiveProps(nextProps) {

      this.setState({Complaints: nextProps.Complaints});
      // console.log('Complaints',Complaints);
      this.setState({ OpenComplaints: nextProps.OpenComplaints });
      this.setState({ ClosedComplaints: nextProps.ClosedComplaints });
      this.setState({ AwaitingComplaints: nextProps.AwaitingComplaints }); 
      this.setState({CustomerCareExeList: nextProps.CustomerCareExe});
      
    }


  Checkvalid=(event, values)=>{

      if(this.state.CustomerEId===null && this.state.Status===null){
         
        alert("Please select atleast one option !")
         
      }

  }

    SearchData = (event, values) => {
      // let SearchDataValue = {
      //   id: this.state.loginUser,
      //   // AssignTo: values.CustomerEId,
      //   Status: values.Status
      // };
      const UserId= values.CustomerEId;
      const Status= values.Status;
      // console.log('parameter ',UserId);
      // console.log('Status ',Status);
      if(UserId!='' && Status==''){
        const SearchUserId = this.state.Complaints.filter(({ AssignTo }) => AssignTo == UserId);
        // console.log('SearchUserId F',SearchUserId);
        this.setState({ComplaintsData:SearchUserId});
        if(Status!=''){
          const SearchStatus = this.state.ComplaintsData.filter(obj => obj.Status == Status);
          // console.log('SearchStatus F',SearchStatus);
          this.setState({ComplaintsData:SearchStatus});
        }
        
      }else if(UserId!='' && Status!=''){
        const SearchUserId = this.state.Complaints.filter(({ AssignTo }) => AssignTo == UserId);
        this.setState({ComplaintsData:SearchUserId});
        // console.log('SearchUserId S',SearchUserId);
        if(Status!=''){
          const SearchStatus = this.state.ComplaintsData.filter(obj => obj.Status == Status);
          // console.log('SearchStatus S',SearchStatus);
          this.setState({ComplaintsData:SearchStatus});
        }
      }else if(Status!='' && UserId==''){
        const SearchStatus = this.state.Complaints.filter(obj => obj.Status == Status);
        // console.log('SearchStatus T',SearchStatus);
        this.setState({ComplaintsData:SearchStatus});
        if(UserId!=''){
          const SearchUserId = this.state.ComplaintsData.filter(({ AssignTo }) => AssignTo == UserId);
          // console.log('SearchUserId T',SearchUserId);
          this.setState({ComplaintsData:SearchUserId});
        }
      }


    }

    tog_static() {
      this.setState(prevState => ({
        modal_static: !prevState.modal_static
      }));
      this.removeBodyCss();
    }
    tog_scroll() {
      this.setState(prevState => ({
        modal_scroll: !prevState.modal_scroll
        // componentDidMount()
      }));
      this.removeBodyCss();
    }
    removeBodyCss() {
      document.body.classList.add("no_padding");
    }
    show() {
      this.setState({ visible: true });
    }
    hide() {
      this.setState({ visible: false });
    }
  
    render() {
      const { CustomerCareExeList } = this.state;
      // console.log('CustomerCareExe',CustomerCareExeList);
      const OptionExecutive = CustomerCareExeList.map(person =>
        <option key={person.Id} value={person.Id}>{person.UserName}</option>
      )


      // console.log('OptionExecutive = ',OptionExecutive);
      const ComplaintsEditData = this.state.editData;
      // console.log('ComplaintsEditData = ' ,ComplaintsEditData);
      const { Complaints } = this.state;
      const { ComplaintsData } = this.state;
      // console.log('ComplaintsData render = ' ,ComplaintsData);
      const {OpenComplaints} = this.state;
      const {ClosedComplaints} = this.state;
      const {AwaitingComplaints} = this.state;
                // console.log("UserTicketList",OpenComplaints)
      const Opened = [];
      OpenComplaints && OpenComplaints.map( (OpenList,i)=>{
        let StatusData="";
        if(OpenList.Status=='Assigned'){
          StatusData = <span className="text-warning">Assigned</span>
        }else if(OpenList.Status=='ReOpen'){
          StatusData = <span className="text-danger">ReOpen</span>
        }else{
          StatusData = <span className="text-primary">UnAssigned</span>
        }
        const limit = 50;
        Opened.push({
          SN:i+1,
          TicketId: OpenList.TicketId,
          TicketTypeName: OpenList.TicketTypeName,
          TicketReasonName:OpenList.TicketReasonName,
          Description: <a title={OpenList.Description}>{OpenList.Description.substring(0,limit)}...</a>,
          DealerName: OpenList.DealerName,
          UserName: OpenList.UserName,
          CreatedDate: Moment(OpenList.CreatedDate).format('MM-DD-YYYY HH:mm:ss'),
          UpdatedDate: (OpenList.UpdatedDate)?Moment(OpenList.UpdatedDate).format('MM-DD-YYYY HH:mm:ss'):null,
          Status: StatusData,
          Action: <div>
                    <div className="badge badge-soft-success font-size-12">
                        <Link to={'/Remarks/'+OpenList.ComplaintId} className="text-primary" ><i className="fa fa-eye" data-param={OpenList.ComplaintId}></i></Link>
                    </div>
                  </div>
        })
      })
      const Closed = [];
      ClosedComplaints && ClosedComplaints.map( (ClosedList,i)=>{

        let StatusData="";
        if(ClosedList.Status=='Closed'){
          StatusData = <span className="text-success">Closed</span>
        }
        const limit = 50;
        Closed.push({
          SN:i+1,
          TicketId: ClosedList.TicketId,
          TicketTypeName: ClosedList.TicketTypeName,
          TicketReasonName:ClosedList.TicketReasonName,
          Description: <a title={ClosedList.Description}>{ClosedList.Description.substring(0,limit)}...</a>,
          DealerName: ClosedList.DealerName,
          UserName: ClosedList.UserName,
          CreatedDate: Moment(ClosedList.CreatedDate).format('MM-DD-YYYY HH:mm:ss'),
          UpdatedDate: (ClosedList.UpdatedDate)?Moment(ClosedList.UpdatedDate).format('MM-DD-YYYY HH:mm:ss'):null,
          Status: StatusData
        })
      })
      const Awaiting = [];
      AwaitingComplaints && AwaitingComplaints.map( (AwaitingList,i)=>{

        let StatusData="";
        if(AwaitingList.Status=='Awaiting'){
          StatusData = <span className="text-primary">Awaiting</span>
        }
        const limit = 50;
        Awaiting.push({
          SN:i+1,
          TicketId: AwaitingList.TicketId,
          TicketTypeName: AwaitingList.TicketTypeName,
          TicketReasonName:AwaitingList.TicketReasonName,
          Description: <a title={AwaitingList.Description}>{AwaitingList.Description.substring(0,limit)}...</a>,
          DealerName: AwaitingList.DealerName,
          UserName: AwaitingList.UserName,
          CreatedDate: Moment(AwaitingList.CreatedDate).format('MM-DD-YYYY HH:mm:ss'),
          UpdatedDate: (AwaitingList.UpdatedDate)?Moment(AwaitingList.UpdatedDate).format('MM-DD-YYYY HH:mm:ss'):null,
          Status: StatusData,
          Action: <div>
                    {/* {`${text.substring(0, MAX_LENGTH)}...`}
                    <div className="badge badge-soft-success font-size-12">
                        <Link to="#" className="text-primary" id={"edit"+OpenList.ComplaintId}><i className="fa fa-edit" onClick={this.editComplaintById} data-param={OpenList.ComplaintId}></i></Link>
                    </div> */}
                    <div className="badge badge-soft-success font-size-12">
                        <Link to={'/Remarks/'+AwaitingList.ComplaintId} className="text-primary" ><i className="fa fa-eye" data-param={AwaitingList.ComplaintId}></i></Link>
                    </div>
                  </div>
        })
      })
      const Complaint = [];
      ComplaintsData && ComplaintsData.map( (ComplaintList,i)=>{

        let StatusData="";
        if(ComplaintList.Status=='Assigned'){
          StatusData = <span className="text-warning">Assigned</span>
        }else if(ComplaintList.Status=='ReOpen'){
          StatusData = <span className="text-danger">ReOpen</span>
        }else if(ComplaintList.Status=='Awaiting'){
          StatusData = <span className="text-primary">Awaiting</span>
        }else if(ComplaintList.Status=='Closed'){
          StatusData = <span className="text-success">Closed</span>
        }else{
          StatusData = <span className="text-primary">UnAssigned</span>
        }
        const limit = 50;
        Complaint.push({
          SN:i+1,
          TicketId: ComplaintList.TicketId,
          TicketTypeName: ComplaintList.TicketTypeName,
          TicketReasonName:ComplaintList.TicketReasonName,
          Description: <a title={ComplaintList.Description}>{ComplaintList.Description.substring(0,limit)}...</a>,
          DealerName: ComplaintList.DealerName,
          UserName: ComplaintList.UserName,
          CreatedDate: Moment(ComplaintList.CreatedDate).format('MM-DD-YYYY HH:mm:ss'),
          UpdatedDate: (ComplaintList.UpdatedDate)?Moment(ComplaintList.UpdatedDate).format('MM-DD-YYYY HH:mm:ss'):null,
          Status: StatusData,
          Action: <div>
                    {/* {`${text.substring(0, MAX_LENGTH)}...`}
                    <div className="badge badge-soft-success font-size-12">
                        <Link to="#" className="text-primary" id={"edit"+OpenList.ComplaintId}><i className="fa fa-edit" onClick={this.editComplaintById} data-param={OpenList.ComplaintId}></i></Link>
                    </div> */}
                    <div className="badge badge-soft-success font-size-12">
                        <Link to={'/Remarks/'+ComplaintList.ComplaintId} className="text-primary" ><i className="fa fa-eye" data-param={ComplaintList.ComplaintId}></i></Link>
                    </div>
                  </div>
        })

      })
      const data = {
          columns: [
            {
              label: "Sl",
              field: "SN",
              sort: "asc",
              width: 150
            },
            {
              label: "Ticket Id",
              field: "TicketId",
              sort: "asc",
              width: 150
            }, 
            {
              label: "Ticket Type",
              field: "TicketTypeName",
              sort: "asc",
              width: 150
            },
            {
              label: "Ticket Reason",
              field: "TicketReasonName",
              sort: "asc",
              width: 150 
            },
            {
              label: "Description",
              field: "Description",
              sort: "asc",
              width: 150 
            },
            {
              label: "Dealer Name",
              field: "DealerName",
              sort: "asc",
              width: 150 
            },
            {
              label: "Assign To",
              field: "UserName",
              sort: "asc",
              width: 150 
            }, 
            {
              label: "Created Date",
              field: "CreatedDate",
              sort: "asc",
              width: 150 
            },
            {
              label: "Updated Date",
              field: "UpdatedDate",
              sort: "asc",
              width: 150 
            },
            {
              label: "Status",
              field: "Status",
              sort: "asc",
              width: 150 
            },  
            {
              label: "Action",
              field: "Action",
              sort: "asc",
              width: 270
            },
          ],
          rows: Opened 
      };
      const data1 = {
        columns: [
          {
            label: "Sl",
            field: "SN",
            sort: "asc",
            width: 150
          },
          {
            label: "Ticket Id",
            field: "TicketId",
            sort: "asc",
            width: 150
          }, 
          {
            label: "Ticket Type",
            field: "TicketTypeName",
            sort: "asc",
            width: 150
          },
          {
            label: "Ticket Reason",
            field: "TicketReasonName",
            sort: "asc",
            width: 150 
          },
          {
            label: "Description",
            field: "Description",
            sort: "asc",
            width: 150 
          },
          {
            label: "Dealer Name",
            field: "DealerName",
            sort: "asc",
            width: 150 
          },
          {
            label: "Assign To",
            field: "UserName",
            sort: "asc",
            width: 150 
          }, 
          {
            label: "Created Date",
            field: "CreatedDate",
            sort: "asc",
            width: 150 
          },
          {
            label: "Updated Date",
            field: "UpdatedDate",
            sort: "asc",
            width: 150 
          },
          {
            label: "Status",
            field: "Status",
            sort: "asc",
            width: 150 
          },
        ],
        rows: Closed 
      };
      const data2 = {
        columns: [
          {
            label: "Sl",
            field: "SN",
            sort: "asc",
            width: 150
          },
          {
            label: "Ticket Id",
            field: "TicketId",
            sort: "asc",
            width: 150
          }, 
          {
            label: "Ticket Type",
            field: "TicketTypeName",
            sort: "asc",
            width: 150
          },
          {
            label: "Ticket Reason",
            field: "TicketReasonName",
            sort: "asc",
            width: 150 
          },
          {
            label: "Description",
            field: "Description",
            sort: "asc",
            width: 150 
          },
          {
            label: "Dealer Name",
            field: "DealerName",
            sort: "asc",
            width: 150 
          },
          {
            label: "Assign To",
            field: "UserName",
            sort: "asc",
            width: 150 
          }, 
          {
            label: "Created Date",
            field: "CreatedDate",
            sort: "asc",
            width: 150 
          },
          {
            label: "Updated Date",
            field: "UpdatedDate",
            sort: "asc",
            width: 150 
          },
          {
            label: "Status",
            field: "Status",
            sort: "asc",
            width: 150 
          },  
          {
            label: "Action",
            field: "Action",
            sort: "asc",
            width: 270
          },
        ],
        rows: Awaiting 
      };
      const data3 = {
        columns: [
          {
            label: "Sl",
            field: "SN",
            sort: "asc",
            width: 150
          },
          {
            label: "Ticket Id",
            field: "TicketId",
            sort: "asc",
            width: 150
          }, 
          {
            label: "Ticket Type",
            field: "TicketTypeName",
            sort: "asc",
            width: 150
          },
          {
            label: "Ticket Reason",
            field: "TicketReasonName",
            sort: "asc",
            width: 150 
          },
          {
            label: "Description",
            field: "Description",
            sort: "asc",
            width: 150 
          },
          {
            label: "Dealer Name",
            field: "DealerName",
            sort: "asc",
            width: 150 
          },
          {
            label: "Assign To",
            field: "UserName",
            sort: "asc",
            width: 150 
          }, 
          {
            label: "Created Date",
            field: "CreatedDate",
            sort: "asc",
            width: 150 
          },
          {
            label: "Updated Date",
            field: "UpdatedDate",
            sort: "asc",
            width: 150 
          },
          {
            label: "Status",
            field: "Status",
            sort: "asc",
            width: 150 
          },  
          {
            label: "Action",
            field: "Action",
            sort: "asc",
            width: 270
          },
        ],
        rows: Complaint 
      };
        return (
            <React.Fragment>
              <div className="page-content">
              <Container fluid>
              <Breadcrumbs title="Tickets" breadcrumbItems={this.state.breadcrumbItems} />
              <Row>
                <Col xs={12}>
                  <Tabs >
                    <TabList>
                      <Tab>Open</Tab>
                      <Tab onClick={this.Closed}>Closed</Tab>
                      <Tab onClick={this.Awaiting}>Awaiting</Tab>
                      <Tab onClick={this.Search}>Search</Tab>
                    </TabList>
                    <TabPanel>
                      <Card>
                        <CardBody>
                          <MDBDataTable responsive bordered data={data} />
                        </CardBody>
                      </Card>
                    </TabPanel>
                    <TabPanel>
                      <Card>
                        <CardBody>
                            <MDBDataTable responsive bordered data={data1} />
                        </CardBody>
                      </Card>
                    </TabPanel>
                    <TabPanel>
                      <Card>
                        <CardBody>
                          <MDBDataTable responsive bordered data={data2} />
                        </CardBody>
                      </Card>
                    </TabPanel>
                    <TabPanel>
                      <Card>
                        <CardBody>
                          {this.props.apiError && this.props.apiError ? <Alert color="danger" isOpen={this.state.visible}>{this.props.apiError}</Alert> : null }
                          {this.props.message && this.props.message!=='' ? <Alert color="success" isOpen={this.state.visible}>{this.props.message} </Alert> : null }
                          <AvForm onValidSubmit={this.SearchData} className="form-horizontal" >
                            <FormGroup row>
                              {(this.state.RoleId === 2) &&
                                <Col sm={5} md={5} xl={5}>
                                <Label htmlFor="CustomerEId">Choose Executive</Label>
                                  <AvField className="form-control" name="CustomerEId" onChange={(e)=> this.setState({CustomerEId: e.target.value})} type="select"  id="CustomerEId">
                                    <option value="">Select Executive</option>
                                    {OptionExecutive}
                                  </AvField>
                                </Col>
                              }
                              <Col sm={5} md={5} xl={5}>
                              <Label htmlFor="Status">Choose Status</Label>
                                <AvField className="form-control" name="Status" onChange={(e)=> this.setState({Status: e.target.value})} type="select" id="Status">
                                <option value="">Select Status</option>
                                <option value="UnAssigned">UnAssigned</option>
                                <option value="Assigned">Assigned</option>
                                <option value="ReOpen">ReOpen</option>
                                <option value="Awaiting">Awaiting</option>
                                <option value="Closed">Closed</option>
                                </AvField>
                              </Col>
                              {/* {(this.state.RoleId === 1) &&
                                <Col sm={6} md={6} xl={6}>
                                <Label htmlFor="CustomerEId">Choose Executive</Label>
                                  <AvField className="form-control" name="CustomerEId"  type="select"  id="CustomerEId">
                                    <option value="">Select Executive</option>
                                    {OptionExecutive}
                                  </AvField>
                                </Col>
                              } */}
                              <Col sm={2} md={2} xl={2}>
                                <Label htmlFor="button" color="primary"> </Label>
                                <AvField className="form-control btn btn-primary mt-2" name="submit" onClick={this.Checkvalid} type="submit" value="Search" />
                                {/* <Button type="submit" color="primary">Save changes</Button> */}
                              </Col>
                            </FormGroup>
                          </AvForm>
                          <MDBDataTable responsive bordered data={data3} />
                        </CardBody>
                      </Card>
                    </TabPanel>
                </Tabs>
              </Col>
            </Row>
            </Container>
            </div>
          </React.Fragment>
        );
    }
}
// export default Complaints;
const mapStatetoProps = state => {
  const { CustomerCareExe } = state.CustomerCareExecutive;
  const { OpenComplaints } = state.OpenComplaints;
  const { ClosedComplaints } = state.ClosedComplaints;
  const { AwaitingComplaints } = state.AwaitingComplaints;
  const { Complaints, apiError, loading, isAlertOpen, modal_static, message, modalError } = state.Complaints; // .Complaints
  return { AwaitingComplaints,ClosedComplaints,OpenComplaints,Complaints,CustomerCareExe,  apiError, loading, isAlertOpen, modal_static, message, modalError};
}

export default withRouter(connect(mapStatetoProps, { AwaitingComplaintsList,ClosedComplaintsList,OpenComplaintsList,CustomerCareExecutiveList,ComplaintsList,editCustomerComplaints,ComplaintsListApiError,deleteComplaint})(Complaints));
 
