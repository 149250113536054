import { all } from 'redux-saga/effects'

//public
import accountSaga from './auth/register/saga';
import loginSaga from './auth/login/saga';

import CustomerCareExeSaga from './CustomerExecutive/saga';
import Count_ListSaga from './Count/saga';
import Notification_List_Saga from './Notification/saga';
import forgetSaga from './auth/forgetpwd/saga';
import LayoutSaga from './layout/saga';
import TicketsTypeSaga from './Tickets/TicketsType/saga';
import TicketsReasonSaga from './Tickets/TicketsReason/saga';
import ComplaintsSaga from './Complaints/saga';
import ProfilesSaga from './Profile/saga';
import RemarksSaga from './Remarks/saga';
import OpenComplaintsSaga from './Complaints/Open/saga';
import ClosedComplaintsSaga from './Complaints/Closed/saga';
import AwaitingComplaintsSaga from './Complaints/Awaiting/saga';
import MainDashboardSaga from './MainDashboard/saga';
import ProfileMenuSaga from './auth/ProfileMenu/saga';

export default function* rootSaga() {
    yield all([
        
        //public
        accountSaga(),
        loginSaga(),
        CustomerCareExeSaga(),
        Count_ListSaga(),
        Notification_List_Saga(),
        ProfilesSaga(),
        forgetSaga(),
        LayoutSaga(),
        ComplaintsSaga(),
        TicketsReasonSaga(),
        TicketsTypeSaga(),
        RemarksSaga(),
        OpenComplaintsSaga(),
        ClosedComplaintsSaga(),
        AwaitingComplaintsSaga(),
        MainDashboardSaga(),
        ProfileMenuSaga(),

    ])
}