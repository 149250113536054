import { CLOSED_COMPLAINTS_LIST,CLOSED_COMPLAINTS_LIST_SUCCESSFUL,
     CLOSED_COMPLAINTS_LIST_API_FAILED,DELETE_COMPLAINT,
     ADD_CUSTOMER_CLOSED_COMPLAINTS,ADD_CLOSED_COMPLAINTS_API_FAILED,
     EDIT_CUSTOMER_CLOSED_COMPLAINTS } from './actionTypes';

const initialState = {
    apiError: null, loading: false, ClosedComplaints: [],  isAlertOpen: false, modal_static: false, message: null, modalError: null
}

const ClosedComplaints = (state = initialState, action) => {
    switch (action.type) {
        case CLOSED_COMPLAINTS_LIST:
            state = {
                ...state,
                loading: true,
                apiError: null
            }
            break;
        case CLOSED_COMPLAINTS_LIST_SUCCESSFUL:
            state = {
                ...state,
                ClosedComplaints: action.payload.ClosedComplaints,
                loading: false,
                message: action.payload.message.message
            }
            break;
        case CLOSED_COMPLAINTS_LIST_API_FAILED:
            state = {
                ...state,
                loading: false,
                ClosedComplaints: []
            }
            break;
            case ADD_CUSTOMER_CLOSED_COMPLAINTS:
            state = {
                ...state,
                loading: true,
                modalError: null
            }
            break;
            case ADD_CLOSED_COMPLAINTS_API_FAILED:
            state = {
                ...state,
                loading: false,
                message: action.payload
            }
            break;
            case EDIT_CUSTOMER_CLOSED_COMPLAINTS:
            state = {
                ...state,
                loading: false,
                apiError: null,
                message: null
            }
            break;
            case DELETE_COMPLAINT:
            state = {
                ...state,
                loading: true,
                apiError: null,
                message: null
            }
            break;
        default:
            state = { ...state };
            break;
    }

    return state;
}

export default ClosedComplaints;