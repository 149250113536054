import { act } from 'react-dom/test-utils';
import { CHECK_LOGIN, LOGIN_USER_SUCCESSFUL, API_ERROR, LOGOUT_USER, LOGOUT_USER_SUCCESS, FETCH_COMPANY, FETCH_COMPANY_SUCCESSFUL } from './actionTypes';

const initialState = {
    loginError: "", message: null, loading: false, company: null,companyId:''
}

const login = (state = initialState, action) => {
    // console.log(action);
    
    switch (action.type) {
        case CHECK_LOGIN:
            
            state = {
                ...state,
                loading: true,
                companyId:action.payload.company,
            }
            
            break;
        case LOGIN_USER_SUCCESSFUL:
            
            state = {
                ...state,
                loading: false
            }
            
            break;

        case LOGOUT_USER:
            state = { ...state };
            break;

        case LOGOUT_USER_SUCCESS:
            state = { ...state };
            break;
            
        case API_ERROR:
            state = {
                ...state,
                loading: false,
                loginError: action.payload
            }
            break;
        
        case FETCH_COMPANY:
            state = {
                ...state,
                loading: true,
            }
        break;

        case FETCH_COMPANY_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                company: action.payload.company
            }
        break;

        default:
            state = { ...state };
            break;
    }
    return state;
}

export default login;