export const COMPLAINTS_LIST = 'complaints_list';
export const COMPLAINTS_LIST_SUCCESSFUL = 'complaints_list_successfull';
export const COMPLAINTS_LIST_API_FAILED = 'complaints_list_api_failed';
export const EDIT_COMPLAINTS = 'edit_complaints';
export const EDIT_COMPLAINTS_API_FAILED = 'edit_complaints_api_failed';
export const DELETE_COMPLAINT = 'delete_complaint';
export const ADD_COMPLAINTS_API_FAILED = 'add_complaints_api_failed';
export const ADD_CUSTOMER_COMPLAINTS = 'add_customer_complaints';
export const EDIT_CUSTOMER_COMPLAINTS = 'edit_customer_complaints';


