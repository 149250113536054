import React, { Component } from 'react';
import { Col, Card, CardBody, Media } from "reactstrap";
import { withRouter,Redirect } from "react-router-dom";
// import Moment from 'moment';
import { connect } from "react-redux";
import axios from 'axios';
import { Count_List_By_Id, Count_List_API_FAILED } from  '../../store/actions';

class MiniWidgets extends Component {
    constructor(props) {
      //  console.log("props Mini widget", props.reports)
        super(props);
        this.state = {
            RoleId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).Id : null,
            breadcrumbItems : [
                { title : "Dashboard", link : "dashboard" },
            ],
            reports : [
                { icon : "ri-stack-line", iconColor:"text-danger", title : "Total", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-store-2-line", iconColor:"text-primary", title : "Open", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-store-2-line", iconColor:"text-warning", title : "Assigned", value : "0", rate : "2.4%", desc : "From previous period" },
                { icon : "ri-briefcase-4-line", iconColor:"text-success", title : "Closed", value : "0", rate : "2.4%", desc : "From previous period" },
            ],
            CountListData: [],
        }
    }
    componentDidMount(){
        // if(this.state.RoleId==1){
        //     return <Redirect to='/maindashboard'/>
        // }
        //  this.props.Count_List_API_FAILED(""); 
          this.props.Count_List_By_Id(this.state.RoleId);
 
        // const baseURLL = process.env.REACT_APP_API_BASEURL;
        // const tokenByIdGet = JSON.parse(localStorage.getItem('token'));
        //     console.log(`${baseURLL}/customer/complaintcount/${this.state.RoleId}`)
        //     console.log("MINi",tokenByIdGet )

        // fetch(`${baseURLL}/customer/complaintcount/${this.state.RoleId}`, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Accepts': 'application/json', 
        //         'Authorization':`Bearer ${tokenByIdGet}`
        //     }
        //     }).then(response => response.json())
        //     .then(response => {  
        //                 console.log("response MINI",response)  
        //     });
 
    }
    componentWillReceiveProps(nextProps) {
        
        // console.log("Start MINIWIDGET Will Receve Props", nextProps.Count) 
             let CountListData = []; 

            nextProps.Count && nextProps.Count.map((CountItem, i)=>{

                CountListData.push(
                    { icon : "ri-stack-line", iconColor:"text-danger", title : "Total", value : `${CountItem.Total?CountItem.Total:0}`, rate : "2.4%", desc : "From previous period" },
                    { icon : "ri-store-2-line", iconColor:"text-primary", title : "Open", value : `${CountItem.Open?CountItem.Open:0}`, rate : "2.4%", desc : "From previous period" },
                    { icon : "ri-store-2-line", iconColor:"text-warning", title : "Assigned", value : `${CountItem.Assigned?CountItem.Assigned:0}`, rate : "2.4%", desc : "From previous period" },
                    { icon : "ri-briefcase-4-line", iconColor:"text-success", title : "Closed", value : `${CountItem.Closed?CountItem.Closed:0}`, rate : "2.4%", desc : "From previous period" },

                 )

            })
        
            
             this.setState({reports: CountListData});
        
         
          
    }

    render() {
        
        return (
            <React.Fragment>
                {
                    this.state.reports && this.state.reports.map((report, key) =>
                                    <Col key={key} md={3}>
                                        <Card>
                                            <CardBody>
                                                <Media>
                                                    <Media body className="overflow-hidden"> 
                                                        <p className={`text-truncate font-size-14 mb-2 ${report.iconColor}`}>{report.title && report.title}</p>
                                                        <i className={`${report.icon} font-size-24 ${report.iconColor}`}></i>  
                                                    </Media> 
                                                    <div className="text-primary">  
                                                        <h4 className={`${report.iconColor} mb-0`}>{report.value && report.value}</h4>
                                                    </div>
                                                </Media>
                                            </CardBody> 
                                        </Card>
                                    </Col>
                    )
                }
            </React.Fragment>
        );
    }
}

//export default MiniWidgets;

const mapStatetoProps = state => {
    const { Count, apiError, loading, message } = state.Count_List; // .Complaints
    return { Count, apiError, loading,message };
  }
  
  export default withRouter(connect(mapStatetoProps,
         { Count_List_By_Id, Count_List_API_FAILED })(MiniWidgets));