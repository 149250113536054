import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { READNOTIFICATION,NOTIFICATION_LIST,NOTIFICATION_LIST_BY_ID } from './actionTypes';
import { Notification_List_successfull, Notification_List_Api_Faild } from './actions';

// AUTH related methods
import { getList, getDataById, editData} from '../../helpers/fackBackend_Helper';

function* getNotification_List_successfull({ payload: { LoginId } }) {
    try {
        const response = yield call(getList, `/customer/complaintlistnotification/${LoginId}`);
       
        if (response) {
            // console.log("complaintlistnotification",response.data.data);
            yield put(Notification_List_successfull(response.data.data));
        }
    } catch (error) {
        // console.log('error',error)
        yield put(Notification_List_Api_Faild(error));
    }
}

export function* watchGetNotification_List() {
    yield takeEvery(NOTIFICATION_LIST, getNotification_List_successfull)
}
function* ReadNotification({ payload: { Notifications } }) {
    try {
        // console.log("ReadNotification response",Notifications);
        const responseedit = yield call(editData, `/admin/notification`,Notifications);
        // console.log("ReadNotification response edit",responseedit);        
        if (responseedit) {
            const response = yield call(getList, `/customer/complaintlistnotification/${Notifications.UserId}`);
            // console.log("getNotification response",response)
            if (response) {
                // console.log('ReadNotification response.data.data',response.data.data);
                yield put(Notification_List_successfull(response.data.data));
            }
        }
    } catch (error) {
        // console.log('error',error)
        yield put(Notification_List_Api_Faild(error));
    }
}

export function* watchGetReadNotificationt() {
    yield takeEvery(READNOTIFICATION, ReadNotification)
}


function* getNotification_List_By_Id({ payload: { Id } }) {

    try {
        // console.log('saga Id',Id)
        const response = yield call(getDataById, `/customer/complaintlistnotification/${Id}`);
        // console.log("response getNotification by id Gaga", response)
        if (response) {
            // console.log('api result',response)
            yield put(Notification_List_successfull(response.data.data));
        }
    } catch (error) {
        // console.log('Notification_List_Api_Faild api error',error)
        yield put(Notification_List_Api_Faild(error));
    }
}

export function* watchGetNotification_List_By_Id() {
    yield takeEvery(NOTIFICATION_LIST_BY_ID, getNotification_List_By_Id)
}
 
function* Notification_List_Saga() {
    yield all([fork(watchGetNotification_List)]);
    yield all([fork(watchGetNotification_List_By_Id)]);
    yield all([fork(watchGetReadNotificationt)]);      
}

export default Notification_List_Saga;