import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { TICKETSREASON_LIST,ADDTICKETSREASON,EDITTICKETSREASON,DELETE_TICKETREASON,ADD_ACTIVITY } from './actionTypes';
import { TicketsReasonListSuccessful, TicketsReasonListApiError,addTicketReasonApiError,add_activity_api_error,activitylist_successfull } from './actions';

// AUTH related methods
import { getList,addData,editData,deleteData } from '../../../helpers/fackBackend_Helper';

function* getTicketsReasonList( { payload: { loginId } } ) {
    try {
        const response = yield call(getList, `/ticket/ticketreasonlist/${loginId}`);
        // console.log("Reason list saga", response.data.data);
        if (response) {
            yield put(TicketsReasonListSuccessful(response.data.data));
        }
    } catch (error) {
        yield put(TicketsReasonListApiError(error));
    }
}

export function* watchGetTicketsReason() {
    yield takeEvery(TICKETSREASON_LIST, getTicketsReasonList)
}



function* addTicketReason({ payload: { TicketsReason } }) {
    try {
        const responseCreate = yield call(addData, '/ticket/ticketreason', TicketsReason);
        // console.log('response from saga = ', responseCreate);
        if (responseCreate) {
            const response = yield call(getList, `/ticket/ticketreasonlist/${TicketsReason.CompanyCode}`);
            if (response) {
                // console.log('responseCreate',responseCreate);
                // console.log('response.data.data',response.data.data);
                yield put(TicketsReasonListSuccessful(response.data.data,responseCreate));                
            }
        }
    } catch (error) {
        yield put(addTicketReasonApiError(error));
    }
}

export function* watchaddTicketReason() {
    yield takeEvery(ADDTICKETSREASON, addTicketReason);
}


function* addNewactivity({ payload: { activity } }) {
    
    try {
        // console.log("try saga payload activity",activity);
        const responseCreate = yield call(addData, '/activity', activity);
        if (responseCreate) {
            // console.log("add payload activity",activity)
            const response = yield call(getList, `/activities`);
            // console.log("add response activity",response.data.data)
            if (response) {
                // console.log('response saga = ',response.data.data);
                // console.log('responseCreate',responseCreate);
                yield put(activitylist_successfull(response.data.data,responseCreate));
            }
            else{

                // yield put(add_activity_api_error(response.message));
            }
        }
    } catch (error) {
        // yield put(add_activity_api_error(error));
        // console.log("catch saga payload activity",activity)
    }
}


export function* watchAdd_Activity() {
    yield takeEvery(ADD_ACTIVITY, addNewactivity);
}


function* editTicketReason({ payload: { TicketsReason } }) {
    try {
        const responseedit = yield call(editData, '/ticket/ticketreason', TicketsReason);
        if (responseedit) {
            const response = yield call(getList, `/ticket/ticketreasonlist/${TicketsReason.CompanyCode}`);
            if (response) {
                // console.log('response= ', response.data.data)
                // console.log('responseadd= ', responseedit)
                yield put(TicketsReasonListSuccessful(response.data.data,responseedit));
            }
        }
    } catch (error) {
        yield put(addTicketReasonApiError(error));
    }
}

export function* watcheditTicketReason() {
    yield takeEvery(EDITTICKETSREASON, editTicketReason);
}

function* updateTicketReasonStatus({ payload: { Id,status,CompanyCode,UpdatedBy } }) {
    try {
        // console.log('Tickets Reason',Id,status,UpdatedBy)
        const responseD = yield call(deleteData, '/ticket/ticketreason', { Id,status,UpdatedBy });
        // console.log('update status =',responseD);
        if (responseD) {
            const response = yield call(getList, `/ticket/ticketreasonlist/${CompanyCode}`);
            if (response) {
                // console.log('response',response.data.data)
                // console.log('responseD',responseD)
                yield put(TicketsReasonListSuccessful(response.data.data,responseD));
                
            }
        }
    } catch (error) {
        yield put(addTicketReasonApiError(error));
    }
}

export function* watchdeleteTicketReason() {
    yield takeEvery(DELETE_TICKETREASON, updateTicketReasonStatus);
}


function* TicketsReasonSaga() {
    yield all([fork(watchGetTicketsReason)]);
    yield all([fork(watchaddTicketReason)]);
    yield all([fork(watcheditTicketReason)]);
    yield all([fork(watchdeleteTicketReason)]);
    yield all([fork(watchAdd_Activity)]);
}

export default TicketsReasonSaga;