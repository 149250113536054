import { AWAITING_COMPLAINTS_LIST,AWAITING_COMPLAINTS_LIST_SUCCESSFUL,
     AWAITING_COMPLAINTS_LIST_API_FAILED,DELETE_COMPLAINT,
     ADD_CUSTOMER_AWAITING_COMPLAINTS,ADD_AWAITING_COMPLAINTS_API_FAILED,
     EDIT_CUSTOMER_AWAITING_COMPLAINTS } from './actionTypes';

const initialState = {
    apiError: null, loading: false, AwaitingComplaints: [],  isAlertOpen: false, modal_static: false, message: null, modalError: null
}

const AwaitingComplaints = (state = initialState, action) => {
    switch (action.type) {
        case AWAITING_COMPLAINTS_LIST:
            state = {
                ...state,
                loading: true,
                apiError: null
            }
            break;
        case AWAITING_COMPLAINTS_LIST_SUCCESSFUL:
            state = {
                ...state,
                AwaitingComplaints: action.payload.AwaitingComplaints,
                loading: false,
                message: action.payload.message.message
            }
            break;
        case AWAITING_COMPLAINTS_LIST_API_FAILED:
            state = {
                ...state,
                loading: false,
                AwaitingComplaints: []
            }
            break;
            case ADD_CUSTOMER_AWAITING_COMPLAINTS:
            state = {
                ...state,
                loading: true,
                modalError: null
            }
            break;
            case ADD_AWAITING_COMPLAINTS_API_FAILED:
            state = {
                ...state,
                loading: false,
                message: action.payload
            }
            break;
            case EDIT_CUSTOMER_AWAITING_COMPLAINTS:
            state = {
                ...state,
                loading: false,
                apiError: null,
                message: null
            }
            break;
            case DELETE_COMPLAINT:
            state = {
                ...state,
                loading: true,
                apiError: null,
                message: null
            }
            break;
        default:
            state = { ...state };
            break;
    }

    return state;
}

export default AwaitingComplaints;