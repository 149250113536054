import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, UncontrolledTooltip, Alert,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Container, FormGroup, Label, Input } from "reactstrap";
import { AvForm, AvInput,AvField } from "availity-reactstrap-validation";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Moment from 'moment';
//Import Breadcrumb
// import Breadcrumbs from '../../components/Common/Breadcrumb';
import "./datatables.scss"; 


// import BootstrapTable from 'react-bootstrap-table-next';
// import ToolkitProvider from 'react-bootstrap-table2-toolkit';
// import TicketingModel from '../../pages/Ticketing/TicketingModel';

import { 
  CompanyList, 
  CompanyListByID,
  CompanyListApiError,
  addCompany,
  addCompanyApiError,
  editCompany,
  deleteCompanyList,
  CompanyLogoApi,
} from  '../../store/actions';
import { isNull } from "lodash";

const ImgURL = process.env.REACT_APP_IMAGE_URL;

class Company extends Component {
    constructor(props) {
      super(props);
      this.state = {
        visible: false,
        modal_standard: false,
        modal_large: false,
        modal_xlarge: false,
        modal_small: false,
        modal_center: false,
        modal_scroll: false,
        modal_static: false,
        ErrorMsg:'',
        breadcrumbItems : [
          { title : "Customer Care Executive", link : "customer-executive" }, 
      ],
      loggedInUserId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).Id : '',
      RoleId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).RoleId : null,
      // CompanyId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyId : null,
      // CompanyCode: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyCode : null,
      
      isAlertOpen : false,
      editData: null,
      Company:[],
      CompanyList: [],
      editCompany: [],

      };

        this.tog_standard = this.tog_standard.bind(this);
        this.tog_xlarge = this.tog_xlarge.bind(this);
        this.tog_large = this.tog_large.bind(this);
        this.tog_small = this.tog_small.bind(this);
        this.tog_center = this.tog_center.bind(this);
        this.tog_scroll = this.tog_scroll.bind(this);
        this.tog_static = this.tog_static.bind(this);
        this.addCompany = this.addCompany.bind(this);
        this.editCompany = this.editCompany.bind(this);
        this.deleteCompanyById = this.deleteCompanyById.bind(this);
        // this.fileChangedHandler=this.fileChangedHandler.bind(this);
        this.picture = React.createRef();
        
    }

    


  componentDidMount(){
    // this.props.CompanycutiveListApiError("");
    // this.props.addCompanyApiError("");
    //  this.props.CompanycutiveList(this.state.CompanyCode);
      this.props.CompanyList();
      
  } 
  componentWillReceiveProps(nextProps) {
 
    
    this.setState({CompanyList : nextProps.Company}); 
    console.log("Props Company",nextProps.Company);
  }
  editCompanyById = (event) => {
    
    const CompId = event.target.dataset.param;
    console.log('CompId = ',CompId);
    console.log(this.state.CompanyList);
    const editData = this.state.CompanyList && this.state.CompanyList.filter(({ Id }) => Id == CompId);
    console.log('editData = ',editData);
    
    if(typeof editData !== "undefined"){
      this.setState({ editData: editData[0], modal_scroll: true, isAlertOpen: false });
    }else{
      this.state.editData = [];
      this.setState({ editData: editData, modal_scroll: true, isAlertOpen: false });
      console.log('PopupData ',editData);
    }
    
  }
  deleteCompanyById = (event) => {
    //console.log("event",event.target.dataset)
    const { param } = event.target.dataset;
    const status =  'Delete';
    this.props.deleteCompanyById(param, status);
    if (this.props.modalError === null) {
      this.setState({ isAlertOpen: true });
      
        this.setState({ modal_scroll: false });
        this.setState({visible:true},()=>{
          window.setTimeout(()=>{
            this.setState({visible:false})
          },5000)
        });
    }

  } 
  fileChangedHandler = (event) => {
    let picture = event.target.files[0];

    console.log('picture name = ', picture.name);

    const formData = new FormData();
    formData.append( "picture", picture, picture.name);
    console.log('formData',formData);
    this.props.CompanyLogoApi(formData);
   
  }
  addCompany = (event, values) => {
    
    let picture = values.picture;
    console.log("type:",typeof(picture)); // 👉️ object
    const result = JSON.stringify(picture)
    console.log("type:",typeof(result)); // 👉️ object
    console.log(result); // 👉️ ['Fri', 'Dec', ...]
    //console.log("picture",picture.toString().split('/');
    // let value = event.target.value;
    // let name = event.target.name;
    // console.log("name",name);
    // // console.log("value",value);
    //C:\\fakepath\\virtuzologo.jpg
    // let pic = result.slice(19,-1);
    // console.log("pic",pic);
    const  picturefile = result.slice(19,-2);
    console.log("picturefile",picturefile);
    // let picturesData = {
    //   picture: values.picture.str.substr(0,12),
      
    // };
    //console.log('picturesData',picturesData);
    
    let CompanyData = { 
      filename :  picturefile,
      companyName : values.CompanyName, 
      Email : values.CompanyEmail, 
      CompanyPrefix : values.CompanyPrefix, 
      URL : values.CompanyUrl,
     // picture : values.picture,
      status: "add"
    };
        // const formData = new FormData();
        // formData.append( "companyName" , values.CompanyName); 
        // formData.append( "Email" , values.CompanyEmail); 
        // formData.append( "CompanyPrefix" , values.CompanyPrefix); 
        // formData.append( "URL" , values.CompanyUrl);
        // formData.append( "picture",picture, picture.name);
        // formData.append( "IsActive", 1);
        // formData.append( "status", 'add');
        // formData.append( "Id", Id);
        // this.props.editProfiles(formData);
    console.log(CompanyData);
    this.props.addCompany(CompanyData);
 
        if (this.props.modalError === null) {
          this.setState({ isAlertOpen: true });
          
            this.setState({ modal_scroll: false });
            this.setState({visible:true},()=>{
              window.setTimeout(()=>{
                this.setState({visible:false})
              },5000)
            });
        }
  }
 

    // Submit Updated data

    editCompany = (event, values) => {
      
      var picture = values.picture;
      if(JSON.stringify(picture) == '{"":""}' ){
        var picture = values.CompanyLogo;
        console.log("ifpicturevalue:",picture);
        // console.log("type:",typeof(picture)); // 👉️ object
        var result = JSON.stringify(picture)
        // console.log("type:",typeof(result)); // 👉️ string
        console.log(result);
        var  picturefile = result.slice(40,-2);
       
      }else{
        
        console.log("picture:",picture);
        //console.log("type:",typeof(picture)); // 👉️ object
        var result = JSON.stringify(picture)
       // console.log("type:",typeof(result)); // 👉️ string
        console.log("result:",result);
        var  picturefile = result.slice(19,-2);
        console.log("picturefile",picturefile);
      }
     
      
      let CompanyData = {
        filename :  picturefile,
        companyName : values.CompanyName, 
        Email : values.CompanyEmail, 
        CompanyPrefix : values.CompanyPrefix, 
        URL : values.CompanyUrl,
        //CompanyLogo : values.CompanyLogo,
        IsActive:values.IsActive,
        // CompanyId : this.state.CompanyId,
        // CompanyCode : this.state.CompanyCode,  
        status:"edit",
        // RoleId : 3,
        // UpdatedBy : this.state.loggedInUserId,
        Id: this.state.editData.Id
      };
      console.log('Description = ',CompanyData);
      console.log('props = ',this.props);
      this.props.editCompany(CompanyData);
      //console.log("this.props.modalError:",this.props.modalError);
      if (this.props.modalError === null) {
        this.setState({ isAlertOpen: true });
        
          this.setState({ modal_scroll: false });
          this.setState({visible:true},()=>{
            window.setTimeout(()=>{
              this.setState({visible:false})
            },5000)
          });
      }

      // setTimeout(() => {
      //   console.log('this.props',this.props,'modal error',this.props.modalError);
      //   if (this.props.modalError === null) {
      //     this.setState({ isAlertOpen: true });
      //     setTimeout(() => {
      //       this.setState({ modal_scroll: false });
      //     }, 1000);
      //   }
      // }, 1000);
    }

    tog_static() {
      this.setState(prevState => ({
        modal_static: !prevState.modal_static
      }));
      this.removeBodyCss();
    }
  
    tog_standard() {
      this.setState(prevState => ({
        modal_standard: !prevState.modal_standard
      }));
      this.removeBodyCss();
    }
    removeBodyCss() {
      document.body.classList.add("no_padding");
    }
  
    tog_large() {
      this.setState(prevState => ({
        modal_large: !prevState.modal_large
      }));
      this.removeBodyCss();
    }
    tog_xlarge() {
      this.setState(prevState => ({
        modal_xlarge: !prevState.modal_xlarge
      }));
      this.removeBodyCss();
    }
    tog_small() {
      this.setState(prevState => ({
        modal_small: !prevState.modal_small
      }));
      this.removeBodyCss();
    }
    tog_center() {
      this.setState(prevState => ({
        modal_center: !prevState.modal_center
      }));
      this.removeBodyCss();
    }
    tog_scroll() {
      this.setState(prevState => ({

        modal_scroll: !prevState.modal_scroll
      }));
      this.removeBodyCss();
    }
    show() {
      this.setState({ visible: true });
    }
    hide() {
      this.setState({ visible: false });
    }

  TicketingModel(){

      console.log("TicketingModel Call");
           
  }
   handleSelect(e){
      console.log(e);
    }
  
    render() {
      const CompanyData = this.state.editData;
       const  RowData = [];
       const { CompanyList } = this.state;
       console.log('CompanyList',CompanyList);
       CompanyList && CompanyList.map( (CompanyList,i)=>{
        RowData.push(
            {
              SN:i+1,
              Name: CompanyList.CompanyName,
              Email: CompanyList.CompanyEmail,
              CompanyCode:CompanyList.CompanyCode,
              CompanyPrefix: CompanyList.CompanyPrefix,
              URL: CompanyList.CompanyUrl,
              LoginUrl: CompanyList.LoginUrl,
              Logo: <img width="100" height="100" src={ImgURL+CompanyList.Logo} />,
              CreatedDate: Moment(CompanyList.CreatedDate).format('MM-DD-YYYY HH:mm:ss'),
              UpdatedDate: Moment(CompanyList.UpdatedDate).format('MM-DD-YYYY HH:mm:ss'),
              Action: <div className="badge badge-soft-success font-size-12"> 
             
                        <Link to="#" data-toggle="modal"
                               className="mr-3 text-primary" id={"edit"+CompanyList.Id}><i onClick={this.editCompanyById}  data-param={CompanyList.Id} className="mdi mdi-pencil font-size-18"></i></Link>
                        
                            {CompanyList.IsActive=='1'?
                              <Link to="#" className={`text-danger`} 
                                          id={"delete"+CompanyList.Id}><i className="mdi mdi-trash-can font-size-18" 
                                          onClick={this.deleteCompanyById} data-param={CompanyList.Id}></i>
                                  </Link>:''}
                      </div>
            })

          })
        const data = {
            columns: [
              {
                label: "S.N",
                field: "SN",
                sort: "asc",
                width: 150
              },
              {
                label: "Name",
                field: "Name",
                sort: "asc",
                width: 150
              },
              {
                label: "Email",
                field: "Email",
                sort: "asc",
                width: 150 
              },
              {
                label: "Company Code",
                field: "CompanyCode",
                sort: "asc",
                width: 150 
              },
              
              {
                label: "CompanyPrefix",
                field: "CompanyPrefix",
                sort: "asc",
                width: 150 
              },
              
              {
                label: "URL",
                field: "URL",
                sort: "asc",
                width: 270
              },
              {
                label: "LoginUrl",
                field: "LoginUrl",
                sort: "asc",
                width: 270
              },
              {
                label: "Logo",
                field: "Logo",
                sort: "asc",
                width: 270
              },
              {
                label: "CreatedDate",
                field: "CreatedDate",
                sort: "asc",
                width: 270
              },
              {
                label: "UpdatedDate",
                field: "UpdatedDate",
                sort: "asc",
                width: 270
              }, 
              {
                label: "Action",
                field: "Action",
                sort: "asc",
                width: 270
              }, 
            ],
            rows: RowData 
          };
   
      return (
       
                <Col xs={12}>
                  <Card>
                    <CardBody> 
                     <div className="text-right">
                      <Button
                            type="button"
                            color="primary" className="waves-effect waves-light align-center"
                            onClick={this.editCompanyById}
                            data-toggle="modal"
                          >
                           Add
                        </Button>  
                     </div>
                     <h4 className="card-title">Company</h4>
                     
                     <MDBDataTable responsive bordered hover data={data} />
                        <Modal
                          isOpen={this.state.modal_scroll}
                          toggle={this.tog_scroll}
                          scrollable={true}
                        >
                          <ModalHeader toggle={() => this.setState({ modal_scroll: false })}>
                          {(CompanyData) ? 'Edit Company' : 'Add Company'} 
                           </ModalHeader>
                          <ModalBody>
                                 <AvForm onValidSubmit={(CompanyData) ? this.editCompany : this.addCompany}  className="form-horizontal" >
                                  <FormGroup row>
                                             <Col md={6}>
                                             <Label htmlFor="CompanyName">Name</Label>
                                                <AvField className="form-control" type="text"  required name="CompanyName"  value={CompanyData && CompanyData.CompanyName} placeholder="Company Name.." id="example-text-input" />
                                            </Col>
                                            <Col md={6}>
                                            <Label htmlFor="Email">Email</Label>
                                                <AvField className="form-control" type="email" required name="CompanyEmail" value={CompanyData && CompanyData.CompanyEmail}  placeholder="Your Company Mail.." id="example-text-input" />
                                            </Col>
                                  </FormGroup>
                                  <FormGroup row> 
                                            <Col md={6}>
                                            <Label htmlFor="CompanyLogo">Logo</Label>
                                                <AvField className="form-control" type="file"  name="picture[]" onChange={this.fileChangedHandler} accept="image/*" placeholder=" Company Logo .." id="example-text-input" />
                                                <AvField className="form-control" type="hidden"  value={CompanyData && CompanyData.Logo} name="CompanyLogo[]" placeholder=" Company Logo .." id="example-text-input" />
                                            </Col> 
                                            <Col md={6}>
                                            <Label htmlFor="CompanyUrl">Company Url</Label>
                                                <AvField className="form-control" type="text" required  name="CompanyUrl" value={CompanyData && CompanyData.CompanyUrl} placeholder=" Company Url .." id="example-text-input" />
                                            </Col>
                                  </FormGroup>
                                  <FormGroup row> 
                                          {(CompanyData)? '' :
                                            <Col md={6}>
                                                <Label htmlFor="CompanyPrefix">Prefix</Label>
                                                <AvField className="form-control" type="text" required  name="CompanyPrefix" value={CompanyData && CompanyData.CompanyPrefix} placeholder="Company Prefix.." id="example-text-input" />
                                            </Col>
                                          }
                                          {(CompanyData)? 
                                            <Col md={6}>
                                            <Label htmlFor="Status">Status</Label>
                                            <AvField className="form-control" name="IsActive"  value={CompanyData && JSON.stringify(CompanyData.IsActive)} type="select" id="IsActive" >
                                              <option value="">Select</option>
                                              <option value="1">Active</option>
                                              <option value="0">InActive</option> 
                                            </AvField>
                                            </Col>
                                            : ''}
                                  </FormGroup>
                                  <ModalFooter>
                                    <Button type="button" color="light"  onClick={() => this.setState({ modal_scroll: false }) }>Close</Button>
                                    <Button type="submit" color="primary"  >Save changes</Button>
                                  </ModalFooter>
                                </AvForm>
                          </ModalBody>
                        </Modal>
                    </CardBody>
                  </Card>
                </Col>
      );
    }
  }
const mapStatetoProps = state => {
  const {  Company, apiError, loading, isAlertOpen, modal_static, message, modalError } = state.MainDashboard;      // .SupportExecutive
  return {  Company,apiError, loading, isAlertOpen, modal_static, message, modalError };
}

export default withRouter(connect(mapStatetoProps, { 
  CompanyList, 
  CompanyListByID,
  CompanyListApiError,
  addCompany,
  addCompanyApiError,
  editCompany,
  deleteCompanyList,
  CompanyLogoApi,
})(Company));
 
