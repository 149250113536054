import { OPEN_COMPLAINTS_LIST,OPEN_COMPLAINTS_LIST_SUCCESSFUL,
     OPEN_COMPLAINTS_LIST_API_FAILED,DELETE_COMPLAINT,
     ADD_CUSTOMER_OPEN_COMPLAINTS,ADD_OPEN_COMPLAINTS_API_FAILED,
     EDIT_CUSTOMER_OPEN_COMPLAINTS } from './actionTypes';

export const OpenComplaintsList = (LoginId) => {
    
    return {
        type: OPEN_COMPLAINTS_LIST,
        payload: {LoginId}
    }
}

export const OpenComplaintsListSuccessful = (OpenComplaints, message='') => {

    return {
        type: OPEN_COMPLAINTS_LIST_SUCCESSFUL,
        payload: {OpenComplaints, message},
    }
}

export const addCustomerOpenComplaints = (CustmerOpenComplaints) => {
    return {
        
        type: ADD_CUSTOMER_OPEN_COMPLAINTS,
        payload: {CustmerOpenComplaints},
        isAlertOpen: false,
        modal_static: false
    }
}



export const editCustomerOpenComplaints = (CustmerOpenComplaints) => {
    return {
        
        type: EDIT_CUSTOMER_OPEN_COMPLAINTS,
        payload: {CustmerOpenComplaints},
        isAlertOpen: false,
        modal_static: false
    }
}

export const OpenComplaintsListApiError = (error) => {
    return {
        type: OPEN_COMPLAINTS_LIST_API_FAILED,
        payload: error
    }
}
export const addOpenComplaintsApiError = (error) => {
    return {
        type: ADD_OPEN_COMPLAINTS_API_FAILED,
        payload: error
    }
}
export const deleteComplaint = (ComplaintId,status,UpdatedBy) => {
    return {
        type: DELETE_COMPLAINT,
        payload: {ComplaintId,status,UpdatedBy},
        isAlertOpen: false,
        modal_static: false
    }
}

