import { 
    COMPANYLIST, 
    COMPANYLISTBYID,
    COMPANYLISTSUCCESSFUL, 
    COMPANYLISTAPIERROR,
    ADDCOMPANY,
    ADDCOMPANYAPIERROR,
    EDITCOMPANY,
    DELETECOMPANYLIST,
    COMPANYLOGOAPI
} from './actionTypes';

const initialState = {
    apiError: null, loading: false, Company: [], isAlertOpen: false, modal_static: false, message: null, modalError: null
}

const MainDashboard = (state = initialState, action) => {
    switch (action.type) {
        case COMPANYLIST:
            state = {
                ...state,
                loading: true,
                apiError: null
            }
            break;
        case COMPANYLISTBYID:
            state = {
                ...state,
                loading: true,
                apiError: null
            }
            break;
        case COMPANYLISTSUCCESSFUL:
            // console.log(action.payload);
            state = {
                ...state,
                Company: action.payload.Company,
                loading: false,
                message: action.payload.message.message
            }
            break;
        case COMPANYLISTAPIERROR:
            // console.log(action.payload);
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
                Company: []
            }
            break;
        case ADDCOMPANY:
            debugger
            state = {
                ...state,
                loading: true,
                modalError: null
            }
            break;
        case COMPANYLOGOAPI:
            debugger
            state = {
                ...state,
                loading: true,
                modalError: null
            }
                break;
        case ADDCOMPANYAPIERROR:
            debugger
            state = {
                ...state,
                loading: false,
                modalError: action.payload,
            }
            break;
        case DELETECOMPANYLIST:
            state = {
                ...state,
                loading: true,
                apiError: null,
                message: null
            }
            break;

        case EDITCOMPANY:
            debugger
            state = {
                ...state,
                loading: true,
                modalError: null,
                message: null,
            }
            break;

        default:
            state = { ...state };
            break;
    }

    return state;
}


export default MainDashboard;