import { COMPLAINTS_LIST,COMPLAINTS_LIST_SUCCESSFUL,
    COMPLAINTS_LIST_API_FAILED,DELETE_COMPLAINT,
    ADD_CUSTOMER_COMPLAINTS,ADD_COMPLAINTS_API_FAILED,
    EDIT_CUSTOMER_COMPLAINTS } from './actionTypes';

export const ComplaintsList = (LoginId) => {
   
   return {
       type: COMPLAINTS_LIST,
       payload: {LoginId}
   }
}

export const ComplaintsListSuccessful = (Complaints, message='') => {

   return {
       type: COMPLAINTS_LIST_SUCCESSFUL,
       payload: {Complaints, message},
   }
}

export const addCustomerComplaints = (CustmerComplaints) => {
   return {
       
       type: ADD_CUSTOMER_COMPLAINTS,
       payload: {CustmerComplaints},
       isAlertOpen: false,
       modal_static: false
   }
}



export const editCustomerComplaints = (CustmerComplaints) => {
   return {
       
       type: EDIT_CUSTOMER_COMPLAINTS,
       payload: {CustmerComplaints},
       isAlertOpen: false,
       modal_static: false
   }
}

export const ComplaintsListApiError = (error) => {
   return {
       type: COMPLAINTS_LIST_API_FAILED,
       payload: error
   }
}
export const addComplaintsApiError = (error) => {
   return {
       type: ADD_COMPLAINTS_API_FAILED,
       payload: error
   }
}
export const deleteComplaint = (ComplaintId,status,UpdatedBy) => {
   return {
       type: DELETE_COMPLAINT,
       payload: {ComplaintId,status,UpdatedBy},
       isAlertOpen: false,
       modal_static: false
   }
}

