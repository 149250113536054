import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ModalBody, ModalFooter, Alert, FormGroup, Label, Input, Button, Modal, Col, ModalHeader } from 'reactstrap';
import { AvForm, AvField } from "availity-reactstrap-validation";
//i18n
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// users
import DummyUser from '../../../assets/images/users/DummyUser.jpg';
import { ProfilesList, editProfiles, ProfilesListApiError } from '../../../store/actions';
import { editPassword, editpasswordfailed, editpasswordsuccessfull } from '../../../store/auth/ProfileMenu/actions';

const baseURL = process.env.REACT_APP_API_URL;
const imgURL = process.env.REACT_APP_IMAGE_URL;
class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            RoleId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).RoleId : null,
            UserId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).Id : null,
            LoginUrl: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).LoginUrl : null,
            profile: '',
            modalstatic: false,
            Password: '',
            NewPassword: '',
            confirmPassword: '',
            PasswordError: false,
            Alertmessage: '',
            PasswordAlert: false,
            PasswordAlertError: false,
            updateSuccess: false,
            updateError: false,


        };
        this.toggle = this.toggle.bind(this);
        this.logout = this.logout.bind(this);
        this.profile = this.profile.bind(this);
        this.FormHandler = this.FormHandler.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.removeError = this.removeError.bind(this);
        this.removeModal = this.removeModal.bind(this);
    }


    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    logout() {

        // const baseURL = process.env.REACT_APP_API_URL 
        // let token = localStorage.getItem('tokenForVcard');
        // const response = fetch(`${baseURL}user/logout`, {  
        //   method: 'GET',  
        //   headers: { 
        // 			'Content-Type': 'application/json',
        // 			 'Accept': '*/*',
        // 			 'Authorization' : `Bearer ${token}`					 
        // 			}		
        // })
        // .then(data => data.json())
        // .then(json => {

        // 		if(json.success == true)  
        // 		{   
        // 			localStorage.clear();
        // 			this.props.history.push("/login");
        // 		} 
        // 		else{
        localStorage.clear();
        this.props.history.push("/login");
        // 		}
        //     })
        // localStorage.clear();
        // if(this.state.RoleId==1){
        //     this.props.history.push("/login/superadmin");
        // }else{
        //     this.props.history.push("/login/"+this.state.LoginUrl);
        // }


    }
    profile() {
        this.props.history.push("/profile");
    }

    FormHandler(e) {

        let value = e.target.value;
        let name = e.target.name;

        this.setState({ [name]: value })
        // console.log(name);
        // console.log(value);
        // console.log({ [name]: value })
    }


    // update password
    updatePassword = (event, value) => {
        
        const pwd = value.NewPassword
        const cpwd = value.ConfirmPassword
        // const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        // var test = strongRegex.test(pwd);
        // if (test) {
        if (pwd === cpwd) {
            let update = {
                Id: this.state.UserId,
                Password: value.Password,
                NewPassword: value.NewPassword,
                ConfirmPassword: value.ConfirmPassword,
            }
            this.props.editPassword(update);
            setTimeout(() => {
                if (this.props.modalError === null) {
                    
                    // this.setState({ PasswordAlertError: true })
                    this.setState({ updateSuccess: true, updateError: false })
                    setTimeout(() => {
                        this.setState({ modalstatic: false })
                    }, 4000);
                }
                else {
                    
                    this.setState({ updateError: true, updateSuccess: false });
                    setTimeout(() => {
                        this.setState({ modalstatic: true, updateError: false, updateSuccess: false });
                    }, 4000);
                }
            }, 2000)

        } else {
            this.setState({ PasswordError: true, Alertmessage: 'Password Confirmation does not match !' })
        }
        // } else {
        //     this.setState({ PasswordError: true, Alertmessage: 'Please type strong password !' })
        // }
    }

    removeError() {
        this.setState({ PasswordError: false, Alertmessage: '' });
    }
    removeModal() {
        this.setState({ modalstatic: false, Password: '', NewPassword: '', confirmPassword: '', Alertmessage: '', PasswordError: false });
    }

    componentDidMount() {
        this.props.ProfilesList(this.state.UserId);

    }

    componentWillReceiveProps(nextProps) {
        // console.log("ProfileList  In PROFILE MENU nextProps", nextProps.Profiles)

        this.setState({ Profiles: nextProps.Profiles })
        // this.setState({FirstName:nextProps.Profiles[0].FirstName}) 
        nextProps.Profiles && nextProps.Profiles.map((item) => {

            // console.log(item.FirstName)

            this.setState(
                {
                    FirstName: item.FirstName,
                    LastName: item.LastName,
                    Email: item.Email,
                    UserName: item.UserName,
                    PhoneNumber: item.PhoneNumber,
                    // Password: item.Password,
                    profile: item.Image,
                })

            // console.log("fist Name", baseURL)

        })

    }

    render() {

        let username = "Admin";
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            const Email = obj.Email;
            //  console.log("uNm", Email.split("@"))
            const uNm = Email.split("@")[0];
            username = uNm.charAt(0).toUpperCase() + uNm.slice(1);

            // const obj = JSON.parse(localStorage.getItem("authUser"));
            //   const Email = obj.Email;
            //  //  console.log("uNm", Email.split("@"))
            // const uNm = Email.split("@")[0];
            // username = uNm.charAt(0).toUpperCase() + uNm.slice(1);

        }

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block user-dropdown">
                    <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
                        <img className="rounded-circle header-profile-user mr-1" src={this.state.profile ? imgURL + this.state.profile : DummyUser} alt="Header Avatar" />
                        <span className="d-none d-xl-inline-block ml-1 text-transform">{username}</span>
                        <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={this.profile}><i className="ri-user-line align-middle mr-1"></i>Profile</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem className="text-primary" onClick={() => this.setState({ modalstatic: true, updateSuccess: false, updateError: false })}><i className="ri-lock-line align-middle mr-1"></i>Reset Password</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem className="text-danger" onClick={this.logout} ><i className="ri-shut-down-line align-middle mr-1 text-danger"></i>Logout</DropdownItem>
                    </DropdownMenu>
                </Dropdown>

                <Modal isOpen={this.state.modalstatic} toggle={this.tog_scroll} >
                    <ModalHeader toggle={this.removeModal}>
                        Reset Password
                    </ModalHeader>
                    <ModalBody>
                        {/* <Alert isOpen={this.state.PasswordAlert} color="success">Password Updated Successfully!</Alert> */}
                        {this.state.updateError ? <Alert color="danger">Please enter the correct old password !</Alert> : null}
                        {/* updateError={this.state.updateError} */}
                        {this.state.updateSuccess ? <Alert color="success">Password Updated Successfully !</Alert> : null}
                        {/* updateSuccess={this.state.updateSuccess} */}
                        <AvForm onValidSubmit={this.updatePassword} className="form-horizontal">
                            <FormGroup row>
                                <div className="col-md-12 frm-hit">
                                    <Label htmlFor="OldPassword">Old Password<span className="mandatory">*</span></Label>
                                    <AvField className="form-control" type="password" required name="Password" placeholder="Old Password" value={this.state.Password} onChange={this.FormHandler} id="example-text-input" />
                                </div>
                            </FormGroup>
                            <FormGroup row>
                                <div className="col-md-12 frm-hit">
                                    <Label htmlFor="NewPassword">New Password<span className="mandatory">*</span></Label>
                                    <AvField className="form-control" type="password" required name="NewPassword" placeholder="New Password" value={this.state.NewPassword} onChange={this.FormHandler} id="example-text-input" />
                                </div>
                            </FormGroup>
                            <FormGroup row>
                                <div className="col-md-12 frm-hit">
                                    <Label htmlFor="ConfirmPassword">Confirm New Password<span className="mandatory">*</span></Label>
                                    <AvField className="form-control" type="password" required name="ConfirmPassword" placeholder="Confirm New Password" value={this.state.confirmPassword} onInput={this.removeError} onChange={this.FormHandler} id="example-text-input" />
                                    <p style={{ color: '#ff3d60', marginTop: '-10px', padding: ' 0.75rem 0.25rem !important' }}>{this.state.Alertmessage}</p>
                                    {/* PasswordError={this.state.PasswordError}s */}
                                </div>
                            </FormGroup>
                            <ModalFooter>
                                <Button type="button" color="secondary" className="mx-2" onClick={this.removeModal} >Cancel</Button>
                                <Button type="submit" color="primary" >Set Password</Button>
                            </ModalFooter>
                        </AvForm>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

//export default withNamespaces()(ProfileMenu);

//export default withRouter(withNamespaces()(ProfileMenu));

const mapStatetoProps = state => {
    const { Profiles, } = state.Profiles; // .Complaints
    const { editUserPassword, loading, message, modalError, isAlertOpen, isAlertError } = state.ProfileMenu
    return { Profiles, loading, message, editUserPassword, modalError, isAlertOpen, isAlertError };
}
export default withRouter(connect(mapStatetoProps, { ProfilesList, editProfiles, ProfilesListApiError, editpasswordfailed, editPassword, editpasswordsuccessfull })(ProfileMenu));

