export const AWAITING_COMPLAINTS_LIST = 'AwaitingComplaints_list';
export const AWAITING_COMPLAINTS_LIST_SUCCESSFUL = 'AwaitingComplaints_list_successfull';
export const AWAITING_COMPLAINTS_LIST_API_FAILED = 'AwaitingComplaints_list_api_failed';
export const EDIT_AWAITING_COMPLAINTS = 'edit_AwaitingComplaints';
export const EDIT_AWAITING_COMPLAINTS_API_FAILED = 'edit_AwaitingComplaints_api_failed';
export const DELETE_COMPLAINT = 'delete_complaint';
export const ADD_AWAITING_COMPLAINTS_API_FAILED = 'add_AwaitingComplaints_api_failed';
export const ADD_CUSTOMER_AWAITING_COMPLAINTS = 'add_customer_AwaitingComplaints';
export const EDIT_CUSTOMER_AWAITING_COMPLAINTS = 'edit_customer_AwaitingComplaints';


