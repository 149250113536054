export const CLOSED_COMPLAINTS_LIST = 'ClosedComplaints_list';
export const CLOSED_COMPLAINTS_LIST_SUCCESSFUL = 'ClosedComplaints_list_successfull';
export const CLOSED_COMPLAINTS_LIST_API_FAILED = 'ClosedComplaints_list_api_failed';
export const EDIT_CLOSED_COMPLAINTS = 'edit_ClosedComplaints';
export const EDIT_CLOSED_COMPLAINTS_API_FAILED = 'edit_ClosedComplaints_api_failed';
export const DELETE_COMPLAINT = 'delete_complaint';
export const ADD_CLOSED_COMPLAINTS_API_FAILED = 'add_ClosedComplaints_api_failed';
export const ADD_CUSTOMER_CLOSED_COMPLAINTS = 'add_customer_ClosedComplaints';
export const EDIT_CUSTOMER_CLOSED_COMPLAINTS = 'edit_customer_ClosedComplaints';


