import { 
    COUNT_LIST, 
    COUNT_LIST_BY_ID,
    COUNT_LIST_SUCCESSFUL, 
    COUNT_LIST_API_FAILED,  
    COUNT_LIST_API_Error,
} from './actionTypes';

const initialState = {
    apiError: null, loading: false, Count: [], isAlertOpen: false, modal_static: false, message: null, modalError: null
}

const Count_List = (state = initialState, action) => {
    switch (action.type) {
         
        case COUNT_LIST_BY_ID:
            state = {
                ...state,
                loading: true,
                apiError: null
            }
            break;
        case COUNT_LIST_SUCCESSFUL:
            // console.log('Count Reducer ',action.payload);
            state = {
                ...state,
                Count: action.payload.Count,
                loading: false,
                message: action.payload.message.message
            }
            break;
        case COUNT_LIST_API_FAILED:
            // console.log(action.payload);
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
                Count: []
            }
            break;
         
         
        case COUNT_LIST_API_Error:
            state = {
                ...state,
                loading: false,
                modalError: action.payload,
            }
            break;        

        default:
            state = { ...state };
            break;
    }

    return state;
}

export default Count_List;