import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CUSTOMER_CARE_EXECUTIVE_LIST, CUSTOMER_CARE_EXECUTIVE_BY_ID, ADD_CUSTOMER_CARE_EXECUTIVE, ADD_ACTIVITY, DELETE_CUSTOMER_CARE_EXECUTIVE, EDIT_CUSTOMER_CARE_EXECUTIVE, UPDATE_PASSWORD } from './actionTypes';
import { CustomerCareExecutiveList_successfull, CustomerCareExecutiveByID, CustomerCareExecutiveListApiError, add_customer_care_executive_ApiError, addCompanyApiError, add_activity_api_error, activitylist_successfull, update_password_failed, update_password_api_successfull } from './actions';

// AUTH related methods
import { getList, getDataById, addData, deleteData, editData } from '../../helpers/fackBackend_Helper';

function* getCustomerCareExeList({ payload: { LoginId } }) {
    try {
        const response = yield call(getList, `/users/customerCareExecutivelist/${LoginId}`);
        // console.log("getCustomerCareExeList response", response)
        if (response) {
            yield put(CustomerCareExecutiveList_successfull(response.data.data));
        }
    } catch (error) {
        yield put(CustomerCareExecutiveListApiError(error));
    }
}

export function* watchGetCustomerCareExe() {
    yield takeEvery(CUSTOMER_CARE_EXECUTIVE_LIST, getCustomerCareExeList)
}

function* getCustomerCareExeByID({ payload: { Id } }) {
    try {
        const response = yield call(getDataById, '/users/user', { Id });
        // console.log("response ByID ", response)
        if (response) {
            yield put(CustomerCareExecutiveByID(response.data));
        }
    } catch (error) {
        yield put(CustomerCareExecutiveListApiError(error));
    }
}

export function* watchGetCustomerCareExeById() {
    yield takeEvery(CUSTOMER_CARE_EXECUTIVE_BY_ID, getCustomerCareExeByID)
}

function* addNewCustomer_care_executive({ payload: { customer_care_executive } }) {

    try {
        const responseCreate = yield call(addData, '/users/customerCareExecutive', customer_care_executive);

        if (responseCreate) {
            // console.log("add payload Executive", customer_care_executive)
            const response = yield call(getList, `/users/customerCareExecutivelist/${customer_care_executive.CompanyCode}`);
            // console.log("add response Executive", response.data.data)

            if (response) {
                // console.log('response saga = ', response.data.data);
                // console.log('responseCreate', responseCreate);
                yield put(CustomerCareExecutiveList_successfull(response.data.data, responseCreate));

            }
            else {

                yield put(add_customer_care_executive_ApiError(response.message));

            }
        }
    } catch (error) {
        yield put(add_customer_care_executive_ApiError(error));

    }
}

export function* watchAdd_customer_care_executive() {
    yield takeEvery(ADD_CUSTOMER_CARE_EXECUTIVE, addNewCustomer_care_executive);
}

function* addNewactivity({ payload: { activity } }) {

    try {
        // console.log("try saga payload activity", activity);
        const responseCreate = yield call(addData, '/activity', activity);
        if (responseCreate) {
            // console.log("add payload activity", activity)
            const response = yield call(getList, `/activities`);
            // console.log("add response activity", response.data.data)
            if (response) {
                // console.log('response saga = ', response.data.data);
                // console.log('responseCreate', responseCreate);
                yield put(activitylist_successfull(response.data.data, responseCreate));
            }
            else {

                // yield put(add_activity_api_error(response.message));
            }
        }
    } catch (error) {
        // yield put(add_activity_api_error(error));
        // console.log("catch saga payload activity", activity)
    }
}


export function* watchAdd_Activity() {
    yield takeEvery(ADD_ACTIVITY, addNewactivity);
}


function* updateCustomerCareExeStatus({ payload: { Id, UpdatedBy, CompanyCode, status } }) {

    try {
        const response = yield call(deleteData, '/users/customerCareExecutive', { Id, UpdatedBy, status });

        // console.log("deleteData", response);
        if (response) {

            const responseCom = yield call(getList, `/users/customerCareExecutivelist/${CompanyCode}`);
            // console.log("deleted data", responseCom.data)
            if (responseCom) {
                yield put(CustomerCareExecutiveList_successfull(responseCom.data.data, response));
            }
        }
    } catch (error) {

        yield put(add_customer_care_executive_ApiError(error));

    }
}

export function* watchDeleteCustomerCareExe() {
    yield takeEvery(DELETE_CUSTOMER_CARE_EXECUTIVE, updateCustomerCareExeStatus);
}

function* editCustomerCareExeById({ payload: { customer_care_executive } }) {

    try {
        const responseedit = yield call(editData, '/users/customerCareExecutive', customer_care_executive);
        // console.log('responseedit customerCareExecutive', responseedit);
        // console.log("payload customer_care_executive", customer_care_executive)
        if (responseedit) {
            // console.log("payload Edit", customer_care_executive)
            const response = yield call(getList, `/users/customerCareExecutivelist/${customer_care_executive.CompanyCode}`);
            // console.log("response Edit", response.data.data)
            if (response) {
                // console.log('response saga = ', response.data.data);
                // console.log('responseCreate', responseedit);
                yield put(CustomerCareExecutiveList_successfull(response.data.data, responseedit));
            }
        }
    } catch (error) {
        yield put(add_customer_care_executive_ApiError(error));
    }
}

export function* watchEditCustomerCareExe() {
    yield takeEvery(EDIT_CUSTOMER_CARE_EXECUTIVE, editCustomerCareExeById);
}

function* updatePasswordById({ payload: { update_password } }) {

    try {
        const response = yield call(editData, '/users/updatePassword', update_password);

        // console.log('resetpassword', response);
        if (response) {

            yield put(update_password_api_successfull(response.message))
        }
    }
    catch (error) {
        yield put(update_password_failed(error));
    }
}

export function* watchUpdatePassword() {
    yield takeEvery(UPDATE_PASSWORD, updatePasswordById);
}

function* CustomerCareExeSaga() {
    yield all([fork(watchGetCustomerCareExe)]);
    yield all([fork(watchGetCustomerCareExeById)]);
    yield all([fork(watchAdd_customer_care_executive)]);
    yield all([fork(watchDeleteCustomerCareExe)]);
    yield all([fork(watchEditCustomerCareExe)]);
    yield all([fork(watchAdd_Activity)]);
    yield all([fork(watchUpdatePassword)]);
}

export default CustomerCareExeSaga;