import React, { Component } from 'react';
import { MDBDataTable } from "mdbreact";
import {  Card, CardBody,Row,Col, UncontrolledTooltip, Dropdown,
   DropdownMenu,Container, DropdownToggle,Alert, DropdownItem,Modal,ModalHeader, ModalBody,
   FormGroup,Button,ModalFooter,Label } from "reactstrap";
import { AvForm,  AvField } from "availity-reactstrap-validation";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withRouter, Link } from "react-router-dom";
import Moment from 'moment';
import { connect } from "react-redux";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

// import { 
//   CustomerCareExecutiveList, 
//   CustomerCareExecutiveListApiError,
//   add_customer_care_executive,
//   edit_customer_care_executive,
//   delete_customer_care_executive,
// } from  '../../store/actions';

import { AwaitingComplaintsList,ClosedComplaintsList,OpenComplaintsList,CustomerCareExecutiveList,ComplaintsList,ComplaintsListApiError,TicketsReasonList,
  TicketsTypeList,addCustomerComplaints,editCustomerComplaints
 } from  '../../store/actions';
// const baseURL = process.env.REACT_APP_API_BASEURL;
// const token = JSON.parse(localStorage.getItem('token')); 
class LatestTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu : false,
            RoleId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).RoleId : null,
            CompanyId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyId : null,
            CompanyCode: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyCode : null,
      
            
            UserTicketList: [],
            visible: false,
            isAlertOpen: false,
            modal_scroll: false,
            modal_static: false,
            modalError: false,
            breadcrumbItems: [
              { title: "Dashboard", link: "dashboard" },
            ],
            Complaints:[],
            TicketsType:[],
            TicketsReason:[],
            TicketsTypeId:null,
            CustomerCareExe:[],
            CustomerCareExeList: [],
            TicketType:0,
            CustomerEId:null,
            Status:null,
            editData:null,
            loginUser: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).Id : '',
      
          };
          this.tog_scroll = this.tog_scroll.bind(this);
          this.tog_static = this.tog_static.bind(this);
          this.SearchData = this.SearchData.bind(this);
          this.Checkvalid=this.Checkvalid.bind(this);
          

 
    }
    
  tog_static() {
    this.setState(prevState => ({
      modal_static: !prevState.modal_static
    }));
    this.removeBodyCss();
  }
  tog_scroll() {
    this.setState(prevState => ({
      modal_scroll: !prevState.modal_scroll
      // componentDidMount()
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

    componentDidMount(){
      this.props.ComplaintsListApiError("");
      this.props.ComplaintsList(this.state.loginUser);
      this.props.OpenComplaintsList(this.state.loginUser);
      this.props.ClosedComplaintsList(this.state.loginUser);
      this.props.AwaitingComplaintsList(this.state.loginUser);
      this.props.CustomerCareExecutiveList(this.state.CompanyCode);
      this.props.TicketsTypeList(this.state.CompanyCode);
      this.props.TicketsReasonList(this.state.CompanyCode);
    }
    componentWillReceiveProps(nextProps) {

      this.setState({Complaints: nextProps.Complaints});
      this.setState({ OpenComplaints: nextProps.OpenComplaints });
      this.setState({ ClosedComplaints: nextProps.ClosedComplaints });
      this.setState({ AwaitingComplaints: nextProps.AwaitingComplaints }); 
      this.setState({ TicketsType: nextProps.TicketsType });
      this.setState({ TicketsReason: nextProps.TicketsReason });
      this.setState({CustomerCareExeList: nextProps.CustomerCareExe});
      // console.log('nextProps.CustomerCareExe = ',nextProps.CustomerCareExe);
    }
    Checkvalid=(event, values)=>{

        if(this.state.CustomerEId===null && this.state.Status===null){
           
          alert("Please select atleast one option !")
           
        }

    }
    SearchData = (event, values) => {
      // let SearchDataValue = {
      //   id: this.state.loginUser,
      //   // AssignTo: values.CustomerEId,
      //   Status: values.Status
      // };
      const UserId= values.CustomerEId;
      const Status= values.Status;
      // console.log('parameter ',UserId);
      // console.log('Status ',Status);
      if(UserId!='' && Status==''){
        const SearchUserId = this.state.Complaints.filter(({ AssignTo }) => AssignTo == UserId);
        // console.log('SearchUserId F',SearchUserId);
        this.setState({ComplaintsData:SearchUserId});
        if(Status!=''){
          const SearchStatus = this.state.ComplaintsData.filter(obj => obj.Status == Status);
          // console.log('SearchStatus F',SearchStatus);
          this.setState({ComplaintsData:SearchStatus});
        }
        
      }else if(UserId!='' && Status!=''){
        const SearchUserId = this.state.Complaints.filter(({ AssignTo }) => AssignTo == UserId);
        this.setState({ComplaintsData:SearchUserId});
        // console.log('SearchUserId S',SearchUserId);
        if(Status!=''){
          const SearchStatus = this.state.ComplaintsData.filter(obj => obj.Status == Status);
          // console.log('SearchStatus S',SearchStatus);
          this.setState({ComplaintsData:SearchStatus});
        }
      }else if(Status!='' && UserId==''){
        const SearchStatus = this.state.Complaints.filter(obj => obj.Status == Status);
        // console.log('SearchStatus T',SearchStatus);
        this.setState({ComplaintsData:SearchStatus});
        if(UserId!=''){
          const SearchUserId = this.state.ComplaintsData.filter(({ AssignTo }) => AssignTo == UserId);
          // console.log('SearchUserId T',SearchUserId);
          this.setState({ComplaintsData:SearchUserId});
        }
      }


    }
    render() {
      const { CustomerCareExeList } = this.state;
      // console.log('CustomerCareExe',CustomerCareExeList);
      const OptionExecutive = CustomerCareExeList && CustomerCareExeList.map(person =>
        <option key={person.Id} value={person.Id}>{person.UserName}</option>
      )
      // console.log('OptionExecutive = ',OptionExecutive);
      const ComplaintsEditData = this.state.editData;
      // console.log('ComplaintsEditData = ' ,ComplaintsEditData);
      const { TicketsType, TicketsTypeId } = this.state;

      //const CustomerExecutiveId = this.state.TicketsTypeId;
      
      // console.log('TicketsTypeId ', TicketsTypeId);
     // console.log('TicketsTypeId ', TicketsTypeId.CustomerExecutive);
      

      const { ComplaintsData } = this.state;
      const {OpenComplaints} = this.state;
      const {ClosedComplaints} = this.state;
      const {AwaitingComplaints} = this.state;
      const Opened = [];
      OpenComplaints && OpenComplaints.map( (OpenList,OpenI)=>{
        // console.log("OpenComplaints", OpenComplaints);
        let StatusData="";
        if(OpenList.Status=='Assigned'){
          StatusData = <span className="text-warning">Assigned</span>
        }else if(OpenList.Status=='ReOpen'){
          StatusData = <span className="text-danger">ReOpen</span>
        }else{
          StatusData = <span className="text-primary">UnAssigned</span>
        }
        const limit = 50;
        Opened.push({
          SN:OpenI+1,
          TicketId: OpenList.TicketId,
          TicketTypeName: OpenList.TicketTypeName,
          TicketReasonName:OpenList.TicketReasonName,
          Description: <a title={OpenList.Description}>{OpenList.Description.substring(0,limit)}...</a>,
          DealerName: OpenList.DealerName,
          UserName: OpenList.UserName,
          CreatedDate: Moment(OpenList.CreatedDate).format('MM-DD-YYYY HH:mm:ss'),
          UpdatedDate: (OpenList.UpdatedDate)?Moment(OpenList.UpdatedDate).format('MM-DD-YYYY HH:mm:ss'):null,
          Status: StatusData,
          Action: <div>
                    <div className="badge badge-soft-success font-size-12">
                        <Link to={'/Remarks/'+OpenList.ComplaintId} className="text-primary" ><i className="fa fa-eye" data-param={OpenList.ComplaintId}></i></Link>
                    </div>
                  </div>
        })
      })
      const Closed = [];
      ClosedComplaints && ClosedComplaints.map( (ClosedList,ClosedI)=>{
        // console.log("ClosedComplaints", ClosedComplaints);
        let StatusData="";
        if(ClosedList.Status=='Closed'){
          StatusData = <span className="text-success">Closed</span>
        }
        const limit = 50;
        Closed.push({
          SN:ClosedI+1,
          TicketId: ClosedList.TicketId,
          TicketTypeName: ClosedList.TicketTypeName,
          TicketReasonName:ClosedList.TicketReasonName,
          Description: <a title={ClosedList.Description}>{ClosedList.Description.substring(0,limit)}...</a>,
          DealerName: ClosedList.DealerName,
          UserName: ClosedList.UserName,
          CreatedDate: Moment(ClosedList.CreatedDate).format('MM-DD-YYYY HH:mm:ss'),
          UpdatedDate: (ClosedList.UpdatedDate)?Moment(ClosedList.UpdatedDate).format('MM-DD-YYYY HH:mm:ss'):null,
          Status: StatusData
        })
      })
      const Awaiting = [];
      AwaitingComplaints && AwaitingComplaints.map( (AwaitingList,AwaitingI)=>{
        // console.log("AwaitingComplaints", AwaitingComplaints);
        let StatusData="";
        if(AwaitingList.Status=='Awaiting'){
          StatusData = <span className="text-primary">Awaiting</span>
        }
        const limit = 50;
        Awaiting.push({
          SN:AwaitingI+1,
          TicketId: AwaitingList.TicketId,
          TicketTypeName: AwaitingList.TicketTypeName,
          TicketReasonName:AwaitingList.TicketReasonName,
          Description: <a title={AwaitingList.Description}>{AwaitingList.Description.substring(0,limit)}...</a>,
          DealerName: AwaitingList.DealerName,
          UserName: AwaitingList.UserName,
          CreatedDate: Moment(AwaitingList.CreatedDate).format('MM-DD-YYYY HH:mm:ss'),
          UpdatedDate: (AwaitingList.UpdatedDate)?Moment(AwaitingList.UpdatedDate).format('MM-DD-YYYY HH:mm:ss'):null,
          Status: StatusData,
          Action: <div>
                    {/* {`${text.substring(0, MAX_LENGTH)}...`}
                    <div className="badge badge-soft-success font-size-12">
                        <Link to="#" className="text-primary" id={"edit"+OpenList.ComplaintId}><i className="fa fa-edit" onClick={this.editComplaintById} data-param={OpenList.ComplaintId}></i></Link>
                    </div> */}
                    <div className="badge badge-soft-success font-size-12">
                        <Link to={'/Remarks/'+AwaitingList.ComplaintId} className="text-primary" ><i className="fa fa-eye" data-param={AwaitingList.ComplaintId}></i></Link>
                    </div>
                  </div>
        })
      })
      const Complaint = [];
      ComplaintsData && ComplaintsData.map( (ComplaintList,ComplaintI)=>{
        // console.log("ComplaintsData", ComplaintsData);
        let StatusData="";
        if(ComplaintList.Status=='Assigned'){
          StatusData = <span className="text-warning">Assigned</span>
        }else if(ComplaintList.Status=='ReOpen'){
          StatusData = <span className="text-danger">ReOpen</span>
        }else if(ComplaintList.Status=='Awaiting'){
          StatusData = <span className="text-primary">Awaiting</span>
        }else if(ComplaintList.Status=='Closed'){
          StatusData = <span className="text-success">Closed</span>
        }else{
          StatusData = <span className="text-primary">UnAssigned</span>
        }
        const limit = 50;
        Complaint.push({
          SN:ComplaintI+1,
          TicketId: ComplaintList.TicketId,
          TicketTypeName: ComplaintList.TicketTypeName,
          TicketReasonName:ComplaintList.TicketReasonName,
          Description: <a title={ComplaintList.Description}>{ComplaintList.Description.substring(0,limit)}...</a>,
          DealerName: ComplaintList.DealerName,
          UserName: ComplaintList.UserName,
          CreatedDate: Moment(ComplaintList.CreatedDate).format('MM-DD-YYYY HH:mm:ss'),
          UpdatedDate: (ComplaintList.UpdatedDate)?Moment(ComplaintList.UpdatedDate).format('MM-DD-YYYY HH:mm:ss'):null,
          Status: StatusData,
          Action: <div>
                    {/* {`${text.substring(0, MAX_LENGTH)}...`}
                    <div className="badge badge-soft-success font-size-12">
                        <Link to="#" className="text-primary" id={"edit"+OpenList.ComplaintId}><i className="fa fa-edit" onClick={this.editComplaintById} data-param={OpenList.ComplaintId}></i></Link>
                    </div> */}
                    <div className="badge badge-soft-success font-size-12">
                        <Link to={'/Remarks/'+ComplaintList.ComplaintId} className="text-primary" ><i className="fa fa-eye" data-param={ComplaintList.ComplaintId}></i></Link>
                    </div>
                  </div>
        })

      })
      const data = {
        columns: [
          {
            label: "Sl",
            field: "SN",
            sort: "asc",
            width: 150
          },
          {
            label: "Ticket Id",
            field: "TicketId",
            sort: "asc",
            width: 150
          }, 
          {
            label: "Ticket Type",
            field: "TicketTypeName",
            sort: "asc",
            width: 150
          },
          {
            label: "Ticket Reason",
            field: "TicketReasonName",
            sort: "asc",
            width: 150 
          },
          {
            label: "Description",
            field: "Description",
            sort: "asc",
            width: 150 
          },
          {
            label: "Dealer Name",
            field: "DealerName",
            sort: "asc",
            width: 150 
          },
          {
            label: "Assign To",
            field: "UserName",
            sort: "asc",
            width: 150 
          }, 
          {
            label: "Created Date",
            field: "CreatedDate",
            sort: "asc",
            width: 150 
          },
          {
            label: "Updated Date",
            field: "UpdatedDate",
            sort: "asc",
            width: 150 
          },
          {
            label: "Status",
            field: "Status",
            sort: "asc",
            width: 150 
          },  
          {
            label: "Action",
            field: "Action",
            sort: "asc",
            width: 270
          },
        ],
        rows: Opened 
    };
    const data1 = {
      columns: [
        {
          label: "Sl",
          field: "SN",
          sort: "asc",
          width: 150
        },
        {
          label: "Ticket Id",
          field: "TicketId",
          sort: "asc",
          width: 150
        }, 
        {
          label: "Ticket Type",
          field: "TicketTypeName",
          sort: "asc",
          width: 150
        },
        {
          label: "Ticket Reason",
          field: "TicketReasonName",
          sort: "asc",
          width: 150 
        },
        {
          label: "Description",
          field: "Description",
          sort: "asc",
          width: 150 
        },
        {
          label: "Dealer Name",
          field: "DealerName",
          sort: "asc",
          width: 150 
        },
        {
          label: "Assign To",
          field: "UserName",
          sort: "asc",
          width: 150 
        }, 
        {
          label: "Created Date",
          field: "CreatedDate",
          sort: "asc",
          width: 150 
        },
        {
          label: "Updated Date",
          field: "UpdatedDate",
          sort: "asc",
          width: 150 
        },
        {
          label: "Status",
          field: "Status",
          sort: "asc",
          width: 150 
        },
      ],
      rows: Closed 
    };
    const data2 = {
      columns: [
        {
          label: "Sl",
          field: "SN",
          sort: "asc",
          width: 150
        },
        {
          label: "Ticket Id",
          field: "TicketId",
          sort: "asc",
          width: 150
        }, 
        {
          label: "Ticket Type",
          field: "TicketTypeName",
          sort: "asc",
          width: 150
        },
        {
          label: "Ticket Reason",
          field: "TicketReasonName",
          sort: "asc",
          width: 150 
        },
        {
          label: "Description",
          field: "Description",
          sort: "asc",
          width: 150 
        },
        {
          label: "Dealer Name",
          field: "DealerName",
          sort: "asc",
          width: 150 
        },
        {
          label: "Assign To",
          field: "UserName",
          sort: "asc",
          width: 150 
        }, 
        {
          label: "Created Date",
          field: "CreatedDate",
          sort: "asc",
          width: 150 
        },
        {
          label: "Updated Date",
          field: "UpdatedDate",
          sort: "asc",
          width: 150 
        },
        {
          label: "Status",
          field: "Status",
          sort: "asc",
          width: 150 
        },  
        {
          label: "Action",
          field: "Action",
          sort: "asc",
          width: 270
        },
      ],
      rows: Awaiting 
    };
    const data3 = {
      columns: [
        {
          label: "Sl",
          field: "SN",
          sort: "asc",
          width: 150
        },
        {
          label: "Ticket Id",
          field: "TicketId",
          sort: "asc",
          width: 150
        }, 
        {
          label: "Ticket Type",
          field: "TicketTypeName",
          sort: "asc",
          width: 150
        },
        {
          label: "Ticket Reason",
          field: "TicketReasonName",
          sort: "asc",
          width: 150 
        },
        {
          label: "Description",
          field: "Description",
          sort: "asc",
          width: 150 
        },
        {
          label: "Dealer Name",
          field: "DealerName",
          sort: "asc",
          width: 150 
        },
        {
          label: "Assign To",
          field: "UserName",
          sort: "asc",
          width: 150 
        }, 
        {
          label: "Created Date",
          field: "CreatedDate",
          sort: "asc",
          width: 150 
        },
        {
          label: "Updated Date",
          field: "UpdatedDate",
          sort: "asc",
          width: 150 
        },
        {
          label: "Status",
          field: "Status",
          sort: "asc",
          width: 150 
        },  
        {
          label: "Action",
          field: "Action",
          sort: "asc",
          width: 270
        },
      ],
      rows: Complaint 
    };
        return (
            <React.Fragment>
              <Row>
                <Col sm={6} md={6} xl={6} className="mt-4">
                  <h4 className="card-title">Tickets List</h4>
                </Col>
                <Col sm={6} md={6} xl={6} className="mt-4 pull-right text-right"  >
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                <Tabs >
                    <TabList>
                      <Tab>Open</Tab>
                      <Tab onClick={this.Closed}>Closed</Tab>
                      <Tab onClick={this.Awaiting}>Awaiting</Tab>
                      <Tab onClick={this.Search}>Search</Tab>
                    </TabList>
                    <TabPanel>
                      <Card>
                        <CardBody>
                          <MDBDataTable responsive bordered data={data} />
                        </CardBody>
                      </Card>
                    </TabPanel>
                    <TabPanel>
                      <Card>
                        <CardBody>
                            <MDBDataTable responsive bordered data={data1} />
                        </CardBody>
                      </Card>
                    </TabPanel>
                    <TabPanel>
                      <Card>
                        <CardBody>
                          <MDBDataTable responsive bordered data={data2} />
                        </CardBody>
                      </Card>
                    </TabPanel>
                    <TabPanel>
                      <Card>
                        <CardBody>
                          {this.props.apiError && this.props.apiError ? <Alert color="danger" isOpen={this.state.visible}>{this.props.apiError}</Alert> : null }
                          {this.props.message && this.props.message!=='' ? <Alert color="success" isOpen={this.state.visible}>{this.props.message} </Alert> : null }
                          <AvForm onValidSubmit={this.SearchData} className="form-horizontal" >
                            <FormGroup row>
                            {(this.state.RoleId === 3) &&
                                <Col>
                                {/* <Label htmlFor="CustomerEId">Choose Executive</Label> */}
                                  <AvField className="form-control" name="CustomerEId"  type="hidden" value={this.state.loginUser}  id="CustomerEId"/>
                                   
                                </Col>
                              }
                              {(this.state.RoleId === 2) &&
                                <Col sm={4} md={4} xl={4}>
                                <Label htmlFor="CustomerEId">Choose Executive</Label>
                                  <AvField className="form-control" name="CustomerEId" onChange={(e)=> this.setState({CustomerEId: e.target.value})} type="select"  id="CustomerEId">
                                    <option value="">Select Executive</option>
                                    {OptionExecutive}
                                  </AvField>
                                </Col>
                              }
                              <Col sm={4} md={4} xl={4}>
                              <Label htmlFor="Status">Choose Status</Label>
                                <AvField className="form-control" name="Status"  type="select" id="Status" onChange={(e)=> this.setState({Status: e.target.value})} >
                                <option value="">Select Status</option>
                                <option value="UnAssigned">UnAssigned</option>
                                <option value="Assigned">Assigned</option>
                                <option value="ReOpen">ReOpen</option>
                                <option value="Awaiting">Awaiting</option>
                                <option value="Closed">Closed</option>
                                </AvField>
                              </Col>
                              {(this.state.RoleId === 1) &&
                                <Col sm={4} md={4} xl={4}>
                                <Label htmlFor="CustomerEId">Choose Executive</Label>
                                  <AvField className="form-control" name="CustomerEId"  type="select"  id="CustomerEId">
                                    <option value="">Select Executive</option>
                                    {OptionExecutive}
                                  </AvField>
                                </Col>
                              }
                              <Col sm={2} md={2} xl={2}>
                                <Label htmlFor="button"> </Label>
                                <AvField className="form-control btn btn-primary mt-2" name="submit" onClick={this.Checkvalid} color="primary" type="submit" value="Search" />
                                {/* <Button type="submit" color="primary">Save changes</Button> */}
                              </Col>
                            </FormGroup>
                          </AvForm>
                          <MDBDataTable responsive bordered data={data3} />
                        </CardBody>
                      </Card>
                    </TabPanel>
                </Tabs>
              </Col>
            </Row>
          </React.Fragment>
        );
    }
}

//
//export default LatestTransactions;

const mapStatetoProps = state => {
  const { TicketsType, CustomerExecutive } = state.TicketsType; // .TicketsType

  const { TicketsReason } = state.TicketsReason;
  const { OpenComplaints } = state.OpenComplaints;
  const { ClosedComplaints } = state.ClosedComplaints;
  const { AwaitingComplaints } = state.AwaitingComplaints;
  const { Complaints, apiError, loading,
    isAlertOpen, modal_static, message, modalError } = state.Complaints;
  const { CustomerCareExe } = state.CustomerCareExecutive;
   // .Complaints
   return { AwaitingComplaints,ClosedComplaints,OpenComplaints,CustomerCareExe,Complaints,TicketsType, CustomerExecutive, TicketsReason,
     apiError, loading, isAlertOpen, modal_static, message, modalError };
}

export default withRouter(connect(mapStatetoProps,
         { AwaitingComplaintsList,ClosedComplaintsList,OpenComplaintsList,CustomerCareExecutiveList,ComplaintsList,TicketsTypeList,TicketsReasonList,
          ComplaintsListApiError,addCustomerComplaints,
          editCustomerComplaints})(LatestTransactions));
 
 
 
