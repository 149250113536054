import { CHECK_LOGIN, LOGIN_USER_SUCCESSFUL, API_ERROR,LOGOUT_USER, LOGOUT_USER_SUCCESS, FETCH_COMPANY, FETCH_COMPANY_SUCCESSFUL } from './actionTypes';

export const checkLogin = (user, history,companyId) => {
    
    return {
        
        type: CHECK_LOGIN,
        payload: { user,history,companyId}
    }
    
}

export const loginUserSuccessful = (user) => {
    
    return {
        type: LOGIN_USER_SUCCESSFUL,
        payload: user
    }
    
}

export const apiError = (error) => {
    // console.log("Action", error)
    return {
        type: API_ERROR,
        payload: error
    }
}

export const logoutUser = (history) => {
    return {
        type: LOGOUT_USER,
        payload: { history }
    }
}

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {}
    }
}

export const fetchCompany = (domainName) => {
    return {
        type: FETCH_COMPANY,
        payload: {domainName}
    }
}

export const fetchCompanySucceassful = (company) => {
    return {
        type: FETCH_COMPANY_SUCCESSFUL,
        payload: {company}
    }
}
