import React, { Component } from "react";

import { Col, Row, Card, CardBody, Container, FormGroup, Label,  Input } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
//import { Count_List_By_Id, Count_List_API_FAILED } from  '../../store/actions';

// //Import Breadcrumb
import Breadcrumbs from '../../Common/Breadcrumb';
import DummyUser from '../../../assets/images/users/DummyUser.jpg';
import { ProfilesList,editProfiles,ProfilesListApiError } from  '../../../store/actions';

const baseURL = process.env.REACT_APP_API_URL;
const imgURL = process.env.REACT_APP_IMAGE_URL;

// console.log(imgURL);
class ProfileList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "", link: "#" },
              ],
          RoleId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).RoleId : null,
          UserId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).Id : null,
          CompanyId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyId : null,
          Profiles:[],
          picture: false,
          src: false, 
             
        };
        this.picture = React.createRef();
        this.changepicture = this.changepicture.bind(this);
        this.uploadHandler = this.uploadHandler.bind(this);
      }
  
	componentDidMount() {

        this.props.ProfilesList(this.state.UserId);
		
    }
    componentWillReceiveProps(nextProps) {
        // console.log("ProfileList nextProps", nextProps.Profiles) 
        this.setState({Profiles:nextProps.Profiles}) 
       // this.setState({FirstName:nextProps.Profiles[0].FirstName}) 
       nextProps.Profiles && nextProps.Profiles.map( (item)=>{

        // console.log(item.FirstName)

        this.setState({FirstName:item.FirstName,
            LastName: item.LastName,
            Email: item.Email,
            UserName: item.UserName,
            PhoneNumber: item.PhoneNumber,
            Password: item.Password,
            Image: item.Image ,
            CompanyName: item.CompanyName,
            CompanyEmail: item.CompanyEmail
                        })

        // console.log("fist Name", baseURL)

       })
        
        
    }

    fileChangedHandler = (event) => {
        
        let picture = event.target.files[0];
        // console.log('picture name = ', picture.name);

        const formData = new FormData();
            let Id = this.state.UserId;
        // Update the formData object
        // console.log('formData',formData);
        formData.append( "picture", picture, picture.name);
        formData.append( "Id", Id);
        this.props.editProfiles(formData);
       
      }
      uploadHandler = (event, values) => {
        
        //   console.log('values= ',values);
        
        var formData = new FormData();
    //    console.log(formData);
        formData.append("file", this.state.picture);
        // console.log('formData',formData);
        this.props.editProfiles(formData);
        
      }
    changepicture = (event, values) => {
        
        // console.log('event',event);
        // console.log('values',values);
        let picturesData = {
          picture: this.state.picture,
          
        };
        // console.log('picturesData',picturesData);
        // this.props.editProfiles(picturesData);
    }
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="User Profile" breadcrumbItems={this.state.breadcrumbItems} />

                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                      
                                    <div className="p-2">
                                        <AvForm onValidSubmit={this.uploadHandler} className="form-horizontal" >
                                        <FormGroup row>
                                             
                                            <Col className="col-md-4 mt-4 pt-3">
                                               <Label htmlFor="FirstName">Change Profile picture</Label>
                                               <Input className="form-control file-bor" type="file" accept="image/*" name="picture[]" onChange={this.fileChangedHandler} id="example-date-input" />
                                               {/* <Input  type="text" name="Id" value="3" id="example-date-input" /> */}
                                            </Col>
                                            <Col className="col-md-2 mt-4 pt-3">
                                             {/* <button type="submit" className="updte-ProfileList" >Upload</button> */}
                                            </Col>
                                            <Col md = { 2 } > { /* <img src={this.state.photoPath,this.state.photo} /> */ }
                                                <Label htmlFor="FirstName">Profile picture</Label>
												<img src={this.state.Image?imgURL+this.state.Image:DummyUser} className="ProfileList-img" height="150" width="150" alt={this.state.Image} /> 
											</Col> 
											
                                           
                                        </FormGroup>
                                        <FormGroup row>
                                             <Col md={6}>
                                                <Label htmlFor="FirstName">First Name</Label>
                                                <AvField className="form-control" type="text" name="updateFirstName" value={this.state.FirstName}    placeholder="Firs tName" id="example-text-input" readOnly="readOnly" />
                                            </Col>
                                            <Col md={6}>
                                                <Label htmlFor="FirstName">Last Name</Label>
                                                <AvField className="form-control" type="text" name="updateLastName" value={this.state.LastName}   placeholder="Last Name"  id="example-text-input"readOnly="readOnly"/>
                                            </Col>
                                  </FormGroup>
                                  <FormGroup row>
                                             <Col md={6}>
                                                 <Label htmlFor="FirstName">Phone Number</Label>
                                                <AvField className="form-control" type="text" name="updatePhoneNumber" value={this.state.PhoneNumber}   placeholder="Phone Number"  id="example-text-input" readOnly="readOnly"/>
                                            </Col>

                                            <Col md={6}>
                                                <Label htmlFor="FirstName">Email</Label> 
                                                <AvField className="form-control" type="text" name="updateEmail" value={this.state.Email}   placeholder="Email" id="example-text-input" readOnly="readOnly"/>
                                            </Col>
                                  </FormGroup>
                                  <FormGroup row> 
                                            <Col md={6}>
                                                <Label htmlFor="FirstName">Username</Label>
                                                <AvField className="form-control" type="text" name="updateUserName" value={this.state.UserName}   placeholder="User Name"  id="example-text-input" readOnly="readOnly"/>
                                            </Col>
                                            {/* <Col md={6}>
                                                <Label htmlFor="FirstName">Password</Label>
                                                <AvField className="form-control" type="text" name="Password" value={this.state.Password}   placeholder="User Name"  id="example-text-input" readOnly="readOnly" />
                                            </Col> */}
                                            <Col md={6}>
                                                <Label htmlFor="FirstName">Company Name</Label>
                                                <AvField className="form-control" type="text" name="CompanyName" value={this.state.CompanyName}   placeholder="Company Name"  id="example-text-input" readOnly="readOnly"/>
                                            </Col>
                                            </FormGroup>
                                  <FormGroup row> 
                                            
                                            <Col md={6}>
                                                <Label htmlFor="FirstName">Company Email</Label>
                                                <AvField className="form-control" type="text" name="CompanyEmail" value={this.state.CompanyEmail}   placeholder="CompanyEmail"  id="example-text-input" readOnly="readOnly" />
                                            </Col>
                                  </FormGroup>
                                  
										</AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
const { Profiles, apiError, loading, message } = state.Profiles; // .Complaints
    return { Profiles, apiError, loading,message };
}

export default withRouter(connect(mapStatetoProps,{ ProfilesList,editProfiles, ProfilesListApiError })(ProfileList));

