import { COMPLAINTS_LIST,COMPLAINTS_LIST_SUCCESSFUL,
    COMPLAINTS_LIST_API_FAILED,DELETE_COMPLAINT,
    ADD_CUSTOMER_COMPLAINTS,ADD_COMPLAINTS_API_FAILED,
    EDIT_CUSTOMER_COMPLAINTS } from './actionTypes';

const initialState = {
   apiError: null, loading: false, Complaints: [],  isAlertOpen: false, modal_static: false, message: null, modalError: null
}

const Complaints = (state = initialState, action) => {
   switch (action.type) {
       case COMPLAINTS_LIST:
           state = {
               ...state,
               loading: true,
               apiError: null
           }
           break;
       case COMPLAINTS_LIST_SUCCESSFUL:
           state = {
               ...state,
               Complaints: action.payload.Complaints,
               loading: false,
               message: action.payload.message.message
           }
           break;
       case COMPLAINTS_LIST_API_FAILED:
           state = {
               ...state,
               loading: false,
               Complaints: []
           }
           break;
           case ADD_CUSTOMER_COMPLAINTS:
           state = {
               ...state,
               loading: true,
               modalError: null
           }
           break;
           case ADD_COMPLAINTS_API_FAILED:
           state = {
               ...state,
               loading: false,
               message: action.payload
           }
           break;
           case EDIT_CUSTOMER_COMPLAINTS:
           state = {
               ...state,
               loading: false,
               apiError: null,
               message: null
           }
           break;
           case DELETE_COMPLAINT:
           state = {
               ...state,
               loading: true,
               apiError: null,
               message: null
           }
           break;
       default:
           state = { ...state };
           break;
   }

   return state;
}

export default Complaints;