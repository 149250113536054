import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { TICKETSTYPE_LIST,ADD_TICKETTYPE,EDIT_TICKETTYPE,DELETE_TICKETTYPE,LOG_TICKETTYPE,ADD_ACTIVITY, } from './actionTypes';
import { TicketsTypeListSuccessful, TicketsTypeListApiError,
    AddTicketTypeApiError,add_activity_api_error,activitylist_successfull } from './actions';

// AUTH related methods
import { getList,addData,editData,deleteData } from '../../../helpers/fackBackend_Helper';

function* getTicketsTypeList( { payload : { LoginId } } ) {
    try {
        const response = yield call(getList, `/admin/tickets/${LoginId}`);
            // console.log("ticket Type List",response.data)
        if (response) {
            const responseCustomer = yield call(getList, `/users/customerCareExecutivelist/${LoginId}`);
            if (responseCustomer) {
                // console.log("responseCustomer",responseCustomer)
                yield put(TicketsTypeListSuccessful(response.data.data,responseCustomer.data.data));
            }
            // yield put(TicketsTypeListSuccessful(JSON.parse(response)));

        }
    } catch (error) {
        yield put(TicketsTypeListApiError(error));
    }
}

export function* watchGetTicketsType() {
    yield takeEvery(TICKETSTYPE_LIST, getTicketsTypeList)
}


function* AddTicketType({ payload: { TicketsType } }) {
    try {
        const responseCreate = yield call(addData, '/admin/tickettype', TicketsType);
        // console.log('response from saga = ', );
        if (responseCreate) {
            const response = yield call(getList, `/admin/tickets/${TicketsType.CompanyCode}`);
            if (response) {
                const responseCustomer = yield call(getList, `/users/customerCareExecutivelist/${TicketsType.CompanyCode}`);
                if (responseCustomer) {
                    // console.log("responseCreate",responseCreate)
                    // console.log("All Response = ",response.data.data,responseCustomer.data.data,responseCreate)
                    yield put(TicketsTypeListSuccessful(response.data.data,responseCustomer.data.data,responseCreate));
                }
            }
        }
    } catch (error) {
        yield put(AddTicketTypeApiError(error));
    }
}

export function* watchAddTicketType() {
    yield takeEvery(ADD_TICKETTYPE, AddTicketType);
}

function* addNewactivity({ payload: { activity } }) {
    
    try {
        // console.log("try saga payload activity",activity);
        const responseCreate = yield call(addData, '/activity', activity);
        if (responseCreate) {
            // console.log("add payload activity",activity)
            const response = yield call(getList, `/activities`);
            // console.log("add response activity",response.data.data)
            if (response) {
                // console.log('response saga = ',response.data.data);
                // console.log('responseCreate',responseCreate);
                yield put(activitylist_successfull(response.data.data,responseCreate));
            }
            else{

                // yield put(add_activity_api_error(response.message));
            }
        }
    } catch (error) {
        // yield put(add_activity_api_error(error));
        // console.log("catch saga payload activity",activity)
    }
}


export function* watchAdd_Activity() {
    yield takeEvery(ADD_ACTIVITY, addNewactivity);
}


function* editTicketType({ payload: { TicketsType } }) {
    try {
        // console.log('TicketsType', TicketsType)
        const responseadd = yield call(editData, '/admin/tickettype', TicketsType);
        if (responseadd) {
            // console.log('responseadd = ', responseadd)
            const response = yield call(getList, `/admin/tickets/${TicketsType.CompanyCode}`);
            if (response) {
                // console.log('response = ', response)
                const responseCustomer = yield call(getList, `/users/customerCareExecutivelist/${TicketsType.CompanyCode}`);
                if (responseCustomer) {
                    // console.log("responseCustomer",response.data.data,responseCustomer.data.data,responseadd)
                    yield put(TicketsTypeListSuccessful(response.data.data,responseCustomer.data.data,responseadd));
                }
            }
        }
        
    } catch (error) {
        yield put(AddTicketTypeApiError(error));
    }
}

export function* watcheditTicketType() {
    yield takeEvery(EDIT_TICKETTYPE, editTicketType);
}

function* updateTicketTypeStatus({ payload: { Id,status,CompanyCode,UpdatedBy } }) {
    try {
        // console.log('Tickets Type',Id,status,UpdatedBy)
        const response = yield call(deleteData, '/admin/tickettype', { Id,status,UpdatedBy });
        // console.log('update Tickets Type status =',response);
        if (response) {
            const responses = yield call(getList, `/admin/tickets/${CompanyCode}`);
            if (responses) {
                // console.log("tickets Type",responses.data.data)
                const responseCustomer = yield call(getList, `/users/customerCareExecutivelist/${CompanyCode}`);
                if (responseCustomer) {
                    // console.log("responseCustomer",responseCustomer)
                    yield put(TicketsTypeListSuccessful(responses.data.data,responseCustomer.data.data,response));
                }
            }
        }
    } catch (error) {
        yield put(AddTicketTypeApiError(error));
    }
}

export function* watchdeleteTicketType() {
    yield takeEvery(DELETE_TICKETTYPE, updateTicketTypeStatus);
}

function* TicketsTypeSaga() {
    yield all([fork(watchGetTicketsType)]);
    yield all([fork(watchAddTicketType)]);
    yield all([fork(watcheditTicketType)]);
    yield all([fork(watchdeleteTicketType)]);
    yield all([fork(watchAdd_Activity)]);
}

export default TicketsTypeSaga;