import React, { Component } from "react";
//import React,{useState,useEffect} from 'react'

import Moment from 'moment';
// import Axios from 'axios';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// import {useHistory} from 'react-router-dom'
import { AvForm, AvField } from "availity-reactstrap-validation";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { MDBDataTable } from "mdbreact";
// import Multiselect from 'multiselect-react-dropdown';
// import { MultiSelect } from "react-multi-select-component";
// import Select from 'react-select';
// import AvForm from 'availity-reactstrap-validation';

import {
  Row, Col, Card, CardBody,
  Modal, ModalHeader, ModalBody, UncontrolledTooltip, ModalFooter, Button,
  Container, FormGroup, Label, Alert
} from "reactstrap";

import Breadcrumbs from '../../components/Common/Breadcrumb';
import "./datatables.scss";

// import BootstrapTable from 'react-bootstrap-table-next';
// import ToolkitProvider from 'react-bootstrap-table2-toolkit';
// import TicketingModel from '../../pages/Ticketing/TicketingModel';
import {
  TicketsTypeList, TicketsTypeListApiError,
  TicketsReasonList, TicketsReasonListApiError,
  AddTicketType, editTicketType, deleteTicketType, editTicketReason, addTicketReason, deleteTicketReason
} from '../../store/actions';

// import { TicketMultiSelect } from  'TicketMultiSelect'; 

// const baseURL = process.env.REACT_APP_API_BASEURL;
// const token = JSON.parse(localStorage.getItem('token'));

class Ticketing extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();

    this.state = {
      visible: false,
      isAlertOpen: false,
      modal_standard: false,
      modal_large: false,
      modal_xlarge: false,
      modal_small: false,
      modal_center: false,
      modal_scroll: false,
      modal_reason: false,
      modal_static: false,
      breadcrumbItems: [
        { title: "", link: "tickets" },
      ],
      TicketsType: [],
      TicketsReason: [],
      TicketTypeName: "",
      TicketType: [],
      TicketReasonName: '',
      CustomerExecutive: [],
      editData: null,
      editReasonData: null,
      IsActive: '',
      // CreatedBy:'1', 
      loginUser: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).Id : '',
      CompanyId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyId : '',
      RoleId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).RoleId : '',
      CompanyCode: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyCode : '',

    };
    this.FormHandler = this.FormHandler.bind(this);
    this.tog_standard = this.tog_standard.bind(this);
    this.tog_xlarge = this.tog_xlarge.bind(this);
    this.tog_large = this.tog_large.bind(this);
    this.tog_small = this.tog_small.bind(this);
    this.tog_center = this.tog_center.bind(this);
    this.tog_scroll = this.tog_scroll.bind(this);
    this.tog_reason = this.tog_reason.bind(this);
    this.tog_static = this.tog_static.bind(this);
    this.addTicketsReason = this.addTicketsReason.bind(this);
    this.addTicketsType = this.addTicketsType.bind(this);
    this.deleteTicketTypeById = this.deleteTicketTypeById.bind(this);
    this.deleteTicketReasonById = this.deleteTicketReasonById.bind(this);
  }

  componentDidMount() {
    // console.log('this.state.CompanyCode', this.state.CompanyCode);
    this.props.TicketsTypeListApiError("");
    this.props.TicketsReasonListApiError("");
    // this.props.querytypeList();
    this.props.TicketsTypeList(this.state.CompanyCode);
    this.props.TicketsReasonList(this.state.CompanyCode);
  }
  componentWillReceiveProps(nextProps) {
    // console.log('nextProps api data = ', nextProps);
    this.setState({ TicketsType: nextProps.TicketsType });
    this.setState({ CustomerExecutive: nextProps.CustomerExecutive });
    this.setState({ TicketsReason: nextProps.TicketsReason });
  }
  addTicketsType = (event, values) => {
    let TicketsTypeData = {
      TicketTypeName: values.TicketTypeName,
      CustomerExecutive: values.CustomerExecutive,
      CreatedBy: this.state.loginUser,
      CompanyId: this.state.CompanyId,
      CompanyCode: this.state.CompanyCode,
      status: 'add'
    };
    // console.log('TicketsTypeData = ', TicketsTypeData);
    this.props.AddTicketType(TicketsTypeData);
    if (this.props.modalError === null) {
      this.setState({ isAlertOpen: true });
      this.setState({ modal_scroll: false });

      this.setState({ visible: true }, () => {
        window.setTimeout(() => {
          this.setState({ visible: false })
        }, 5000)
      });
    }
  }
  deleteTicketTypeById = (event) => {
    let TicketId = {
      Id: event.target.getAttribute('data-param')
    }
    const status = 'Delete';
    this.props.deleteTicketType(TicketId.Id, status, this.state.CompanyCode, this.state.loginUser);
    this.setState({ visible: true }, () => {
      window.setTimeout(() => {
        this.setState({ visible: false })
      }, 5000)
    });
  }

  deleteTicketReasonById = (event) => {
    let TicketId = {
      Id: event.target.getAttribute('data-id')
    }
    const status = 'Delete';
    // console.log('delete reson ', this.state.loginUser,status,TicketId.Id);
    this.props.deleteTicketReason(TicketId.Id, status, this.state.CompanyCode, this.state.loginUser);
    // console.log('this.props', this.props);
    this.setState({ visible: true }, () => {
      window.setTimeout(() => {
        this.setState({ visible: false })
      }, 5000)
    });
  }
  // addTicketTypePopup = (event) => {
  //   // const TTId = event.target.dataset.param;
  //   let TicketsTypeEditData='';
  //   // const editData = this.state.TicketsType.filter(({ Id }) => Id == TTId);
  //   this.setState({  modal_scroll: true, isAlertOpen: false });
  //   // console.log('editData ',editData[0])
  // }
  editTicketTypeById = (event) => {
    const TTId = event.target.dataset.param;
    const editData = this.state.TicketsType && this.state.TicketsType.filter(({ Id }) => Id == TTId);

    if (typeof editData !== "undefined") {
      this.setState({ editData: editData[0], modal_scroll: true, isAlertOpen: false });
    } else {
      this.state.editData = [];
      this.setState({ editData: editData, modal_scroll: true, isAlertOpen: false });
      // console.log('PopupData ', editData);
    }
    // this.setState({ editData: editData[0], modal_scroll: true, isAlertOpen: false });
    // console.log('editData ',editData[0]);
  }
  editTicketType = (event, values) => {
    let TicketsTypeData = {
      TicketTypeName: values.TicketTypeName,
      CustomerExecutive: values.CustomerExecutive,
      IsActive: values.IsActive,
      UpdatedBy: this.state.loginUser,
      CompanyId: this.state.CompanyId,
      CompanyCode: this.state.CompanyCode,
      status: 'edit',
      Id: this.state.editData.Id
    };
    this.props.editTicketType(TicketsTypeData);
    // console.log('this.props', this.props);
    if (this.props.modalError === null) {
      this.setState({ isAlertOpen: true });
      this.setState({ modal_scroll: false });

      this.setState({ visible: true }, () => {
        window.setTimeout(() => {
          this.setState({ visible: false })
        }, 5000)
      });
    }
  }

  addTicketsReason = (event, values) => {
    let TicketsReasonData = {
      TicketType: values.TicketType,
      TicketReasonName: values.TicketReasonName,
      CompanyId: this.state.CompanyId,
      CreatedBy: this.state.loginUser,
      CompanyCode: this.state.CompanyCode,
      status: 'add'
    };
    // console.log('TicketsReasonData', TicketsReasonData);
    // console.log('this.props', this.props);
    this.props.addTicketReason(TicketsReasonData);

    if (this.props.modalError === null) {
      this.setState({ isAlertOpen: true });
      this.setState({ modal_reason: false });

      this.setState({ visible: true }, () => {
        window.setTimeout(() => {
          this.setState({ visible: false })
        }, 5000)
      });
    }
  }

  editTicketReasonById = (event) => {
    const TRId = event.target.dataset.id;
    const editReasonData = this.state.TicketsReason && this.state.TicketsReason.filter(({ Id }) => Id == TRId);
    if (typeof editReasonData !== "undefined") {
      this.setState({ editReasonData: editReasonData[0], modal_reason: true, isAlertOpen: false });
    } else {
      this.state.editReasonData = [];
      this.setState({ editReasonData: editReasonData, modal_reason: true, isAlertOpen: false });
      // console.log('PopupData ', editReasonData);
    }

  }
  editTicketsReason = (event, values) => {
    let TicketsReasonData = {
      TicketType: values.TicketType,
      TicketReasonName: values.TicketReasonName,
      UpdatedBy: this.state.loginUser,
      CompanyId: this.state.CompanyId,
      IsActive: values.IsActive,
      CompanyCode: this.state.CompanyCode,
      status: 'edit',
      Id: this.state.editReasonData.Id
    };
    // console.log('this.props', this.props);
    this.props.editTicketReason(TicketsReasonData);
    if (this.props.modalError === null) {
      this.setState({ isAlertOpen: true });
      this.setState({ modal_reason: false });

      this.setState({ visible: true }, () => {
        window.setTimeout(() => {
          this.setState({ visible: false })
        }, 5000)
      });
    }
  }
  tog_static() {
    this.setState(prevState => ({
      modal_static: !prevState.modal_static
    }));
    this.removeBodyCss();
  }

  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  tog_large() {
    this.setState(prevState => ({
      modal_large: !prevState.modal_large
    }));
    this.removeBodyCss();
  }
  tog_xlarge() {
    this.setState(prevState => ({
      modal_xlarge: !prevState.modal_xlarge
    }));
    this.removeBodyCss();
  }
  tog_small() {
    this.setState(prevState => ({
      modal_small: !prevState.modal_small
    }));
    this.removeBodyCss();
  }
  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center
    }));
    this.removeBodyCss();
  }
  tog_scroll() {
    this.setState(prevState => ({
      modal_scroll: !prevState.modal_scroll
      // componentDidMount()
    }));
    this.removeBodyCss();
  }
  tog_reason() {
    this.setState(prevState => ({
      modal_reason: !prevState.modal_reason
    }));
    this.removeBodyCss();
  }
  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  TicketingModel() {
    // console.log("TicketingModel Call");
  }
  handleSelect(e) {
    // console.log(e);
  }

  FormHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value }, function () {
    });
  }



  render() {
    const TicketsTypeEditData = this.state.editData;
    const TicketsReasonEditData = this.state.editReasonData;
    const { TicketsType } = this.state;
    const { TicketsReason } = this.state;
    const { CustomerExecutive } = this.state;
    const rowData = [];
    TicketsType && TicketsType.map((item, TypeKey) => {
      // console.log("Updated date:", item.UpdatedDate);
      rowData.push({
        SN: TypeKey + 1,
        TicketType: item.TicketTypeName,
        AssignTo: <span>{(item.UserName !== '') ? <span className="text-success">{item.UserName}</span> : <span className="text-danger">UnAssign</span>}</span>,
        IsActive: <span>{(item.IsActive === 1) ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>}</span>,
        CreatedDate: Moment(item.CreatedDate).format('MM-DD-YYYY HH:mm:ss'),
        UpdatedDate: (item.UpdatedDate) ? Moment(item.UpdatedDate).format('MM-DD-YYYY HH:mm:ss') : null,
        // Id: item.Id,
        Action: <div className="badge badge-soft-success font-size-12">
          <Link to="#" className="mr-3 text-primary" id={"edittype" + TypeKey} ><i className="mdi mdi-pencil font-size-18" data-toggle="modal" onClick={this.editTicketTypeById} data-param={item.Id}></i></Link>

          {(item.IsActive === 1) && <Link to="#" className="text-danger" id={"deletetype" + TypeKey}><i className="mdi mdi-trash-can font-size-18" onClick={this.deleteTicketTypeById} data-param={item.Id}></i></Link>}

        </div>,
      });
    });

    const rowData1 = [];
    TicketsReason && TicketsReason.map((Ritem, Rindex) => {
      rowData1.push({
        SN: Rindex + 1,
        TicketReason: Ritem.TicketReasonName,
        TicketType: Ritem.TicketTypeName,
        IsActive: <span>{(Ritem.IsActive === 1) ? <span className="text-success">Active</span> : <span className="text-danger">Inative</span>}</span>,
        CreatedDate: Moment(Ritem.CreatedDate).format('MM-DD-YYYY HH:mm:ss'),
        UpdatedDate: (Ritem.UpdatedDate) ? Moment(Ritem.UpdatedDate).format('MM-DD-YYYY HH:mm:ss') : null,
        // Id: item.Id,
        Action: <div className="badge badge-soft-success font-size-12">
          <Link to="#" className="mr-3 text-primary" id={"editreason" + Rindex} ><i className="mdi mdi-pencil font-size-18" data-toggle="modal" onClick={this.editTicketReasonById} data-id={Ritem.Id}></i></Link>

          {(Ritem.IsActive === 1) &&
            <Link to="#" className="text-danger" id={"deletereason" + Rindex}><i className="mdi mdi-trash-can font-size-18" onClick={this.deleteTicketReasonById} data-id={Ritem.Id}></i></Link>}

        </div>,
      });
    });
    const data = {
      columns: [
        {
          label: "Sl",
          field: "SN",
          sort: "asc",
          width: 150
        },
        {
          label: "Ticket Type",
          field: "TicketType",
          sort: "asc",
          width: 150
        },
        {
          label: "Assign To",
          field: "AssignTo",
          sort: "asc",
          width: 150
        },
        {
          label: "Status",
          field: "IsActive",
          sort: "asc",
          width: 270
        },
        {
          label: "Created Date",
          field: "CreatedDate",
          sort: "asc",
          width: 270
        },
        {
          label: "Updated Date",
          field: "UpdatedDate",
          sort: "asc",
          width: 270
        },
        {
          label: "Action",
          field: "Action",
          // sort: "asc",
          width: 270
        },
      ],
      rows: rowData
    };
    const data1 = {
      columns: [
        {
          label: "Sl",
          field: "SN",
          sort: "asc",
          width: 150
        },
        {
          label: "Ticket Reason",
          field: "TicketReason",
          sort: "asc",
          width: 150
        },
        {
          label: "Ticket Type",
          field: "TicketType",
          sort: "asc",
          width: 270
        },
        {
          label: "Status",
          field: "IsActive",
          sort: "asc",
          width: 270
        },
        {
          label: "Created Date",
          field: "CreatedDate",
          sort: "asc",
          width: 270
        },
        {
          label: "Updated Date",
          field: "UpdatedDate",
          sort: "asc",
          width: 270
        },
        {
          label: "Action",
          field: "Action",
          // sort: "asc",
          width: 270
        },
      ],
      rows: rowData1
    };

    // const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
    //   <div className="btn-group btn-group-toggle" data-toggle="buttons">
    //     {
    //       columns
    //         .map(column => ({
    //           ...column,
    //           toggle: toggles[column.dataField]
    //         }))
    //         .map(column => (
    //           <button
    //             type="button"
    //             key={column.dataField}
    //             className={`btn btn-secondary ${column.toggle ? 'active' : ''}`}
    //             data-toggle="button"
    //             aria-pressed={column.toggle ? 'true' : 'false'}
    //             onClick={() => onColumnToggle(column.dataField)}
    //           >
    //             {column.text}
    //           </button>
    //         ))
    //     }
    //   </div>
    // );


    const OptionData = CustomerExecutive && CustomerExecutive.map(Executive =>
      <option key={Executive.Id} value={Executive.Id}>{Executive.UserName}</option>
    )



    // const { TicketType } = this.state;

    const TicketTypeData = TicketsType && TicketsType.map(TicketType =>
      <option key={TicketType.Id} value={TicketType.Id}>{TicketType.TicketTypeName}</option>
    )
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>

            <Breadcrumbs title="Ticket Configuration" breadcrumbItems={this.state.breadcrumbItems} />

            <Row>
              <Col xs={12}>
                <Tabs >
                  <TabList>
                    <Tab>Ticket Type</Tab>
                    <Tab onClick={this.TicketReason}>Ticket Reason</Tab>
                  </TabList>

                  <TabPanel>
                    <Card>
                      <CardBody>
                        {this.props.apiError && this.props.apiError ? <Alert color="danger" isOpen={this.state.visible}>{this.props.apiError}</Alert> : null}
                        {this.props.message && this.props.message !== '' ? <Alert color="success" isOpen={this.state.visible}>{this.props.message} </Alert> : null}
                        <Modal
                          isOpen={this.state.modal_scroll}
                          toggle={this.tog_static}
                          scrollable={true}
                          backdrop="static" centered size="sm">

                          <ModalHeader toggle={() => this.setState({ modal_scroll: false })}>
                            {(TicketsTypeEditData) ? 'Edit Ticket Type' : 'Add Ticket Type '}

                          </ModalHeader>

                          <ModalBody>
                            <AvForm onValidSubmit={(TicketsTypeEditData) ? this.editTicketType : this.addTicketsType} className="form-horizontal" >

                              <FormGroup row>
                                {/* <Label htmlFor="example-text-input" className="col-md-3 col-form-label">Ticket Name</Label> */}
                                <Col md={12}>
                                  <Label htmlFor="TicketTypeName">Ticket Type Name <span className="mandatory">*</span></Label>
                                  <AvField className="form-control" type="text" name="TicketTypeName" value={TicketsTypeEditData && TicketsTypeEditData.TicketTypeName} id="TicketTypeName" placeholder="Ticket Type" required />

                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                {/* <Label htmlFor="example-text-input" className="col-md-3 col-form-label">Title</Label> */}
                                <Col md={12}>
                                  <Label htmlFor="CustomerExecutive">Customer Care Executive <span className="mandatory">*</span></Label>
                                  <AvField className="form-control" name="CustomerExecutive" type="select"
                                    value={TicketsTypeEditData && JSON.stringify(TicketsTypeEditData.CustomerExecutive)}
                                    onChange={this.OptionData} id="CustomerExecutive" required>
                                    <option value="">Select Executive</option>
                                    {OptionData}
                                  </AvField>
                                </Col>
                              </FormGroup>
                              {(TicketsTypeEditData) ?
                                <FormGroup row>
                                  {/* <Label htmlFor="example-text-input" className="col-md-3 col-form-label">Title</Label> */}
                                  <Col md={12}>
                                    <Label htmlFor="IsActive">Status <span className="mandatory">*</span></Label>
                                    <AvField className="form-control" name="IsActive" required type="select" value={TicketsTypeEditData && JSON.stringify(TicketsTypeEditData.IsActive)} id="IsActive">
                                      <option value="" >Select</option>
                                      <option value="1" >Active</option>
                                      <option value="0" >Inactive</option>
                                    </AvField>
                                  </Col>
                                </FormGroup> : ''}
                              <ModalFooter>
                                <Button type="button" color="light" onClick={() => this.setState({ modal_scroll: false })}>Close</Button>
                                <Button type="submit" color="primary">{(TicketsTypeEditData) ? 'Update' : 'Save'}</Button>
                              </ModalFooter>
                            </AvForm>

                          </ModalBody>
                        </Modal>

                        {/* <p className="card-title-desc">
                        </p> */}
                        <Row>
                          {/* <Col sm={6} md={6} xl={6} className="mt-4">
                            <h4 className="card-title">Ticket Type List</h4>

                          </Col> */}
                          <Col sm={12} md={12} xl={12} className="pull-right text-right"  >
                            <Button type="button"
                              color="primary"
                              onClick={this.editTicketTypeById}
                              data-toggle="modal"
                            >
                              Add
                            </Button>

                          </Col>
                        </Row>
                        <MDBDataTable responsive bordered data={data} />


                      </CardBody>
                    </Card>
                  </TabPanel>
                  <TabPanel>
                    <Card>
                      <CardBody>

                        <Modal
                          isOpen={this.state.modal_reason}
                          toggle={this.tog_static}
                          scrollable={true}
                          backdrop="static" centered size="sm">
                          <ModalHeader toggle={() => this.setState({ modal_reason: false })}>
                            {(TicketsReasonEditData) ? 'Edit Ticket Reason' : 'Add Ticket Reason '}

                          </ModalHeader>

                          <ModalBody>
                            <AvForm onValidSubmit={(TicketsReasonEditData) ? this.editTicketsReason : this.addTicketsReason} className="form-horizontal" >

                              <FormGroup row>
                                <Col md={12}>
                                  <Label htmlFor="TicketType">Ticket Reason Name <span className="mandatory">*</span></Label>
                                  <AvField className="form-control" type="text" name="TicketReasonName" value={TicketsReasonEditData && TicketsReasonEditData.TicketReasonName}
                                    onChange={this.FormHandler} id="example-text-input" placeholder="Ticket Reason" required />
                                </Col>
                              </FormGroup>
                              <FormGroup row>

                                <Col md={12}>
                                  <Label htmlFor="TicketType">Ticket Type Name <span className="mandatory">*</span></Label>

                                  <AvField className="form-control" name="TicketType" type="select"
                                    value={TicketsReasonEditData && JSON.stringify(TicketsReasonEditData.TicketType)}
                                    onChange={this.TicketTypeData} id="example-text-input" required>
                                    <option value="">Select Ticket Type</option>
                                    {TicketTypeData}

                                  </AvField>

                                </Col>
                              </FormGroup>

                              {(TicketsReasonEditData) ?
                                <FormGroup row>
                                  {/* <Label htmlFor="example-text-input" className="col-md-3 col-form-label">Title</Label> */}
                                  <Col md={12}>
                                    <Label htmlFor="IsActive">Status <span className="mandatory">*</span></Label>

                                    <AvField className="form-control" name="IsActive" required type="select" value={TicketsReasonEditData && JSON.stringify(TicketsReasonEditData.IsActive)} id="IsActive">
                                      <option value="" >Select</option>
                                      <option value="1" >Active</option>
                                      <option value="0" >Inactive</option>
                                    </AvField>
                                  </Col>
                                </FormGroup> : ''}
                              <ModalFooter>
                                <Button type="button" color="light" onClick={() => this.setState({ modal_reason: false })}>Close</Button>
                                <Button type="submit" color="primary">{(TicketsReasonEditData) ? 'Update' : 'Save'}</Button>
                              </ModalFooter>
                            </AvForm>

                          </ModalBody>
                        </Modal>
                        {/* <p className="card-title-desc">
                        </p> */}
                        <Row>
                          {/* <Col sm={6} md={6} xl={6} className="mt-4">
                            <h4 className="card-title">Ticket Reason List</h4>

                          </Col> */}
                          <Col sm={12} md={12} xl={12} className="pull-right text-right"  >
                            <Button type="button"
                              color="primary"
                              onClick={this.editTicketReasonById}
                              data-toggle="modal"
                            >
                              Add
                            </Button>

                          </Col>
                        </Row>
                        {this.props.apiError1 && this.props.apiError1 ? <Alert color="danger" isOpen={this.state.visible}>{this.props.apiError1}</Alert> : null}
                        {this.props.message1 && this.props.message1 !== '' ? <Alert color="success" isOpen={this.state.visible}>{this.props.message1} </Alert> : null}

                        <MDBDataTable responsive bordered data={data1} />


                      </CardBody>
                    </Card>
                  </TabPanel>
                </Tabs>

              </Col>

            </Row>


          </Container>
        </div>
      </React.Fragment>
    );
  }
}
// export default Ticketing;

const mapStatetoProps = state => {
  // const { querytype, apiError, loading, message } = state.querytype; // .querytype

  const { TicketsType, CustomerExecutive, apiError, loading, isAlertOpen, modal_static, message, modalError } = state.TicketsType; // .TicketsType

  const { TicketsReason, apiError1, loading1, isAlertOpen1, modal_static1, message1, modalError1 } = state.TicketsReason; // .TicketsReason
  // return { querytype,TicketsType,TicketsReason, apiError, loading,message };

  return { TicketsType, CustomerExecutive, TicketsReason, apiError, loading, isAlertOpen, modal_static, message, modalError, apiError1, loading1, isAlertOpen1, modal_static1, message1, modalError1 };
}

// export default withRouter(connect(mapStatetoProps, { querytypeList,querytypeListApiError,TicketsTypeList,TicketsTypeListApiError,TicketsReasonList,TicketsReasonListApiError})(Ticketing));
export default withRouter(connect(mapStatetoProps,
  {
    TicketsTypeList, TicketsTypeListApiError,
    TicketsReasonList, TicketsReasonListApiError,
    AddTicketType, editTicketType, deleteTicketType, addTicketReason,
    editTicketReason, deleteTicketReason

  })(Ticketing));



