import {
    EDIT_PASSWORD,
    EDIT_PASSWORD_API_SUCCESSFULL,
    EDIT_PASSWORD_API_FAILED
} from './actionTypes'

export const editPassword = (editUserPassword) => {
    
    return {
        type: EDIT_PASSWORD,
        payload: { editUserPassword },
        isAlertOpen: false,
    }
    
}

export const editpasswordsuccessfull = (message = "") => {
    
    return {
        type: EDIT_PASSWORD_API_SUCCESSFULL,
        payload: { message }
    }
    
}

export const editpasswordfailed = (error) => {
    
    return {
        type: EDIT_PASSWORD_API_FAILED,
        payload: { error }
    }
    
}